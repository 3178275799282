export const SearchStatus =  {
    Empty: 0,
    Searching: 1,
    Success: 2,
    Failed:3
}

export const SearchNames = {
    Benefits: 0,
    BusinessRates: 1,
    Deceased: 2,
    CouncilTaxAddress: 3,
    CouncilTaxName: 4,
    Directory: 5,
    VotersRoll: 6,
    VotersRollByAddress: 7
}

const initialState = {
    isSU: false
}

export default function reducer(state = initialState, action = { type: 'NOT_SET' }) {
    switch (action.type) {
        default:
            return state;
    }
}

export const selector = {
    isSU: (state) => state.globals.isSU
};