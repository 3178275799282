import axios from 'axios';
import { reset } from 'redux-form';
import { formName } from '../react-components/testing-grounds/form';

export const FORM_START = 'FORM_START';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_FAIL = 'FORM_FAIL';

const urls = {
    form: `/api/diagnostics/react-testing-grounds-1`
}

class FormDTO {
    constructor(model) {
        this.datePicker = model.datePicker;
    }
}

const action = (type, data = null) => ({
    type, data
});

export function submitForm (model) {
    return (dispatch) => {
        dispatch(action(FORM_START, model));
        const data = new FormDTO(model);
        axios.post(urls.form, data).then(_ => {
            dispatch(action(FORM_SUCCESS, _.data));
            dispatch(reset(formName));
        }).catch(err => {
            console.warn(err);
            dispatch(action(FORM_FAIL));
        });
    };
}