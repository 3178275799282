import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

const configureStore = (preloadedState, rootReducer) => {
    const store = createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(thunk)
    );

    return store;
};

export default configureStore;
