import axios from "axios";

export const LOAD_SBR_SUMMARIES_DATA_FINISHED = 'LOAD_SBR_SUMMARIES_DATA_FINISHED';
export const LOAD_SBR_SUMMARIES_DATA_STARTED = 'LOAD_SBR_SUMMARIES_DATA_STARTED';

export const LOAD_SBRV2_SUMMARIES_DATA_FINISHED = 'LOAD_SBRV2_SUMMARIES_DATA_FINISHED';
export const LOAD_SBRV2_SUMMARIES_DATA_STARTED = 'LOAD_SBRV2_SUMMARIES_DATA_STARTED';

const url = '/api/sbr-reports/summaries';
const urlv2 = '/api/sbr-v2-reports/summaries';

const action = (type, data) => ({
    type, data
});

const loadSummaries = () => {
    return dispatch => {
        axios.get(url).then(res => {
            dispatch(action(LOAD_SBR_SUMMARIES_DATA_FINISHED, res.data))
        });
    }
}

const loadSummariesV2 = () => {
    return dispatch => {
        axios.get(urlv2).then(res => {
            dispatch(action(LOAD_SBRV2_SUMMARIES_DATA_FINISHED, res.data))
        });
    }
}

export const startLoadingSummaries = () => {
    return dispatch => {
        dispatch(action(LOAD_SBR_SUMMARIES_DATA_STARTED));
        dispatch(loadSummaries());
    }
}

export const startLoadingSummariesV2 = () => {
    return dispatch => {
        dispatch(action(LOAD_SBRV2_SUMMARIES_DATA_STARTED));
        dispatch(loadSummariesV2());
    }
}