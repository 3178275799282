import axios from 'axios';

import BaseSearchApi from '../base-api';

export const urls = {
    addressList: `/api/search/voters-roll-by-address/address-list`,
    search: `/api/search/voters-roll-by-address`,
    validate: `/api/search/voters-roll-by-address/validate`
}

class Api extends BaseSearchApi {
    baseAddressList = async values => {
        const searchResponse = await axios.post(urls.addressList, values);
        return searchResponse.data || [];
    }

    personList = async values => await this.withCache(this.baseAddressList, "baseAddressList")(values);
}

const api = new Api(urls);
export default api;