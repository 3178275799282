import Modal from './modal';
import React from 'react';

export default ({ onSubmit, modal }) => {

    let { height, width, isVisible } = modal;

    let header = <h4 className="modal-title">Search Guide</h4>;

    let content = (
        <div style={{ overflow: 'auto' }}>
            <p>You have not completed the minimum data requirements for one or more of your search types. &nbsp;Please <a href="/cdn/downloads/LoCTA Plus Search criteria_2023.pdf" rel="noopener noreferrer" target="_blank">click here</a> to download the search guidelines.</p>
        </div>
    );

    let footer = (
        <ul>
            <li className="align-right">
                <button type="button" className="btn btn-default" onClick={ onSubmit }>Close</button>
            </li>
        </ul>
    );

    return <Modal isVisible={ isVisible } header={ header } content={ content } footer={ footer } height={ height } width={ width } onClose={ onSubmit } />;
}
