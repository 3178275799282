import flatten from 'flat';
import IgnoreList from './field-ignore-list';
import AggregateList from './aggregate-list';

class CompaniesHousePresenter {
    sortViewModel = (model) => {
        return model.sort((a,b) => {
            const fieldA = a.field.toUpperCase();
            const fieldB = b.field.toUpperCase();

            let comparison = 0;
            if (fieldA > fieldB) {
                comparison = 1;
            } else if (fieldA < fieldB) {
                comparison = -1;
            }
            return comparison;
        });
    }

    filterViewModel = model => {
        return model.filter(x => {
            if (x !== null){
                const field = x.field.toLowerCase();
                if (IgnoreList.some(rx => rx.test(field))){
                    return false;
                }

                return true;
            }
            return false;
        });
    }
    
    aggregateViewModel = model => {
        AggregateList.forEach(agg => {
            let fieldsMatchingAgg = model.filter(x => {
                if (x !== null){
                    const field = x.field.toLowerCase();

                    return agg.test(field);
                }
                return false;
            });

            fieldsMatchingAgg = fieldsMatchingAgg.sort((a,b) => {
                if(a.field >= b.field){
                    return 1;
                }
                return -1;
            });

            if(fieldsMatchingAgg.length > 0) {
                
                let newFieldValue = fieldsMatchingAgg.reduce((map, obj) => {
                    map += `${obj.fieldValue}, `;
                    return map;
                }, "");
                newFieldValue = newFieldValue.slice(0, -2);

                let newField = {
                    field: `data.${agg}`.replace('/','').slice(0,-1),
                    fieldValue: newFieldValue
                };

                model = model.filter(x => {
                    if (x !== null){
                        const field = x.field.toLowerCase();
    
                        return !agg.test(field);
                    }
                    return false;
                });

                model.push(newField);
            }
        })

        return model;
    }

    mapViewModel = model => {
        return Object.keys(model).map((field, index) => {
            const fieldType = typeof(model[field]);
            const fieldValue = "" + model[field];
            if(!field.startsWith("@") 
                && (fieldType === 'string' || fieldType === 'number')
                && !field.startsWith("Previous")
                && fieldValue !== ""){
                return {field, fieldValue, key: index};
            }
            return null;
        });
    }

    formatViewModel = model => {
        model.forEach(el => {
            el.field = el.field.replace("data.","");
        });
        return model;
    }
    
    toViewModel = (doc) => {
        let flat = flatten(doc);

        let result = this.mapViewModel(flat);

        result = this.filterViewModel(result);

        // result = this.aggregateViewModel(result);

        result = this.formatViewModel(result);

        result = this.sortViewModel(result);

        return result;
    }
}

const instance = new CompaniesHousePresenter();

export default instance;