import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {startLoadingSummaries} from '../../redux-actions/nino-match-actions';
import Table from './table';
import {selector} from '../../redux-reducers/nino-match-reducer';
import {selector as globalSelector} from '../../redux-reducers/globals';

const NinoMatchDownloads = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoadingSummaries());
    }, [dispatch]);


    const isSuperUser = useSelector(globalSelector.isSU);
    const isLoading = useSelector(selector.isLoading);
    const summaries = useSelector(selector.getSummaries);

    let comp = isLoading
        ? <p>Loading data...</p>
        : summaries.length > 0
            ? <Table data={summaries} isSuperUser={isSuperUser} />
            : <p>No data available.</p>;
    
    return comp;
}

export default NinoMatchDownloads;
