import * as Actions from '../redux-actions/diagnostics-actions';

const initialState = {
    loading: false,
    data: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.FORM_START:
            return {
                ...state, 
                loading: true
            }
        case Actions.FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data
            }
        case Actions.FORM_FAIL:
            return {
                ...state, 
                loading: false,
                data: null
            }
        default:
            return state;
    }
}

export const selector = {
    loading: (state) => state.diagnostics.loading,
    data: (state) => state.diagnostics.data
};