import Store from './base/store';
import websiteReducer from '../redux-reducers/website-reducer';

const preloadedState = {
    globals: {
        isSU: false
    }
};

let instance = null;
export default () => {
    if(instance === null){
        instance = Store(websiteReducer, '../redux-reducers/website-reducer', preloadedState);
    }
    return instance;
}