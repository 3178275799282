import axios from 'axios';
import { parse } from 'tiny-querystring';

import { initialiseQuickSearchAlerts } from './quick-search-alerts-actions';

export const FETCH_QUICK_SEARCH_DATA_STARTED = 'FETCH_QUICK_SEARCH_DATA_STARTED';
export const FETCH_QUICK_SEARCH_DATA_FINISHED = 'FETCH_QUICK_SEARCH_DATA_FINISHED';
export const FETCH_QUICK_SEARCH_DATA_ERROR = 'FETCH_QUICK_SEARCH_DATA_ERROR';

const urls = {
    apiPath: '/api/quick-search/'
}

const fetchQuickSearchDataStarted = (props) => {
    return { type: FETCH_QUICK_SEARCH_DATA_STARTED, data: props };
}

const fetchQuickSearchDataFinished = (props) => {
    return { type: FETCH_QUICK_SEARCH_DATA_FINISHED, data: props };
}

const  fetchQuickSearchDataError = (props) => {
    return { type: FETCH_QUICK_SEARCH_DATA_ERROR, data: props };
}

export const fetchQuickSearchData = (query) => {
    return (dispatch) => {
        if (query.indexOf('?') > -1) {
            query = query.substring(1);
        }
        const parsedQueryString = parse(query);
        query = parsedQueryString.query;

        dispatch(fetchQuickSearchDataStarted({query}));

        axios.get(urls.apiPath + query).then(response => {
           const data = response.data;
           if(data){
               dispatch(fetchQuickSearchDataFinished({query, data}));
               dispatch(initialiseQuickSearchAlerts({query, data}));
           } 
        }).catch(err => {
            dispatch(fetchQuickSearchDataError({query, err}));
        });
    }
}
