import React from 'react';
import tableFn from '../react-table-extras/table';
import {LoctaButton, PrimaryButton, DangerButton, Row, Col} from '../bootstrap';
import moment from 'moment';

// {
//     "AccessId": "8bde3aa82603429389077d8d3d94e25e",
//     "ClientName": "LoCTA Testville",
//     "Fullname": "newuser2309",
//     "Email": "support@locta.co.uk",
//     "Username": "newuser2309",
//     "IsAdministrator": false,
//     "LastActivity": "/Date(1632396725237-0000)/",
//     "LastURL": "/Account/Logout"
// }

// https://loctahub.co.uk/AdminDashboard/UserAccounts/Edit/b6474c1d-29a3-4bf2-bffc-e20385f1bce6
const ManageUser = ({accessId}) => (
    <Row className='d-flex justify-content-between align-items-center'>
        <Col size={5}>
            <LoctaButton small={true} className="h-100 p-2" ButtonFn={PrimaryButton} textComponent={<>Edit</>} fontSize="9px" fontWeight="normal" onClick={() => window.location=`/AdminDashboard/UserAccounts/Edit/${accessId}`} />
        </Col>
        <Col size={5}>
            <LoctaButton small={true} className="h-100 p-2" ButtonFn={DangerButton} textComponent={<>Disable</>} fontSize="9px" fontWeight="normal" onClick={() => window.location=`/AdminDashboard/UserAccounts/Remove/${accessId}`} />
        </Col>
    </Row>
);

const columns = [
    {
        Header: 'Full name',
        accessor: 'fullname'
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Username',
        accessor: 'username'
    },
    {
        Header: 'Is Administrator',
        accessor: 'isAdministrator',
        Cell: ({value}) => <>{value ? "Yes" : "No"}</>
    },
    {
        Header: 'Last Activity',
        accessor: 'lastActivity',
        Cell: ({value}) => <>{ value ? moment(value).format('MMMM Do YYYY') : '---'}</>,
        disableFilters: true
    },
    {
        Header: 'Manage',
        accessor: 'accessId',
        Cell: ({value}) => <ManageUser accessId={value}/>,
        disableFilters: true
    }
];
const FilterTable = tableFn(columns);

const Table = ({data}) => {
    return (
        <FilterTable data={data}/>
    );
}

export default Table;