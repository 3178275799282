import * as quickSearch from '../redux-actions/quick-search-actions';

const initialState = {
    searching: false,
    data: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case quickSearch.FETCH_QUICK_SEARCH_DATA_STARTED:
            return {...state, searching: true, error: null, data: null, query: action.data.query };
        case quickSearch.FETCH_QUICK_SEARCH_DATA_FINISHED:
            return {...state, searching: false, data: action.data.data, error: null, query: action.data.query };
        case quickSearch.FETCH_QUICK_SEARCH_DATA_ERROR:
            return {...state, searching: false, data: null, error: action.data.err, query: action.data.query };
        default:
            return state;
    }
};

const selectQuickSearch = (state) => state !== null && typeof(state.quickSearch) !== 'undefined' ? state.quickSearch : null;
const selectData = (state) => typeof(selectQuickSearch(state).data) !== 'undefined' ? selectQuickSearch(state).data : null;
export const selector = {
    selectValidationModel: (state) => {
        const data = selectData(state);
        return data !== null ? data.validationModel : null;
    },
    
    selectPostcodeSearchModel: (state) => {
        const data = selectData(state);
        return data !== null ? data.postcodeSearchModel : null;
    },
    
    selectTelephoneSearchModel: (state) => {
        const data = selectData(state);
        return data !== null ? data.telephoneSearchModel : null;
    },
    
    selectEmailSearchModel: (state) => {
        const data = selectData(state);
        return data !== null ? data.emailSearchModel : null;
    },
    
    selectToken: (state) => {
        const data = selectData(state);
        return data !== null ? data.token : null;
    },
    
    selectError: (state) => {
        const error = typeof(selectQuickSearch(state).error) !== 'undefined' ? selectQuickSearch(state).error : null;
        return error;
    },
    
    selectQuery: (state) => {
        return typeof(selectQuickSearch(state).query) !== 'undefined' ? selectQuickSearch(state).query : null;
    },
    
    selectQuickSearchAlertsEnabled: (state) => {
        const data = selectData(state);
        return data !== null ? data.quickSearchAlertsEnabled : false;
    }
}