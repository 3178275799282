import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';

export const BENEFITS_SEARCH_START = 'BENEFITS_SEARCH_START';
export const BENEFITS_SEARCH_SUCCESS = 'BENEFITS_SEARCH_SUCCESS';
export const BENEFITS_SEARCH_FAIL = 'BENEFITS_SEARCH_FAIL';
export const BENEFITS_SEARCH_RESET = 'BENEFITS_SEARCH_RESET';

export const urls = {
    search: `/api/search/benefits`,
    validate: `/api/search/benefits/validate`
}

export const formName = "benefits-search";

class BenefitsSearchViewModel {
    constructor(model) {
        this.Surname = model.surname;
        this.Nino = model.nino;
        this.DateOfBirth = model.dateOfBirth;
        this.OpenCasesOnly = model.openCasesOnly;
        this.Telephone = model.telephone;
        this.Email = model.email;
        this.InitialSearch = model.initialSearch;
        this.LinkedSearch = model.linkedSearch;
        this.SecondLinkedSearch = model.secondLinkedSearch;
        this.IsLoctaPlusSearch = model.isLoctaPlusSearch;
        this.IsQuickSearch = model.isQuickSearch;

        this.__RequestVerificationToken = model.__requestVerificationToken;
    }
}

const action = (type, data = null) => ({
    type, data
});

export function performSearch(model) {
    return async (dispatch) => {

        dispatch(action(BENEFITS_SEARCH_START, model));

        const formValues = new BenefitsSearchViewModel(model);

        const validateResponse = await axios
            .post(urls.validate, formValues);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            const searchResponse = await axios.post(urls.search, formValues);
            dispatch(action(BENEFITS_SEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(BENEFITS_SEARCH_FAIL));
        }
    }
}

export function validateSearch(model) {
    return async (dispatch) => {
        const formValues = new BenefitsSearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export function resetSearch(values) {
    return (dispatch) => {

        dispatch(action(BENEFITS_SEARCH_RESET));
        dispatch(initialize(formName, {
            ...values,
            surname: '',
            nino: '',
            dateOfBirth: null,
            openCasesOnly: false
        }));
    }
}


