import React, { Component } from 'react';
import {connect} from 'react-redux';
import {startQueryAll} from '../../redux-actions/companies-house-actions';
import {selector} from '../../redux-reducers/companies-house-reducer';
import Form, {formName} from './search-form';
import CompaniesResultsTable from './companies-results-table';
import {initialize} from 'redux-form';
import {Row, Hr,BootstrapContainer} from '../bootstrap';

const querySelector = (props) => props.match.params.query;

class CompaniesHouse extends Component {
    constructor(props){
        super(props);

        const {match} = props;
        const query = match.params.query;

        this.state = {
            displayTable: true
        };
        
        if(typeof(query) !=='undefined' && query !== null){
            props.loadFormData(query);
            this.search({searchTerm: query});
        }

    }

    componentDidUpdate(prevProps) {
        const newQuery = querySelector(this.props);
        const oldQuery = querySelector(prevProps);

        if (newQuery !== oldQuery) {
            this.props.loadFormData(newQuery);
            this.search({searchTerm: newQuery});
        }
    }

    search = ({searchTerm}) => {
        this.props.dispatchQueryAll(searchTerm, 50);
        this.setState({
            ...this.state,
            displayTable: true
        })
    }

    render() {
        return (
            <BootstrapContainer className="container-fluid">
                <Row className="justify-content-center">
                    <h3 className="text-danger font-weight-bolder">BETA Companies House Search Service</h3>
                </Row>
                <Hr/>
                <Form onSubmit={this.search} loading={this.props.loadingAll} additionalLoading={this.props.additionalLoading} />
                <div className={!this.state.displayTable ? "d-none" : ""}>
                    <CompaniesResultsTable/>                
                </div>
            </BootstrapContainer>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    dispatchQueryAll: (query, size) => dispatch(startQueryAll(query, size)),
    loadFormData: (query) => dispatch(initialize(formName, {searchTerm: query}))
});

const mapStateToProps = state => ({
    loadingAll: selector.loading(state),
    additionalLoading: selector.additionalLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesHouse);