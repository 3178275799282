import { HashRouter, Route } from "react-router-dom";
import Add from './add';
import Edit from './edit';
import Table from './table';

const ClientsRoot = () => (
    <>
        <div className="container-fluid p-0 pt-2">
            <HashRouter hashType="noslash">
                <Route exact path={"/"} component={Table}/>
                <Route exact path={"/Add"} component={Add}/>
                <Route exact path={"/Edit/:id"} component={Edit}/>
            </HashRouter>
        </div>
    </>
);


export default ClientsRoot;