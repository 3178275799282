import React from 'react';
import {SearchStatus} from '../../redux-reducers/globals';

export default ({ status }) => {

    if (status === SearchStatus.Searching) {
        return (
            <div className="align-center pull-right">
                <img src="/images/searching.gif" alt="searching" height="20" width="20" />
            </div>
        );
    }

    if (status === SearchStatus.Success) {
        return (
            <div className="align-center pull-right">
                <img src="/images/tick.png" alt="success" height="20" width="20" />
            </div>
        );
    }

    if (status === SearchStatus.Failed) {
        return (
            <div className="align-center pull-right">
                <img src="/images/cross.png" alt="failed" height="20" width="20" />
            </div>
        );
    }

    return <div></div>;
};