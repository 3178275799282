import React from "react";
const today = new Date();

export const Logo = () => (
  <div className="page-header">
    <div className="logo-container mb-2">
      <div className="logo">
        <a href="/">LoCTA Search</a>
      </div>
    </div>
  </div>
);

export const Footer = () => (
  <footer className="page-footer">
    <small className="copyright">
      Copyright &copy; LoCTA Limited 2000 – {today.getUTCFullYear()} All rights
      reserved
    </small>
  </footer>
);
