export default (searchType, result) => {
    switch(searchType){
      case "BusinessRates": return getEmailBodyBusinessRates(result);
      case "Benefits": return getEmailBodyBenefits(result);
      case "CouncilTaxAddress": return getEmailBodyCtaxAddress(result);
      case "CouncilTaxName": return getEmailBodyCtaxName(result);
      default: return null;
    }
  }
  
  const getEmailBodyBusinessRates = (result) => {
    var body = '';
    body += `Address: ${result.address ?? "N/A"}\r\n`
    body += `Charge Payer: ${result.chargePayer ?? "N/A"}\r\n`
    body += `Account Reference: ${result.accountReference ?? "N/A"}\r\n`
    body += `Date of Occupation: ${result.dateOfOccupation ?? "N/A"}\r\n`
    body += `Date of Vacation: ${result.dateOfVacation ?? "N/A"}\r\n`
    body += `Correspondence Address: ${result.correspondenceAddress ?? "N/A"}\r\n`
    body += `Property Reference: ${result.propertyReference ?? "N/A"}\r\n`
    body += `Liability Start Date: ${result.liabilityStartDate ?? "N/A"}\r\n`
    body += `Liability End Date: ${result.liabilityEndDate ?? "N/A"}\r\n`
    body += `Mixed Heriditament Indicator: ${result.mixedHeriditamentIndicator ?? "N/A"}\r\n`
    body += `Discretionary Relief: ${result.discretionaryRelief ?? "N/A"}\r\n`
    body += `Discretionary Relief Rate: ${result.discretionaryReliefRate ?? "N/A"}\r\n`
    body += `Rural Rate Relief: ${result.ruralRateRelief ?? "N/A"}\r\n`
    body += `Rural Rate Relief Percentage: ${result.ruralRateReliefPercentage ?? "N/A"}\r\n`
    body += `Hardship Relief: ${result.hardshipRelief ?? "N/A"}\r\n`
    body += `Hardship Relief Percentage: ${result.hardshipReliefPercentage ?? "N/A"}\r\n`
    body += `S B R Relief Indicator: ${result.sbrReliefIndicator ?? "N/A"}\r\n`
    body += `Rateable Value: ${result.rateableValue ?? "N/A"}\r\n`
    body += `Effective Date Of R V Change: ${result.effectiveDateOfRVChange ?? "N/A"}\r\n`
    body += `\r\n`;
  
    return body;
  }
  
  const getEmailBodyBenefits = (result) => {
    var body = '';
  
    body += `Forename: ${result.forename ?? "N/A"}\r\n`;
    body += `Surname: ${result.surname ?? "N/A"}\r\n`;
    body += `Start Date: ${result.startDate ?? "N/A"}\r\n`;
    body += `End Date: ${result.endDate ?? "N/A"}\r\n`;
    body += `Gender: ${result.gender ?? "N/A"}\r\n`;
    body += `Address: ${result.address ?? "N/A"}\r\n`;
    body += `\r\n`;
  
    return body;
  }
  
  
  const getEmailBodyCtaxAddress = (result) => {
    var body = '';
  
    body += `Forename: ${result.forename ?? "N/A"}\r\n`;
    body += `Surname: ${result.surname ?? "N/A"}\r\n`;
    body += `Start Date: ${result.startDateFormatted ?? "N/A"}\r\n`;
    body += `End Date: ${result.endDateFormatted ?? "N/A"}\r\n`;
    body += `Band: ${result.councilTaxBand ?? "N/A"}\r\n`;
    body += `Address: ${result.address ?? "N/A"}\r\n`;
    body += `\r\n`;
  
    return body;
  }
  
  
  const getEmailBodyCtaxName = (result) => {
      var body = '';
  
      body += `Forename: ${result.forename ?? "N/A"}\r\n`;
      body += `Surname: ${result.surname ?? "N/A"}\r\n`;
      body += `Start Date: ${result.startDate ?? "N/A"}\r\n`;
      body += `End Date: ${result.endDate ?? "N/A"}\r\n`;
      body += `Band: ${result.councilTaxBand ?? "N/A"}\r\n`;
      body += `Address: ${result.address ?? "N/A"}\r\n`;
      body += `\r\n`;
  
      return body;
  }