import React from 'react';

export const Div = ({className, children, ...rest}) => (
    <div className={className} {...rest}>
        {children}
    </div>
)

export const BootstrapContainer = ({className = '', ...rest}) => {
    return (
        <Div className={`b4 ${className}`} {...rest}/>
    );
};
