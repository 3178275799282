import * as Actions from '../redux-actions/feature-switches-actions.js';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.GET_FEATURE_SWITCHES_START:
            return {};
        case Actions.GET_FEATURE_SWITCHES_SUCCESS:
            return {
                ...action.data
            };
        case Actions.GET_FEATURE_SWITCHES_FAIL:
            return {};
        default:
            return state;
    }
}

export const selector = (state) => (fsName) => typeof(state.featureSwitches[fsName]) !== 'undefined' ? state.featureSwitches[fsName] : false;
export const featureSwitch = (fsName) => state => typeof(state.featureSwitches[fsName]) !== 'undefined' ? state.featureSwitches[fsName] : false;
