import { useState, useEffect } from 'react';
import { LoctaButton, PrimaryButton } from '../../bootstrap';

export default ({onClose, onSelectionChange, selectedDatasetIds, datasetOptions}) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            onSelectionChange([...selectedDatasetIds, id]);
          } else {
            onSelectionChange(selectedDatasetIds.filter((itemId) => itemId !== id));
          }
    }

    return (
        <>
            <div className={ `react-modal-anchor b4 ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal add-nino-alert ${visible ? 'show' : ''}` } style={{ width: '800px' }}>
                    <button onClick={onClose} className="close-button"><i className="fas fa-times"></i></button>
                    <h1>
                        <i className="far fa-calendar-plus"></i>
                        Accessible Datasets
                    </h1>

                    <div style={styles.checkboxGrid}>
                    {datasetOptions.map((option) => (
                        <div key={option.id} style={styles.checkboxContainer}>
                            <label style={styles.checkboxLabel}>
                                <input
                                    type="checkbox" 
                                    onChange={(e) => handleCheckboxChange(option.id, e.target.checked)}
                                    checked={selectedDatasetIds.includes(option.id)}
                                />
                                {option.name}
                            </label>
                        </div>
                    ))}
                    </div>

                    <hr className="light-gray" />

                    <div className="button-row p-15">
                        <LoctaButton icon="fas fa-times-circle" ButtonFn={PrimaryButton} onClick={onClose} textComponent="Close" className="mb-3" />
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}

const styles = {
    checkboxGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "10px",
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
    },
    checkboxLabel: {
      display: "flex",
      alignItems: "center",
    },
  };
  