import React from 'react';
import { Field, Form } from 'react-final-form';

import { LoctaButton, SecondaryButton } from '../../bootstrap';
import { renderInput, renderTextArea, renderCheckbox } from './inputs';

import validate from './validate';


let MyForm = ({handleSubmit, onClose, values}) => {
    
    const { body } = values;

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>

            <Field name="subject" type="text" render={renderInput} label="Subject" tabIndex={1} />
            
            <div>
                <span className="w-100 p-15 option-label">Details</span>
                <div className="w-100 p-15">
                    <pre>{body}</pre>
                </div>
            </div>
        
            <Field name="extraBody" render={renderTextArea} label="Message" tabIndex={2} rows={4} />
            
            <div>
                <span className="w-100 p-15 option-label">Information to request</span>
                <div className="w-100 p-15">
                <div className="field-row">
                    <Field name="requestPhoneDetails" render={renderCheckbox} label="Phone details" tabIndex={3} />
                    <Field name="requestCurrentPayments" render={renderCheckbox} label="Are they currently making payments?" tabIndex={6} />
                </div>
                <div className="field-row">
                    <Field name="requestEmailAddress" render={renderCheckbox} label="Email address" tabIndex={4} />
                    <Field name="requestEmploymentDetails" render={renderCheckbox} label="Employment details" tabIndex={7} />
                </div>
                <div className="field-row">
                    <Field name="requestRecentContact" render={renderCheckbox} label="Any recent contact?" tabIndex={5} />
                    <Field name="requestForwardingAddress" render={renderCheckbox} label="Forwarding address" tabIndex={8} />
                </div>
                </div>
            </div>

            <hr className="light-gray" />

            <div className="button-row p-15">
                <LoctaButton icon="fas fa-times-circle" ButtonFn={SecondaryButton} onClick={onClose} textComponent="cancel" className="mb-3" />
                <LoctaButton icon="fas fa-envelope-open-text" type="submit" textComponent="send" className="mb-3" />
            </div>
        
            <div className="alert alert-warning">
                <p>Using the Email function can help you verify if the person is still registered at an address, it can help identify additional occupants and could also highlight potential fraud.</p>
                <p><strong>LoCTA Search Emails are not sent securely so please do not send the individuals National Insurance Number (NINO) or any payment information.</strong></p>
            </div>
        </form>
    );
}


const EmailCouncilForm = ({ onSubmit, onClose, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={MyForm}
            onClose={onClose}
        />
    );
}

export default EmailCouncilForm;