import React from 'react';
import { Row, Col } from '../bootstrap';
import ResultsGrid from '../searches-results/business-rates/results-grid';

const BusinessRatesExport = ({ data }) => {
    if (!data) return null;

    const { DateOfVacation, LiabilityEndDate } = data;

    const borderColor = (DateOfVacation === 'N/A' && LiabilityEndDate === 'N/A') ? "#5ab45a" : "#f0f0f0";
    const borderStyle = { borderLeft: `6px solid ${borderColor}` };
    return (
        <Row className="mt-3 mb-3 pl-3 pt-3" style={borderStyle}>
            <Col size={12} >
                <ResultsGrid data={data} />
            </Col>
        </Row>
    )
}

export default BusinessRatesExport;
