import axios from "axios";

export const LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_FINISHED = 'LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_FINISHED';
export const LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_STARTED = 'LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_STARTED';

export const LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_STARTED = 'LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_STARTED';
export const LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_FINISHED = 'LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_FINISHED';

const summariesUrl = '/api/dissolved-companies/summaries';
const resultsUrl = (id) => `/api/dissolved-companies/report/${id}`;

const action = (type, data) => ({
    type, data
})

const loadSummaries = () => {
    return dispatch => {
        axios.get(summariesUrl).then(res => {
            dispatch(action(LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_FINISHED, res.data))
        }).catch(_ => {
            dispatch(action(LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_FINISHED, []));
        });
    }
}

const loadResults = (id) => {
    return dispatch => {
        axios.get(resultsUrl(id)).then(res => {
            dispatch(action(LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_FINISHED, {data:res.data, id}))
        }).catch(_ => {
            dispatch(action(LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_FINISHED, null));
        });
    }
}

export const startLoadingSummaries = () => {
    return dispatch => {
        dispatch(action(LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_STARTED));
        dispatch(loadSummaries());
    }
}

export const startLoadingResults = (id) => {
    return dispatch => {
        dispatch(action(LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_STARTED, {id}));
        dispatch(loadResults(id));
    }
}