import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchCouncils} from '../../../redux-actions/superuser-actions';
import {selector} from '../../../redux-reducers/councils-reducer';
import Table from './table';
import {BootstrapSpinner} from '../../bootstrap';

const CouncilsList = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCouncils());
    }, [dispatch]);

    const councils = useSelector(selector.councils);

    let result = <></>;

    if(councils.length === 0)
        result = <BootstrapSpinner loadingMessage='Loading councils data ...'/>
    else 
        result = <Table councils={councils.records}/>;

    return result;
}


export default CouncilsList;