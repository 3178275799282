import React, {useEffect, useState} from 'react';
import CouncilForm from './council-form';
import axios from 'axios';
import {BootstrapSpinner} from '../../bootstrap';
import {useHistory} from 'react-router-dom';


const EditForm = ({match:{params:{id}}}) => {
    const [initialValues, setInitialValues] = useState(null);
    const [options, setOptions] = useState(null);
    const editApi = `/api/data-providers/${id}`;
    const history = useHistory();

    useEffect(() => {
        axios.get(editApi).then(res => {
            const result = res.data;
            setInitialValues(result.values);
            setOptions(result.options);
        });
    }, [editApi])

    const onSubmit = async (values) => {
        let response = await axios.put(editApi, values);
        if(response.data.length === 0){
            history.goBack();
        }
        return response.data;
    };

    if(initialValues === null || options === null){
        return <BootstrapSpinner loadingMessage='Loading council settings...'/>
    }

    return <CouncilForm initialValues={initialValues} options={options} onSubmit={onSubmit}/>;
};

export default EditForm;