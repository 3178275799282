import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import { selector } from '../../../redux-reducers/telephone-directory-search-reducer';

import { LoctaButton } from '../../bootstrap';
import AddToProfileModal from '../../modals/add-to-profile';

export default ({row}) => {

    const searchedValues = useSelector(state => selector.searchedValues(state));
    const searchType = useSelector(state => selector.searchType(state));

    const [showAddToProfileModal, setShowAddToProfileModal] = useState(false);

    const addToProfileModal = showAddToProfileModal 
        ? <AddToProfileModal onClose={() => setShowAddToProfileModal(false)} searchType={searchType} result={row.original} /> 
        : null;

    return (
        <>
            <LoctaButton style={{width: '140px !important'}} iconSize="16px" icon="fas fa-user-plus" textComponent={(<>add to profile</>)} onClick={() => setShowAddToProfileModal(true)} />
            { addToProfileModal }
        </>
    );
};