import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';
import ReactTable from 'react-table-v6';
import Pagination from '../pagination';
import columns from './results-columns';
import PrintHeader from '../print-header';

const Results = ({ results }) => {

    const componentRef = useRef();

    return (
        <section className="search-results">
            <header className="block-header bg-blue-grad blue-header has-tabs" id="resultsHeader">
                <ul className="nav nav-tabs pull-left">
                    <li className="active"><a href="#all-results" data-toggle="tab">All Results</a></li>
                </ul>
                <ul className="nav option-tabs pull-right">
                    <li className="active pull-right">
                        <ReactToPrint
                            trigger={() => <button>PDF / Print</button>}
                            content={() => componentRef.current}
                        />
                    </li>
                </ul>
            </header>

            <section className="results-group telephone-directory-results-group" ref={componentRef}>
                <PrintHeader text="VotersRolls Results" />
                <ReactTable
                    PaginationComponent={Pagination}
                    data={results}
                    columns={columns}
                    showPagination={true}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    defaultPageSize={10}
                    filterable={false}
                    minRows={0}
                />
            </section>

        </section>
    );
};

export default Results;
