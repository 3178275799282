import React from 'react'

const DangerActionModal = ({ closeModal, action, title, message, cancelText, text }) => {
  return (
    <div className="b4-modal-content">
      <div className="b4-modal-header">
        <h5
          className="b4-modal-title"
        >{title}</h5>
        <button type="button" className="close" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="b4-modal-body">
        <p>{message}</p>
      </div>
      <div className="b4-modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeModal}>{cancelText || 'Cancel'}</button>
        <button type="button" className="btn btn-danger" onClick={action}>{text || 'Continue'}</button>
      </div>
    </div>
  )
}

export default DangerActionModal;