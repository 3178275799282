import DevStore from "./dev-store";
import ProdStore from "./prod-store";
import {getFeatureSwitches} from '../../redux-actions/feature-switches-actions';

export default (rootReducer, hotPath, preloadedState) => {
  let store;
  if (process.env.NODE_ENV === "production") {
    store = ProdStore(preloadedState, rootReducer);
  } else {
    store = DevStore(preloadedState, rootReducer, hotPath);
  }

  store.dispatch(getFeatureSwitches());

  return store;
};
