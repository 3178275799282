import React from 'react';
import './index.css';

export const Loading = ({message, spinner = true, className=""}) => {
    return (
        <div className={`loading-spinner-root ${className}`}>
            <div>
                <p className="loading-spinner-message">{message}</p>
                {spinner ? <img src="/cdn/images/spinner.gif" className="loading-spinner-img" alt="Loading..." /> : ""}
            </div>
        </div>
    );
}

export default Loading;