import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Card from '../../../shared/Card';
import { BootstrapContainer, LoctaButton, WarningButton, Row, Col } from '../../../bootstrap';
import { renderInput } from '../../../shared/search-inputs';
import { formName } from '../../../../redux-actions/quick-search-alerts-actions';

const AddAlertForm = ({ query, handleSubmit }) => {

    return (
        <Card title="haven't found what you are looking for?" className="quick-search-alert orange w-100">
            <form onSubmit={handleSubmit}>
                <BootstrapContainer>
                    <Row className="alert-description">
                        <Col size={12}>
                            <p>If you would like us to automate this search and notify you when we find different results, simply provide a reference below and we will do the rest :</p>
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col size={12}>
                            <Field name="reference" type="text" component={renderInput} label="My Reference" showLabel={false} className="form-control form-control-sm" />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col size={3}>Query:</Col>
                        <Col size={9}>{query}</Col>
                    </Row>
                    <Row className="form-group">
                        <Col size={3}>Expiry Date:</Col>
                        <Col size={9}>12 months</Col>
                    </Row>
                    <Row>
                        <Col size={7}></Col>
                        <Col size={5}>
                            <Field name="query" component="input" type="hidden" />
                            <Field name="hitCount" component="input" type="hidden" />
                            <LoctaButton small={true} icon="fas fa-play-circle" ButtonFn={WarningButton} textComponent={<>Add Alert</>} iconSize="16px" fontSize="12px" type="submit" />
                        </Col>
                    </Row>
                </BootstrapContainer>
            </form>
        </Card>
    );
};

const validate = values => {
    const errors = {};

    if (!values.reference || values.reference === '') {
        errors.reference = 'Required';
    }

    return errors
}

const AddAlertReduxForm = reduxForm({
    form: formName,
    validate
})(AddAlertForm);

export default AddAlertReduxForm;


