import DomConfigRepository from '../../../repositories/domConfigRepository';

const sv = DomConfigRepository.getJsonConfigById('searchValues');
export const initialValues = {
    surname: sv.surname,
    companyName: sv.companyName,
    location: sv.location || '',

    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};

export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;