import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import {connect} from 'react-redux';
import {selector, changeLogState} from '../../../redux-reducers/users-reducer';
import {stringAcc, dateAcc, fuzzyFilter} from '../../table';

const columns = [
    stringAcc('Change', 'change'),
    stringAcc('From Value', 'from'),
    stringAcc('To Value', 'to'),
    dateAcc('When', d => d.when, 'when'),
    stringAcc('Changed By', 'changedBy'),
    stringAcc('Username', 'username'),
]

class UserLog extends Component {
    constructor(props){
        super(props);

        this.logTable = this.logTable.bind(this);
    }

    logTable() {
        return (
            <div className="ReactTable">
                <ReactTable
                    data={this.props.changeLogData}
                    columns={columns}
                    defaultPageSize={10}
                    filterable={true}
                    defaultFilterMethod={fuzzyFilter}
                    defaultSorted={[
                        {
                          id: "when",
                          desc: true
                        }
                      ]}
                />
            </div>
        );
    }

    render() {
        switch(this.props.changeLogState){
            case changeLogState.UNAVAILABLE:
                return <p>Log UNAVAILABLE</p>;
            case changeLogState.LOADING:
                return <p>Loading...</p>;
            default:
                return this.logTable();
        }
    }
}

const mapStateToProps = (state) => ({
    changeLogState: selector.changeLogState(state),
    changeLogData: selector.changeLogData(state)
})

export default connect(mapStateToProps)(UserLog);