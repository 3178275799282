import React, { Component } from 'react';
import UserLog from './log';
import {connect} from 'react-redux';
import {deleteUser, getUserChangeLog, reenableUser} from '../../../redux-actions/superuser-actions';
import {ModalSizes, ModalTypes} from '../../modals'
import {hideModal, showModal} from '../../../redux-actions/modal-actions';
import {Row, DangerButton, SuccessButton, DangerBadge, SuccessBadge, Col, PrimaryButton} from '../../bootstrap';

const DeletedStatus = ({isDeleted}) => {
    if(isDeleted){
        return (
            <DangerBadge>Deleted</DangerBadge>
        )
    } else {
        return <SuccessBadge>Active</SuccessBadge>
    }
}

class ManageUserModal extends Component {

    constructor(props){
        super(props);
        const {accessId} = props.user;

        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.openUserLogModal = this.openUserLogModal.bind(this);
        this.openReenableModal = this.openReenableModal.bind(this);

        this.props.onViewUserLog(accessId);

    }

    openDeleteModal() {
        const {username, accessId} = this.props.user;
        this.props.showModal({
         open: true,
         title: 'Remove User?',
         message: `Are you sure you want to delete this user - ${username}?`,
         cancelText: 'Cancel',
         text: 'Remove',
         action: () => { 
             this.props.onDeleteUser(accessId)
             this.props.hideModal();
            },
         closeModal: this.props.hideModal
       }, ModalTypes.danger, ModalSizes.default)
    }

    openReenableModal() {
        const {username, accessId} = this.props.user;
        this.props.showModal({
         open: true,
         title: 'Re-enable User?',
         message: `Are you sure you want to re-enable this user - ${username}?`,
         cancelText: 'Cancel',
         text: 'Re-enable',
         action: () => { 
             this.props.onReenableUser(accessId)
             this.props.hideModal();
            },
         closeModal: this.props.hideModal
       }, ModalTypes.success, ModalSizes.default)
    }

    openUserLogModal() {
        const {accessId} = this.props.user;
        this.props.showModal({
            open: true,
            title: 'User Log',
            body: <UserLog userId={accessId}/>,
            closeModal: this.props.hideModal
          }, 'generic', ModalSizes.large)
    }

    render() {
        const {username, accessId, isDeleted} = this.props.user;

        let manageAccountStatus;
        if(!isDeleted){
            manageAccountStatus = <DangerButton onClick={this.openDeleteModal} small>Delete user</DangerButton>
        } else {
            manageAccountStatus = <SuccessButton onClick={this.openReenableModal} small>Re-enable user</SuccessButton>;
        }
        return (
            <Row>
                <Col size={12} className={'pt-2 justify-content-between'}>
                    <h5>{username} <DeletedStatus IsDeleted={isDeleted}/></h5>
                </Col>
                <Col size={12}>
                    <Row className='justify-content-around'>
                        <PrimaryButton onClick={() => window.location.href=`/Users/Edit/${accessId}`} small>Edit User</PrimaryButton>
                        {manageAccountStatus}
                    </Row>
                </Col>
                <Col size={12} className={'pt-2 justify-content-center'}>
                    <UserLog userId={accessId}/>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = dispatch => ({ 
    hideModal: () => dispatch(hideModal()),
    showModal: (modalProps, modalType, modalSize) => {
     dispatch(showModal({ modalProps, modalType, modalSize }))
    },
    onDeleteUser: (userId) => {dispatch(deleteUser(userId))},
    onReenableUser: (userId) => {dispatch(reenableUser(userId))},
    onViewUserLog: (userId) => {dispatch(getUserChangeLog(userId))}
});

export default connect(null, mapDispatchToProps)(ManageUserModal);