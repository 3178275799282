import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from './form';
import { BootstrapContainer } from '../bootstrap';
import { submitForm } from '../../redux-actions/diagnostics-actions';
import { selector } from "../../redux-reducers/diagnostics-reducer";


class TestingGrounds extends Component {

    submitForm = (model) => {
        this.props.submitForm(model);
    }

    render() {
        let results = <>Waiting for form submission</>;
        if (this.props.data) {
            const { DatePicker, AllOk } = this.props.data;
            results =
                <>
                    DatePicker: {'' + DatePicker} <br/>
                    AllOk: {'' + AllOk}
                </>;
        }
        return (
            <BootstrapContainer className="container-fluid">
                <Form onSubmit={this.submitForm} loading={this.props.loading} />
                {results}
            </BootstrapContainer>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    submitForm: (model) => dispatch(submitForm(model))
});

const mapStateToProps = state => ({
    loading: selector.loading(state),
    data: selector.data(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TestingGrounds);