import React, { useState, useEffect } from 'react';

import Confirmation from './confirmation';
import EmailCouncilForm from './form';

import { emailCouncilAsync } from './api';
import getEmailBody from './get-email-body';

export default ({onClose, searchType, result}) => {

    const [visible, setVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
 

    let initialValues = {
        searchType: searchType,
        recordId: result.id,
        dataProviderId: result.dataProviderId,
        body: getEmailBody(searchType, result)
    };


    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible,]);


    const onSubmit = async (formValues) => {
        var result = await emailCouncilAsync(formValues);

        if (Object.keys(result).length) {
            return result;
        }

        setShowConfirmation(true);
    }


    const style = showConfirmation ? { width: '400px' } : { width: '760px'};
    const page = showConfirmation
        ? <Confirmation onClose={onClose} />
        : <EmailCouncilForm 
            onSubmit={onSubmit} 
            onClose={onClose}
            initialValues={initialValues} />;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? ' show' : '')}`}>
                <div className={ `react-modal email-council ${visible ? 'show' : ''}` } style={style}>
                    <div style={{overflow:"auto", height:"100%"}}>
                        <button onClick={onClose} className="close-button"><i className="fas fa-times"></i></button>
                        <h1>
                            <i className="fas fa-envelope-open-text"></i>
                            Email Council
                        </h1>

                        { page }
                    </div>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
}
