import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';
import PrintHeader from "../print-header";
import ResultsTable from './results-table';
import { BootstrapContainer } from '../../bootstrap';

const Results = ({results}) => {
    const componentRef = useRef();
    return (
        <BootstrapContainer>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:'40vh'}}>
                <section className="search-results w-100">
                    <header className="block-header bg-blue-grad blue-header has-tabs" id="resultsHeader">
                        <ul className="nav nav-tabs pull-left">
                            <li className="active"><a href="#all-results" data-toggle="tab">All Results</a></li>
                        </ul>
                        <ul className="nav option-tabs pull-right">
                            <li className="active pull-right">
                                <ReactToPrint
                                    trigger={() => <button>PDF / Print</button>}
                                    content={() => componentRef.current}
                                />
                            </li>
                        </ul>
                    </header>
                    <div ref={componentRef}>
                        <PrintHeader text="Deceased Results" />
                        <ResultsTable data={results} />
                    </div>
                </section>
            </div>
        </BootstrapContainer>
    );
}

export default Results;