import axios from 'axios';
import { SearchStatus } from '../redux-reducers/globals';

export const UPDATE_RESULT = 'LOCTA_PLUS__UPDATE_RESULT';
export const SEARCH_HAS_STARTED = 'LOCTA_PLUS__SEARCH_HAS STARTED';
export const UPDATE_SEARCH_PROGRESS = 'LOCTA_PLUS__UPDATE_SEARCH_PROGRESS';
export const UPDATE_SEARCH_ERROR = 'LOCTA_PLUS__UPDATE_SEARCH_ERROR';
export const RESET_SEARCH = 'LOCTA_PLUS__RESET_SEARCH';
export const SET_COUNCILS = 'LOCTA_PLUS__SET_COUNCILS';
export const SHOW_HELP_MODAL = 'LOCTA_PLUS__SHOW_HELP_MODAL';
export const CLOSE_HELP_MODAL = 'LOCTA_PLUS__CLOSE_HELP_MODAL';

const toServerValues = (values) => {
    console.log(values);
    return {
        ...values,
        dateOfBirth: Date.formatForServer(values.dateOfBirth),
        searchStart: Date.formatForServer(values.searchStart),
        searchEnd: Date.formatForServer(values.searchEnd),
    };
}


export function performSearch(searchValues) {
    return (dispatch) => {

        var serverValues = toServerValues(searchValues);

        dispatch(searchHasStarted(searchValues));

        axios.post('/api/locta-plus/token/', serverValues)
        .then(response => {
            serverValues.id = response.data;

            if (serverValues.searchBenefits) {
                dispatch(searchBenefits(serverValues));
            }

            if (serverValues.searchCouncilTaxName) {
                dispatch(searchCouncilTaxName(serverValues));
            }

            if (serverValues.searchVotersRoll) {
                dispatch(searchVotersRoll(serverValues));
            }

            if (serverValues.searchVotersRollByAddress) {
                dispatch(searchVotersRollByAddress(serverValues));
            }

            if (serverValues.searchDirectory) {
                dispatch(searchDirectory(serverValues));
            }

            if (serverValues.searchDeceased) {
                dispatch(searchDeceased(serverValues));
            }

            if (serverValues.searchCouncilTaxAddress) {
                dispatch(searchCouncilTaxAddress(serverValues));
            }
        })
        .catch(err => {
            console.warn(err);
        });
    };
}

function searchHasStarted(searchValues) {
    return { type: SEARCH_HAS_STARTED, data: searchValues };
}

function searchBenefits(searchValues) {
    return performNonModalSearch('benefits', '/api/locta-plus/benefits', searchValues);
}

function searchCouncilTaxName(searchValues) {
    return performNonModalSearch('councilTaxName', '/api/locta-plus/council-tax-name', searchValues);
}

function searchVotersRoll(searchValues) {
    return performNonModalSearch('votersRoll', '/api/locta-plus/votersroll', searchValues);
}

function searchVotersRollByAddress(searchValues) {
    return performNonModalSearch('votersRollByAddress', '/api/locta-plus/votersroll-by-address', searchValues);
}

function searchDirectory(searchValues) {
    return performNonModalSearch('directory', '/api/locta-plus/directory', searchValues);
}

function searchDeceased(searchValues) {
    return performNonModalSearch('deceased', '/api/locta-plus/deceased', searchValues);
}

function searchCouncilTaxAddress(searchValues) {
    return performNonModalSearch('councilTaxAddress', '/api/locta-plus/council-tax-address', searchValues);
}

function performNonModalSearch(type, url, searchValues, onComplete) {
    return (dispatch) => {
        dispatch(updateSearchProgress({ type, status: SearchStatus.Searching }));
        axios.post(url, searchValues)
        .then(response => {
            let data = response.data;
            dispatch(updateStoreWithResult({ type, status: SearchStatus.Success, ...data}));
                
            setTimeout(function () {
                dispatch(updateSearchProgress({ type, status: SearchStatus.Empty }));
            }, 4000);

            onComplete && onComplete();
        })
        .catch(err => {
            console.log({ type, url, searchValues});
            console.log('err', err);
            dispatch(updateSearchProgress({ type, status: SearchStatus.Failed }));
            dispatch(addSearchError(err.response.data.message));
        })
    };
}


function updateSearchProgress(result) {
    return { type: UPDATE_SEARCH_PROGRESS, data: result };
}

function addSearchError(message) {
    return { type: UPDATE_SEARCH_ERROR, data: message };
}

function updateStoreWithResult(result) {
    return { type: UPDATE_RESULT, data: result };
}

export function resetSearch() {
    return { type: RESET_SEARCH };
}

export function showHelpModal() {
    return { type: SHOW_HELP_MODAL };
}

export function closeHelpModal() {
    return { type: CLOSE_HELP_MODAL };
}