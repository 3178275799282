import DomConfigRepository from '../../../repositories/domConfigRepository';
import config from '../config';

const sv = DomConfigRepository.getJsonConfigById('searchValues');

export const initialValues = {
    companyName: sv.companyName,
    postcode: sv.postcode,
    searchStartDate: Date.parseForReact(sv.searchStartDate),
    searchEndDate: Date.parseForReact(sv.searchEndDate),
    selectedCouncilId: sv.selectedCouncilId,
    maxSearchDistance: typeof(sv.maxSearchDistance) === 'undefined' || sv.maxSearchDistance === 0 ? config.sliderStartingValue : sv.maxSearchDistance,
    hasSbr: sv.hasSbr,
    hasMixedHereditament: sv.hasMixedHereditament,
    email: sv.email,
    selectedCompanyIds: sv.selectedCompanyIds,

    initialSearch: sv.initialSearch,
    linkedSearch: sv.linkedSearch,
    secondLinkedSearch: sv.secondLinkedSearch,
    
    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};
export const councils = sv.councils || {};