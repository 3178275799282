import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';
import { selector } from '../redux-reducers/business-rates-search-reducer';

export const BUSINESS_RATES_SEARCH_STAGING_START = 'BUSINESS_RATES_SEARCH_STAGING_START';
export const BUSINESS_RATES_SEARCH_STAGING_SUCCESS = 'BUSINESS_RATES_SEARCH_STAGING_SUCCESS';
export const BUSINESS_RATES_SEARCH_STAGING_FAIL = 'BUSINESS_RATES_SEARCH_STAGING_FAIL';

export const BUSINESS_RATES_SEARCH_RESET = 'BUSINESS_RATES_SEARCH_RESET';

export const BUSINESS_RATES_PERFORMSEARCH_START = 'BUSINESS_RATES_PERFORMSEARCH_START';
export const BUSINESS_RATES_PERFORMSEARCH_SUCCESS = 'BUSINESS_RATES_PERFORMSEARCH_SUCCESS';
export const BUSINESS_RATES_PERFORMSEARCH_FAIL = 'BUSINESS_RATES_PERFORMSEARCH_FAIL';


export const urls = {
    companyList: `/api/search/business-rates/company-list`,
    search: `/api/search/business-rates/search`,
    validate: `/api/search/business-rates/validate`
}

export const formName = "business-rates-search";

class BusinessRatesSearchViewModel {
    constructor(formValues, logId) {
        this.CompanyName = formValues.companyName;
        this.PostCode = formValues.postcode;
        this.SearchStartDate = Date.formatForServer(formValues.searchStartDate);
        this.SearchEndDate = Date.formatForServer(formValues.searchEndDate);
        this.IsLive = formValues.isLive;
        this.SelectedCouncilId = formValues.selectedCouncilId;
        this.MaxSearchDistance = formValues.maxSearchDistance;
        this.HasSbr = formValues.hasSbr;
        this.HasMixedHereditament = formValues.hasMixedHereditament;
        this.SelectedCompanyIds = formValues.selectedCompanyIds;

        this.InitialSearch = formValues.initialSearch;
        this.LinkedSearch = formValues.linkedSearch;
        this.SecondLinkedSearch = formValues.secondLinkedSearch;
        this.IsLoctaPlusSearch = formValues.isLoctaPlusSearch;
        this.IsQuickSearch = formValues.isQuickSearch;
        if(logId){
            this.LogId = logId;
        }

        this.__RequestVerificationToken = formValues.__requestVerificationToken;
    }
}

export const baseValues = {
    companyName: '',
    postcode: '',
    searchStartDate: null,
    searchEndDate: null,
    isLive: false,
    selectedCouncilId: 0,
    maxSearchDistance: 0,
    hasSbr: false,
    hasMixedHereditament: false,
    selectedCompanyIds: []
};

const action = (type, data = null) => ({
    type, data
});

export const searchStaging = (formValues) => {
    return async (dispatch) => {

        dispatch(action(BUSINESS_RATES_SEARCH_STAGING_START, formValues));

        const viewModel = new BusinessRatesSearchViewModel(formValues);

        const validateResponse = await axios.post(urls.validate, viewModel);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            goToStep(2);
            const searchResponse = await axios.post(urls.companyList, viewModel);
            dispatch(action(BUSINESS_RATES_SEARCH_STAGING_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(BUSINESS_RATES_SEARCH_STAGING_FAIL));
        }
    }
}

export const search = (selectedCompanyIds) => {
    return async (dispatch, getState) => {
        const state = getState();
        const searchedValues = selector.searchedValues(state);
        const logId = selector.logId(state);
        const newFormValues = {...searchedValues, selectedCompanyIds};
        dispatch(action(BUSINESS_RATES_PERFORMSEARCH_START, newFormValues));
        goToStep(3);

        const viewModel = new BusinessRatesSearchViewModel(newFormValues, logId);

        try {
            const searchResponse = await axios.post(urls.search, viewModel);
            dispatch(action(BUSINESS_RATES_PERFORMSEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(BUSINESS_RATES_PERFORMSEARCH_FAIL));
        }
    }
}


export const validateSearch = (model) => {
    return async (dispatch) => {
        const formValues = new BusinessRatesSearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export const resetSearch = (values) => {
    return (dispatch) => {
        dispatch(action(BUSINESS_RATES_SEARCH_RESET));
        dispatch(initialize(formName, { ...values, ...baseValues }));
        goToStep(1);
    }
}

const goToStep = (step) => window.location.hash=`step${step}`;