import React, { useState, useEffect } from 'react';

import Confirmation from './confirmation';
import AddNinoAlertForm from './form';

import { addNinoAlertAsync } from './api';

export default ({onClose, searchedValues, results}) => {

    const [visible, setVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    let initialValues = {
        surname: searchedValues.surname,
        nino: searchedValues.nino,
        dateOfBirth: searchedValues.dateOfBirth ? searchedValues.dateOfBirth.toLocaleDateString() : '',
        openCasesOnly: searchedValues.openCasesOnly,
        isPlusSearch: searchedValues.isLoctaPlusSearch,
        isLinkedSearch: searchedValues.isLinkedSearch,
        isQuickSearch: searchedValues.isQuickSearch,
        resultsCount: results.reduce((prev, curr) => prev + curr.recordsFound, 0)
    };

    
    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    
    const onSubmit = async (formValues) => {
        let result = await addNinoAlertAsync(formValues);

        if (Object.keys(result).length) {
            return result;
        }

        setShowConfirmation(true);
    }
    

    let style = showConfirmation ? { width: '400px' } : { width: '700px' };
    let page = showConfirmation
        ? <Confirmation onClose={onClose} />
        : <AddNinoAlertForm onSubmit={onSubmit} initialValues={initialValues} onClose={onClose} />;

    return (
        <>
            <div className={ `react-modal-anchor b4 ${(visible ? 'show' : '')}`}>
                <div className={ `react-modal add-nino-alert ${visible ? 'show' : ''}` } style={ style }>
                    <button onClick={onClose} className="close-button"><i className="fas fa-times"></i></button>
                    <h1>
                        <i className="far fa-calendar-plus"></i>
                        Add NINO Alert
                    </h1>

                    { page }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
    
}