const hasValue = value => (value || typeof value === 'number')

export default (values) => {

    const errors = {}

    if (!hasValue(values.subject)) {
        errors.subject = 'Required'
    }

    if (!hasValue(values.extraBody)) {
        errors.extraBody = 'Required'
    }

    return errors;
};