import * as Actions from '../redux-actions/dissolved-companies-actions';

const initialState = {
    summaries: [],
    results: {},
    loading: false,
    reports: {}
};

const loadSummariesFinished = (state, action) => {
    let summaries = action.data;
    let reports = {};
    summaries.forEach(summary => {
        reports[summary.Id] = {
            loading: false,
            data: null
        };
    });
    return {...state, summaries, reports, loading:false};
}

const loadResultsStart = (state, action) => {
    let reports = {...state.reports};
    reports[action.data.id] = {
        loading: true,
        data: null
    };
    return {...state, reports};
}

const loadResultsFinished = (state, action) => {
    let reports = {...state.reports};
    reports[action.data.id] = {
        loading: false,
        data: action.data.data
    };
    return {...state, reports};
}


export default function reducer(state = initialState, action = { type: 'NOT_SET' }) {
    switch (action.type) {
        case Actions.LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_STARTED:
            return {...state, summaries: [], loading: true, reports: {}};
        case Actions.LOAD_DISSOLVED_COMPANIES_SUMMARIES_DATA_FINISHED:
            return loadSummariesFinished(state, action);
        case Actions.LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_STARTED:
            return loadResultsStart(state, action);
        case Actions.LOAD_DISSOLVED_COMPANIES_RESULTS_DATA_FINISHED:
            return loadResultsFinished(state, action);
        default:
            return state;
    }
}

export const selector = {
    getSummaries: (state) => state.dissolvedCompanies.summaries,
    getResults: (state) => state.dissolvedCompanies.reports,
    isLoading: (state) => state.dissolvedCompanies.loading
};