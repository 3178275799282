import React from 'react';

export const renderInput = ({input, label, placeholder, type, meta: { touched, error }}) => (
    <div className="w-100">
        <span className="w-100 p-15 option-label">{label}</span>
        <div className="w-100 p-15">
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                className={
                    "input-block-level non-uppercase" +
                    (touched && error ? " invalid-search-input" : "")
                }
            />
        </div>
    </div>
);

export const renderTextArea = ({input, label, rows, meta: { touched, error }}) => (
    <div className="w-100">
        <span className="w-100 p-15 option-label">{label}</span>
        <div className="w-100 p-15">
            <textarea
                {...input}
                rows={rows}
                className={
                    "input-block-level non-uppercase" +
                    (touched && error ? " invalid-search-input" : "")
                }
            >
            </textarea>
        </div>
    </div>
);

export const renderCheckbox = ({input, label, meta: { touched, error }}) => (
    <label className="w-100" htmlFor={input.name}>
        <input
            {...input}
            type="checkbox"
            id={input.name}
            />
        {label}
    </label>
);
