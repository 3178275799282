export const routes = {
    adminDashboard: '/AdminDashboard/Home',
    userDashboard: '/UserDashboard/Home',
    ninomatch:'/ninomatch',
    userAccounts: '/accounts',
    sbr:'/sbr',
    dissolvedCompanies: '/dissolvedcompanies',
    companiesHouse: '/search/companieshouse',
    companiesHouseQuery: `/search/companieshouse/:query?`,
    home: '/',
    homeQuery: '/:query?',
    loctaPlus: '/LoctaPlus',
    testingGrounds: '/diagnostics/ReactTestingGrounds',
    benefitsSearch: '/Search/Benefits',
    councilTaxAddressSearch: '/Search/CouncilTaxAddress',
    councilTaxNameSearch: '/Search/CouncilTaxName',
    quickSearch: '/Search/Quick',
    businessRatesSearch: '/Search/BusinessRates',
    telephoneDirectorySearch: '/Search/Directory',
    votersRollSearch: '/Search/VotersRoll',
    votersRollByAddressSearch: '/Search/VotersRollByAddress',
    deceased: '/Search/Deceased'
}

export const externalRoutes = {
    chSearch: (query) => `https://beta.companieshouse.gov.uk/search?q=${query}`,
    chOfficerSearch: (companyNumber) => `https://beta.companieshouse.gov.uk/company/${companyNumber}`
}