import * as Actions from '../redux-actions/nino-match-actions';

const initialState = {
    summaries: [],
    loading: false
};


export default function reducer(state = initialState, action = { type: 'NOT_SET' }) {
    switch (action.type) {
        case Actions.LOAD_NINO_MATCH_SUMMARIES_DATA_STARTED:
            return {...state, summaries: [], loading: true};
        case Actions.LOAD_NINO_MATCH_SUMMARIES_DATA_FINISHED:
            return {...state, summaries: action.data, loading: false};
        default:
            return state;
    }
}

export const selector = {
    getSummaries: (state) => state.ninoMatch.summaries,
    isLoading: (state) => state.ninoMatch.loading
};