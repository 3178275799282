import React from 'react';

const Card = ({title, className, children}) => {
    return (
        <div className={`plus-article quick-search-item locta-card ${className}`}>
            <header className="header">
                <h3>{ title }</h3>
            </header>
            <div className="body grad-white-grey clearfix">
                {children}
            </div>
        </div>
    );
};

export default Card;