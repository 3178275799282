import React, { useState, useCallback } from 'react';
import { initialValues, isAutoSearch } from './server-values';
import api from './api';
import SearchFields from './search-fields';
import ResultsSummaries from '../../searches-results/telephone-directory-v2';
import NoResults from '../no-results';
import Loading from '../loading';

const TelephoneDirectorySearch = () => {

    const [searchedValues, setSearchedValues] = useState(initialValues);
    const [results, setResults] = useState([]);
    const [searchDone, setSearchDone] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const onFormSubmit = useCallback(async searchValues => {

        setIsLoading(true);

        const validate = await api.validate(searchValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors) {

            setResults([]);
            setSearchDone(false);
            setIsLoading(false);

            return validate.fieldErrors;
        }
        else {
            try {
                const search = await api.search(searchValues);
                setResults(search || []);
                setSearchedValues(searchValues);
            }
            catch (err) {
                setResults([]);
            }

            setIsLoading(false);
            setSearchDone(true);
        }

    }, [setResults, setIsLoading, setSearchDone]);


    const onFormReset = useCallback(form => (_) => {
        form.reset();
        setSearchedValues(initialValues);
        setIsLoading(false);
        setSearchDone(false);
    }, [setSearchDone, setSearchedValues, setIsLoading]);


    const hasResults = results.length > 0;
    const hasLinkedSearch = searchedValues.secondLinkedSearch === '' && !searchedValues.isQuickSearch;
    
    const content = isLoading 
        ? <Loading message="Searching data ..." /> 
        : !searchDone 
            ? null 
            : hasResults 
                ? <ResultsSummaries searchedValues={searchedValues} searchType={"CouncilTaxName"} hasLinkedSearch={hasLinkedSearch} results={results} /> 
                : <NoResults />;
    

    return (
        <section className="span12 search-container searches">
            <header className="block-header block-grey rt">
                <span className="icon-surround"><i className="telephone-directory-small"></i></span>
                <h2>Telephone Directory</h2>
                <span className="search-info-link remove-bold" title="Searches may be made on specific combinations of the fields. &#013; Permitted searches are: &#013; - Surname or Company Name + Location (This can be a Town, City, or Postcode including partial postcode, e.g. M41.) &#013; - Surname requires a minimum of 4 characters and can use the wildcard character * (asterisk). &#013; - Company Name requires a minimum of 3 characters and is automatically wildcarded.">(?)</span>
            </header>
            <SearchFields onSubmit={onFormSubmit} onReset={onFormReset} initialValues={initialValues} isAutoSearch={isAutoSearch} searchDone={searchDone}/>

            <div id="spacer" style={{height: "0px", clear:"both"}}></div>

            { content }
        </section>
    );
}

export default TelephoneDirectorySearch;