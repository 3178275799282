import * as Actions from '../redux-actions/benefits-search-actions';

export const initialSearchedValues = {
    surname: '',
    nino: '',
    dateOfBirth: '',
    openCasesOnly: false,
    initialSearch: 'Benefits',
    linkedSearch: '',
    isLinkedSearch: false,
    secondLinkedSearch: '',
    isLoctaPlusSearch: false,
    isQuickSearch: false,
    telephone: '',
    email: ''
};

const initialState = {
    searchType: "Benefits",
    searchedValues: {...initialSearchedValues},
    results: [],
    searchHasBeenPerformed: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.BENEFITS_SEARCH_START:
            return {
                ...state,
                searchedValues: action.data,
                searchHasBeenPerformed: false,
                results: []
            };
        case Actions.BENEFITS_SEARCH_SUCCESS:
            return {
                ...state,
                searchHasBeenPerformed: true,
                results: action.data
            };
        case Actions.BENEFITS_SEARCH_FAIL:
            return {
                ...state,
                searchHasBeenPerformed: true,
                results: []
            };
        case Actions.BENEFITS_SEARCH_RESET:
            return {
                ...state,
                searchHasBeenPerformed: false,
                results: []
            };
        default:
            return state;
    }
}

export const selector = {
    searchType: (state) => state.benefitsSearch.searchType,
    searchedValues: (state) => state.benefitsSearch.searchedValues,
    results: (state) => state.benefitsSearch.results,
    hasLinkedSearch: (state) => (state.benefitsSearch.searchedValues.secondLinkedSearch === '' && !state.benefitsSearch.searchedValues.isQuickSearch),
    searchHasBeenPerformed: (state) => state.benefitsSearch.searchHasBeenPerformed
};
