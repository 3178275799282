import React from 'react';
import {useSelector} from 'react-redux';
import {selector as globalSelector} from '../../redux-reducers/globals';
import SBRLegacy from '../sbr-legacy';
import SBRV2 from '../sbrv2';

const SBRAdapter = () => {
    const isSU = useSelector(globalSelector.isSU);

    return (
        <>
            {isSU ? <h4>Latest Reports</h4> : null}
            <SBRV2/>
            {isSU ? <h4>Legacy Reports</h4> : null}
            {isSU ? <SBRLegacy/> : null}
        </>
    )
}

export default SBRAdapter;