import React from 'react';
import {Row, Col} from '../bootstrap';
import { externalRoutes } from '../../config/routes';

const CompanySummary = ({company}) => {
    const {companyNumber, address, companyCategory, incorporationDate, hasSummary} = company.document;
    const link = externalRoutes.chOfficerSearch(companyNumber);
    const viewOfficers = <a href={link} target="_blank" rel="noopener noreferrer" className="">Companies House <i className="fas fa-external-link-alt pl-1"></i></a>;

    if (hasSummary)
    {
        return (
            <>
                <Row className="pb-2">
                    <Col size={2}>
                        <span>{companyNumber}</span>
                    </Col>
                    <Col size={4}>
                        <span>{companyCategory}</span>
                    </Col>
                    <Col size={3}>
                        <span>Incorporated: {incorporationDate}</span>
                    </Col>
                    <Col size={3} className="d-flex justify-content-end">
                        {viewOfficers}
                    </Col>
                </Row>
                <Row className="pb-3 text-capitalize">
                    {address}
                </Row>
            </>
        );
    }
    
    return (
        <>
            <Row className="pb-2">
                <Col size={2}>
                    <span>{companyNumber}</span>
                </Col>
                <Col size={3} className="d-flex justify-content-end">
                    {viewOfficers}
                </Col>
            </Row>
        </>
    );
};

export default CompanySummary;