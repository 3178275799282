import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useTable, useGlobalFilter, useFilters } from 'react-table';
import { DefaultColumnFilter, fuzzyTextFilterFn } from '../../bootstrap/table-filters';
import { Row } from '../../bootstrap';

import ActionButtons from './action-buttons';

const columns = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Address',
        accessor: 'address'
    },
    {
        Header: 'Telephone',
        accessor: 'telephone'
    },
    {
        id: 'actionButtons',
        Cell: ({row}) => <ActionButtons row={row} />,
        className: "hide-on-print"
    }
];

const ResultsTable = ({ data }) => {
    const dataMemo = React.useMemo(() => data, [data]);
    const columnsMemo = React.useMemo(() => columns, []);

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const tableInstance = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
        },
        useFilters,
        useGlobalFilter
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <Row className="container-fluid p-0 mt-2 mb-2 react-table-container">
            <BTable striped bordered hover bsPrefix='b4-table' size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className={`align-top ${column.className || ''}`}>
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div className="hide-on-print">{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} style={{ height: "32px" }}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} className={`row-cell ${cell.column.className || ''}`}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </BTable>
        </Row>
    );
}

export default ResultsTable;