import React, {useEffect, useState} from 'react';
import ClientForm from './client-form';
import axios from 'axios';
import {BootstrapSpinner} from '../../bootstrap';
import {useHistory} from 'react-router-dom';


const EditForm = ({match:{params:{id}}}) => {
    const [initialValues, setInitialValues] = useState(null);
    const [options, setOptions] = useState({
        councils: {},
        dataProviders: [],
        datasetOptions: []
    });
    const editApi = `/api/clients/${id}`;
    const history = useHistory();

    useEffect(() => {
        axios.get(editApi).then(res => {
            setInitialValues(res.data.values);
            setOptions(res.data.options);
        });
    }, [editApi])

    const onSubmit = async (values) => {
        let response = await axios.put(editApi, values);
        if(response.data.length === 0){
            history.goBack();
        }
        return response.data;
    };

    if(initialValues === null){
        return <BootstrapSpinner loadingMessage='Loading client settings...'/>
    }

    return <ClientForm initialValues={initialValues} options={options} onSubmit={onSubmit}/>;
};

export default EditForm;