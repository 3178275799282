import dangerModal from './danger-modal';
import genericModal from './generic-modal';
import successModal from './success-modal';

export const ModalTypes = {
    danger: 'danger',
    generic: 'generic',
    success: 'success'
}

const modalsMap = {};
modalsMap[ModalTypes.danger] = dangerModal;
modalsMap[ModalTypes.generic] = genericModal;
modalsMap[ModalTypes.success] = successModal;

export const ModalsMap = modalsMap;

export const ModalSizes = {
    large: 'b4-modal-lg',
    small: 'b4-modal-sm',
    default: ''
}