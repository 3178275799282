import * as Actions from '../redux-actions/council-tax-address-search-actions';

export const initialSearchedValues = {
    ...Actions.baseValues,
    initialSearch: 'CouncilTaxAddress',
    linkedSearch: '',
    isLinkedSearch: false,
    secondLinkedSearch: '',
    isLoctaPlusSearch: false,
    isQuickSearch: false
};

const initialState = {
    searchType: "CouncilTaxAddress",
    searchedValues: {...initialSearchedValues},
    stagingResults: [],
    results: [],
    stagingSearchDone: false,
    searchDone: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.COUNCIL_TAX_ADDRESS_SEARCH_STAGING_START:
            return {
                ...state,
                searchedValues: {...state.searchedValues, ...action.data},
                stagingSearchDone: false,
                stagingResults: [],
                searchDone: false,
                results: []
            };
        case Actions.COUNCIL_TAX_ADDRESS_SEARCH_STAGING_SUCCESS:
            return {
                ...state,
                stagingSearchDone: true,
                stagingResults: action.data
            };
        case Actions.COUNCIL_TAX_ADDRESS_SEARCH_STAGING_FAIL:
            return {
                ...state,
                stagingSearchDone: true,
                stagingResults: [],
                searchDone: false,
                results: []
            };
        case Actions.COUNCIL_TAX_ADDRESS_SEARCH_RESET:
            return {
                ...state,
                stagingSearchDone: false,
                stagingResults: [],
                searchDone: false,
                results: []
            };
        // ---
        case Actions.COUNCIL_TAX_ADDRESS_PERFORMSEARCH_START:
            return {
                ...state,
                searchedValues: action.data,
                searchDone: false,
                results: []
            };
        case Actions.COUNCIL_TAX_ADDRESS_PERFORMSEARCH_SUCCESS:
            return {
                ...state,
                searchDone: true,
                results: action.data
            };
        case Actions.COUNCIL_TAX_ADDRESS_PERFORMSEARCH_FAIL:
            return {
                ...state,
                searchDone: true,
                results: []
            };
        default:
            return state;
    }
}

export const selector = {
    searchType: (state) => state.councilTaxAddressSearch.searchType,
    searchedValues: (state) => state.councilTaxAddressSearch.searchedValues,
    stagingResults: (state) => state.councilTaxAddressSearch.stagingResults,
    results: (state) => state.councilTaxAddressSearch.results,
    logId: (state) => state.councilTaxAddressSearch.stagingResults.LogId,
    hasLinkedSearch: (state) => (state.councilTaxAddressSearch.searchedValues.secondLinkedSearch === '' && !state.councilTaxAddressSearch.searchedValues.isQuickSearch),
    stagingSearchDone: (state) => state.councilTaxAddressSearch.stagingSearchDone,
    searchDone: (state) => state.councilTaxAddressSearch.searchDone
};
