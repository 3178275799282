import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, PrimaryButton, Col, ReactWidgetsDatePicker as DatePicker } from '../bootstrap';

export const formName = 'diagnostics-react-testing-grounds-1';

const required = value => value ? undefined : 'Required';

const renderInput = (label, renderFn, hasRequired) => (field) => {
  return (
    <div className="input-row w-100">
      <label>
        {label} {hasRequired ? <span className="text-danger">*</span> : null}
      </label>
      {renderFn(label)(field)}
      {field.meta.touched && field.meta.error &&
        <div className="text-danger mt-1">{field.meta.error}</div>}
    </div>
  );
}

// const renderTextField = (label) => (field) => (
//   <input {...field.input} type="text" className="b4-form-control" key="query-input" />
// );

// const renderMultiSelectList = (label) => ({ input, data }) =>
//   <SelectList {...input}
//     onBlur={() => input.onBlur()}
//     data={data}
//     multiple={true} />

// const renderSelectList = (label) => ({ input, data }) =>
//   <SelectList {...input}
//     onBlur={() => input.onBlur()}
//     data={data} />

const renderDateTimePicker = (label) => (field) => {
  const { input: { onChange, value } } = field;
  return (
    <div className={`rw-dateinput-container`}>
      <DatePicker
        onChange={onChange}
        placeholder="DD/MM/YYYY"
        time={false}
        value={!value ? null : new Date(value)}
      />
    </div>
  );
}

const SubmitMessage = () => 
  <Row className="justify-content-center mt-4">
    <Col size={3}>
      <div>Submitting form...</div>
    </Col>
  </Row>
;

const Form = ({ handleSubmit, loading }) => {
  const messageRow = loading ? <SubmitMessage /> : null;

  return (
    <form onSubmit={handleSubmit} className="pl-4 pr-4">
      <Row className="pt-4">
        <h3>
          Testing Grounds Form One
        </h3>
      </Row>
      <hr />
      <Row className="pb-3">
        <Col size={6}>
          <Row className="pb-3">
            <Field name="datePicker" component={renderInput("Date Picker Test", renderDateTimePicker, true)} validate={[required]} />
          </Row>
        </Col>
      </Row>
      <PrimaryButton type="submit" disabled={loading} className="mt-4 submit-button">SUBMIT</PrimaryButton>
      {messageRow}
    </form>
  );
}

export default reduxForm({form: formName})(Form);