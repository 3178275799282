import React from 'react';

const ErrorMessage = ({text}) => (
    <div style={{color: "#f00"}}>{text}</div>
)

export const renderInput = ({input, type, meta: { touched, error }}) => (
    <>
        <input
            {...input}
            type={type}
            className={
                "input-block-level" +
                (touched && error ? " invalid-search-input" : "")
            }
        />
        {touched && error ? <ErrorMessage text={error} /> : null}
    </>
);
