import React from 'react';
import ReactTable from 'react-table-v6';
import ManageUser from './manageUser';
import {stringAcc, cellAcc, dateAcc, fuzzyFilter} from '../../table';

// AccessId: "d93aa2f5-60c4-4b2c-97a9-9459d8e07485"
// ClientId: 0
// ClientName: "LoCTA Limited"
// CouncilName: null
// DateDeleted: null
// Departments: null
// Email: "dragos.rosu@locta.co.uk"
// FailedLoginAttempts: 0
// ForcePasswordReset: false
// Fullname: "DR Rosu1"
// IsAdministrator: true
// IsDisabled: false
// LastActivity: "/Date(1540549501527)/"
// LastURL: "http://locta.local:80/Search/Dashboard"
// MultipageDWP: false
// Password: null
// Roles: null
// UserId: 0
// Username: "drr"

const isDeleted = d => {
    const {dateDeleted} = d;
    let result;
    if(dateDeleted){
        result = true;
    }
    else {
        result = false;
    }

    return String(result);
}

const columns = [
    stringAcc('Client Name', 'clientName'),
    stringAcc('Username', 'username'),
    stringAcc('Fullname', 'fullname'),
    stringAcc('Email', 'email'),
    stringAcc('Is Administrator', d => String(d.isAdministrator), 'admin'),
    stringAcc('Deleted', d => isDeleted(d), 'deleted'),
    dateAcc('Date Deleted', d => d.dateDeleted, 'dateDeleted'),
    stringAcc('Disabled', d => String(d.isDisabled), 'disabled'),
    cellAcc("Manage", d => d, props => <ManageUser {...props}/>, 'manage')
]

const Table = ({users}) => {
    return (
        <div className="ReactTable">
            <ReactTable
                data={users}
                columns={columns}
                defaultPageSize={10}
                filterable={true}
                defaultFilterMethod={fuzzyFilter}
            />
        </div>
    );
};

export default Table;