import React, { useState } from 'react';

export default ({ url, height, width }) => {

    const [show, setShow] = useState(false);

    if (!show) {
        setTimeout(() => setShow(true), 200);
    }

    let style = { 
        height: height + 'px', 
        width: width + 'px', 
        padding: '5px',
        overflow: 'hidden'
    };

    return (
        <>
            <div className={ `react-modal-anchor ${(show ? ' show' : '')}`}>
                <div className={ `react-modal ${show ? 'show' : ''}` } style={ style }>
                    <iframe title="modal" src={url} width={width + 'px'} height={height + 'px'}></iframe>
                </div>
            </div>
            <div className={ `react-modal-backdrop ${show ? 'show' : ''}` }></div>
        </>
    );
};
