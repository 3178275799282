import React from 'react';
import { Row, Col } from '../../bootstrap';
import { Field } from '../field';

const Occupant = ({ data: { reference, fullName, claimStartDate, claimEndDate } }) => (
    <Row className="pb-3">
        <Col size={3}>
            <Field label="Additional Occupant" value={fullName} />
        </Col>
        <Col size={3}>
            <Field label="Reference" value={reference} />
        </Col>
        <Col size={3}>
            <Field label="Date In" value={Date.loctaFormat8(claimStartDate)} />
        </Col>
        <Col size={3}>
            <Field label="Date Out" value={Date.loctaFormat8(claimEndDate)} />
        </Col>
    </Row>
);

export default function Occupants({ data: {occupants, resultId} }) {
    if (typeof (occupants) === 'undefined' || occupants.length === 0)
        return null;
    const rows = occupants.map((x, i) => <Occupant key={`${resultId}-${x.reference}-${i}`} data={x} />);
    return rows;
}
