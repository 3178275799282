import React from 'react';
import { Field, Form } from 'react-final-form';

import { LoctaButton, SecondaryButton } from '../../bootstrap';
import { renderSelect, renderInput } from './inputs';

const MyForm = ({handleSubmit, values, onClose, profiles}) => {
    
    const selectedProfile = profiles.find(p => p.id === values.selectedProfileId) || {};

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
        
            <div>
                <span className="w-100 p-15 option-label">Select a Profile to add your result to -</span>
                <div className="w-100 p-15">
                    <Field name="selectedProfileId" render={renderSelect} tabIndex={1}>
                        <option value="">-- Please Select --</option>
                        { profiles.map(p => <option value={p.id} key={p.id}>{p.name}</option>) }
                    </Field>
                </div>
            </div>

            <div className="w-100 p-15 center-text color-light-gray">- OR -</div>

            <div>
                <span className="w-100 p-15 option-label">Type the Name of your new Profile -</span>
                <div className="w-100 p-15">
                    <Field name="newProfileName" type="text" render={renderInput} tabIndex={2} />
                </div>
            </div>

            <hr className="light-gray" />

            <div className="w-100 p-15">
                <h6>Profile Details</h6>
                <div className="dl">
                    <div className="dt">Created On -</div>
                    <div className="dd">{selectedProfile.dateCreatedFormatted || 'N/A'}</div>
                    <div className="dt new-line">Searches Added - </div>
                    <div className="dd">{selectedProfile.resultsIncluded || 'N/A'}</div>
                </div>
            </div>
            
            <hr className="light-gray" />

            <div className="button-row p-15">
                <LoctaButton icon="fas fa-times-circle" ButtonFn={SecondaryButton} onClick={onClose} textComponent="cancel" className="mb-3" />
                <LoctaButton icon="fas fa-user-plus" type="submit" textComponent={(<>add to<br />profile</>)} className="mb-3" />
            </div>
        </form>
    );
}

const AddToProfileForm = ({ onSubmit, onClose, initialValues, profiles }) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            onClose={onClose}
            profiles={profiles}
            render={MyForm}
        />
    );
}

export default AddToProfileForm;
