import DomConfigRepository from '../../../repositories/domConfigRepository';
const sv = DomConfigRepository.getJsonConfigById('searchValues');

export const initialValues = {
    forename: sv.forename,
    surname: sv.surname,
    dateOfBirth: Date.parseForReact(sv.dateOfBirth),
    location: sv.location || '',

    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};

export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;