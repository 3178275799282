import React from 'react';
import {BootstrapContainer, Row, LoctaButton, SecondaryButton, PrimaryButton, Col, LinkButton} from '../../bootstrap';
import { isAutoSearch } from './server-values';

const containerStyles = {border:0, borderBottom:"1px solid rgba(0, 0, 0, .1)"};
const buttonStyles = {fontSize:"14px"};

const InStep1 = ({ goToStep, onReset, stagingSearchDone, searchDone, searchForm }) => {
    const step2Disabled = !searchDone;
    const step3Disabled = !stagingSearchDone;

    const onContinue = () => {
        searchForm.current.submit(new Event('submit', { cancelable: true }))
    }

    return (
        <div className="block-header block-grey rb" style={containerStyles}>
            <Row style={buttonStyles}>
                <Col size={8} className="d-flex align-items-center">
                    <LinkButton onClick={() => goToStep(1)} outline={true}>Initial Search</LinkButton>
                    <p>{">"}</p>
                    <LinkButton onClick={() => goToStep(2)} disabled={step2Disabled}>Filter Results</LinkButton>
                    <p>{">"}</p>
                    <LinkButton onClick={() => goToStep(3)} disabled={step3Disabled}>Results</LinkButton>
                </Col>
                <Col size={4} className="d-flex justify-content-end">
                    <div style={{width:"100px", height:"28px"}}>
                        <LoctaButton small={true} className="h-100" icon="fas fa-sync" disabled={isAutoSearch} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset}/>
                    </div>
                    <div style={{width:"100px", height:"28px"}} className="ml-4">
                        <LoctaButton small={true} className="h-100" icon="fas fa-play-circle" disabled={isAutoSearch} ButtonFn={PrimaryButton} textComponent={<>Search</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onContinue}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const InStep2 = ({ stagingSearchDone, goToStep, onReset, onStagingSearch, selectedRows }) => {
    const step3Disabled = !stagingSearchDone;

    return (
        <>
            <div className="block-header block-grey rb" style={containerStyles}>
                <Row style={buttonStyles}>
                    <Col size={8} className="d-flex align-items-center">
                        <LinkButton onClick={() => goToStep(1)}>Initial Search</LinkButton>
                        <p>{">"}</p>
                        <LinkButton onClick={() => goToStep(2)} outline={true}>Filter Results</LinkButton>
                        <p>{">"}</p>
                        <LinkButton onClick={() => goToStep(3)} disabled={step3Disabled}>Results</LinkButton>
                    </Col>
                    <Col size={4} className="d-flex justify-content-end">
                        <div style={{width:"100px", height:"28px"}}>
                            <LoctaButton small={true} className="h-100" icon="fas fa-sync" disabled={isAutoSearch} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset}/>
                        </div>
                        <div style={{width:"100px", height:"28px"}} className="ml-4">
                            <LoctaButton small={true} className="h-100" icon="fas fa-play-circle" disabled={selectedRows.length <= 0} ButtonFn={PrimaryButton} textComponent={<>Continue</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onStagingSearch}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const InStep3 = ({ goToStep, onReset }) => {
    return (
        <>
            <div className="block-header block-grey rb" style={containerStyles}>
                <Row style={buttonStyles}>
                    <Col size={8} className="d-flex align-items-center">
                        <LinkButton onClick={() => goToStep(1)}>Initial Search</LinkButton>
                        <p>{">"}</p>
                        <LinkButton onClick={() => goToStep(2)}>Filter Results</LinkButton>
                        <p>{">"}</p>
                        <LinkButton onClick={() => goToStep(3)} outline={true}>Results</LinkButton>
                    </Col>
                    <Col size={4} className="d-flex justify-content-end">
                        <div style={{width:"100px", height:"28px"}}>
                            <LoctaButton small={true} className="h-100" icon="fas fa-sync" disabled={isAutoSearch} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset}/>
                        </div>
                        <div style={{width:"100px", height:"28px"}} className="ml-4">
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const ControlCard = (props) => {

    const { currentStep } = props;
    let content;
    switch (currentStep) {
        case 1: content = <InStep1 {...props}/>; break;
        case 2: content = <InStep2 {...props} />; break;
        case 3: content = <InStep3 {...props} />; break;
        default: content = null;
    }

    return (
        <BootstrapContainer>
            {content}
        </BootstrapContainer>
    )
}

export default ControlCard;