import React from 'react';
import ReactTable from 'react-table-v6';
import {stringAcc, cellAcc, fuzzyFilter} from '../table';
import {ReactTableContainer} from '../bootstrap'


const Download = (props) => {
    const {id, isTrainingLink} = props.value;
    return (
        <div>
            <a href={ isTrainingLink ? 'https://cdn.loctahub.co.uk/training/NinoMatchReport Training.xls' : `/api/nino-match/download/${id}`} target="_blank" rel="noopener noreferrer">Download</a>
        </div>
    );
};

const columnsSuperUser = [
    stringAcc('Council Name', 'councilName', 'councilName', 'text-left'),
    stringAcc('Report Date', 'reportDate', 'reportDate', 'text-left'),
    stringAcc('New Matches', 'newMatchCount', 'newMatchCount', 'text-left'),
    stringAcc('Download Count', 'downloadCount', 'downloadCount', 'text-left'),
    cellAcc("Download", d => d, props => <Download {...props}/>, 'download', 'text-left')
];

const columnsDefault = [
    stringAcc('Report Date', 'reportDate', 'reportDate', 'text-left'),
    stringAcc('New Matches', 'newMatchCount', 'newMatchCount', 'text-left'),
    cellAcc("Download", d => d, props => <Download {...props}/>, 'download', 'text-left')
];

const sortSu = [{
    id: "reportDate",
    desc: true
},{
    id: "councilName",
    desc: false
}];

const sortUser = [{
    id: "reportDate",
    desc: true
}];

const Table = ({data, isSuperUser}) => {

    if(typeof(data) === 'undefined' || data === null || data.length === 0){
        return <div>Loading...</div>
    }

    return (
        <ReactTableContainer>
            <ReactTable
                data={data}
                columns={isSuperUser ? columnsSuperUser : columnsDefault}
                defaultPageSize={10}
                defaultSorted={isSuperUser ? sortSu : sortUser}
                filterable={true}
                defaultFilterMethod={fuzzyFilter}
            />
        </ReactTableContainer>
    );
};

export default Table;