import React, { Component } from 'react';
import {connect} from 'react-redux';
import {startLoadingResults} from '../../redux-actions/dissolved-companies-actions';
import {selector} from '../../redux-reducers/dissolved-companies-reducer';
import ReactTable from 'react-table-v6';
import {selector as globalSelector} from '../../redux-reducers/globals';
import {stringAcc, dateAcc8, fuzzyFilter} from '../table';
import {Loading} from '../shared'

const columnsDefault = [
    stringAcc('Company Number', 'companyNumber', 'companyNumber', 'text-left'),
    stringAcc('Company Name', 'name', 'companyName', 'text-left'),
    stringAcc('Company Status', 'companyStatus', 'companyStatus', 'text-left'),
    stringAcc('Postcode', 'postcode', 'postcode', 'text-left'),
    dateAcc8('Date Of Creation', d => d.dateOfCreationFormatted, 'dateOfCreation', 'text-left'),
    dateAcc8('Date Of Cessation', d => d.dateOfCessationFormatted, 'dateOfCessation', 'text-left')
];

const columnsSU = [
    stringAcc('Council ID', 'councilID', 'councilId', 'text-left'),
    stringAcc('CouncilName', 'councilName', 'councilName', 'text-left'),
    stringAcc('Company Number', 'companyNumber', 'companyNumber', 'text-left'),
    stringAcc('Company Name', 'name', 'companyName', 'text-left'),
    stringAcc('Company Status', 'companyStatus', 'companyStatus', 'text-left'),
    stringAcc('Postcode', 'postcode', 'postcode', 'text-left'),
    dateAcc8('Date Of Creation', d => d.dateOfCreationFormatted, 'dateOfCreation', 'text-left'),
    dateAcc8('Date Of Cessation', d => d.dateOfCessationFormatted, 'dateOfCessation', 'text-left')
];

const defaultSort = [{
    id   : 'companyNumber',
    desc : false,
}];

const suSort = [{
    id   : 'councilId',
    desc : false,
}];

const ResultsTable = ({data, isSU}) => {
    let comp = data === null ? "" :
        (
            <div className="w-100 d-flex justify-content-center">
                <ReactTable
                    data={data}
                    columns={isSU ? columnsSU : columnsDefault}
                    showPagination={true}
                    defaultPageSize={10}
                    className="-striped w-100"
                    filterable={true}
                    defaultFilterMethod={fuzzyFilter}
                    defaultSortDesc={true}
                    defaultSorted={isSU ? suSort : defaultSort}
                /> 
            </div>

        )
    return comp;
};


class ResultsView extends Component {

    constructor(props){
        super(props);

        this.props.startLoadingResults(this.props.data.id);
    }

    render() {
        const result = this.props.results[this.props.data.id];
        const loading = result.loading;
        const data = result.data;

        const comp = loading ? <Loading className="w-100 d-flex h-25" message="Loading results..."/> : <ResultsTable isSU={this.props.isSU} data={data}/>;
        return (
            <>
                {comp}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    results: selector.getResults(state),
    isSU: globalSelector.isSU(state),
});

const mapDispatchToProps = (dispatch) => ({
    startLoadingResults: (id) => dispatch(startLoadingResults(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);