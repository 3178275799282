import sbrReducer from './sbr-reducer';
import loctaPlusReducer from './locta-plus-reducer';
import loctaPlusV2Reducer from './locta-plus-v2-reducer';
import globalsReducer from  './globals';
import { reducer as formReducer } from 'redux-form';
import {combineReducers} from 'redux';
import companiesHouseReducer from './companies-house-reducer';
import dissolvedCompaniesReducer from './dissolved-companies-reducer';
import benefitsSearchReducer from './benefits-search-reducer';
import councilTaxAddressSearchReducer from './council-tax-address-search-reducer';
import councilTaxNameSearchReducer from './council-tax-name-search-reducer';
import businessRatesSearchReducer from './business-rates-search-reducer';
import telephoneDirectorySearch from './telephone-directory-search-reducer';
import votersRollSearch from './voters-roll-search-reducer';
import deceasedSearch from './deceased-search-reducer';
import quickSearchReducer from './quick-search-reducer';
import quickSearchAlertsReducer from './quick-search-alerts-reducer';
import featureSwitchReducer from './feature-switches-reducer';
import diagnosticsReducer from './diagnostics-reducer';
import modalReducer from './modal-reducer';
import {reducer as toastrReducer} from 'react-redux-toastr'
import ninoMatchReducer from './nino-match-reducer';

const rootReducer = combineReducers(
        {
            sbr: sbrReducer, 
            ninoMatch: ninoMatchReducer,
            globals: globalsReducer,
            companieshouse: companiesHouseReducer,
            dissolvedCompanies: dissolvedCompaniesReducer,
            loctaPlus: loctaPlusReducer,
            loctaPlusV2: loctaPlusV2Reducer,
            benefitsSearch: benefitsSearchReducer,
            councilTaxAddressSearch: councilTaxAddressSearchReducer,
            councilTaxNameSearch: councilTaxNameSearchReducer,
            businessRatesSearch: businessRatesSearchReducer,
            telephoneDirectorySearch: telephoneDirectorySearch,
            votersRollSearch: votersRollSearch,
            deceasedSearch: deceasedSearch,
            diagnostics: diagnosticsReducer,
            quickSearch: quickSearchReducer,
            quickSearchAlerts: quickSearchAlertsReducer,
            featureSwitches: featureSwitchReducer,
            toastr: toastrReducer,
            modals: modalReducer,
            form: formReducer
        }
    );

export default rootReducer;
