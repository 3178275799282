import React from 'react';
import {SearchNames} from '../../redux-reducers/globals';

const benefitsForm = (result) => (
    <form method="POST" action="/Search/Benefits" target="_blank">
        <input type="hidden" name="Surname" value={ result.query.surname } />
        <input type="hidden" name="Nino" value={ result.query.nino } />
        <input type="hidden" name="DateOfBirth" value={ result.query.dateOfBirth } />
        <input type="hidden" name="OpenCasesOnly" value={ result.query.openCasesOnly ? "true" : "false" } />

        <input type="hidden" name="InitialSearch" value={ result.query.searchType } />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />

        <input type="hidden" name="IsLoctaPlusSearch" value="true" />

        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);

const councilTaxAddress = (result) => (
    <form method="POST" action="/Search/CouncilTaxAddress" target="_blank">
        <input type="hidden" name="HouseNameNumber" value={ result.query.houseNameNumber } />
        <input type="hidden" name="Street" value={ result.query.street } />
        <input type="hidden" name="Postcode" value={ result.query.postcode } />
        <input type="hidden" name="IsLive" value={ result.query.isLive ? "true" : "false" } />
        <input type="hidden" name="LogId" value={ result.query.logId } />

        <input type="hidden" name="InitialSearch" value={ result.query.searchType } />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />

        <input type="hidden" name="IsLoctaPlusSearch" value="true" />

        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);

const councilTaxName = (result, isExact) => (
    <form method="POST" action="/Search/CouncilTaxName" target="_blank">
        <input type="hidden" name="Forename" value={ result.query.forename } />
        <input type="hidden" name="BlankForename" value={ result.query.blankForename } />
        <input type="hidden" name="Surname" value={ result.query.surname } />
        <input type="hidden" name="SelectedCouncilId" value={ result.query.selectedCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ result.query.maxSearchDistance } />
        <input type="hidden" name="SearchStartDate" value={ result.query.searchStartDate } />
        <input type="hidden" name="SearchEndDate" value={ result.query.searchEndDate } />
        <input type="hidden" name="IsLive" value={ result.query.isLive ? "true" : "false" } />
        <input type="hidden" name="IsExact" value={ isExact } />

        <input type="hidden" name="InitialSearch" value={ result.query.searchType } />
        <input type="hidden" name="LinkedSearch" value="" />
        <input type="hidden" name="SecondLinkedSearch" value="" />

        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        
        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);

const deceased = (result) => (
    <form method="POST" action="/Search/Deceased" target="_blank">
        <input type="hidden" name="Forename" value={ result.query.forename } />
        <input type="hidden" name="Surname" value={ result.query.surname } />
        <input type="hidden" name="DateOfBirth" value={ result.query.dateOfBirth } />
        <input type="hidden" name="Location" value={ result.query.location } />
        <input type="hidden" name="Postcode" value={ result.query.postcode } />
        
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        
        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);



const directory = (result) => (
    <form method="POST" action="/Search/Directory" target="_blank">
        <input type="hidden" name="Surname" value={ result.query.surname } />
        <input type="hidden" name="Location" value={ result.query.location } />

        <input type="hidden" name="IsLoctaPlusSearch" value="true" />
        
        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);

const votersRollForm = (result, isExact) => (
    <form method="POST" action="/Search/VotersRoll" target="_blank">
        <input type="hidden" name="Forename" value={ result.query.forename } />
        <input type="hidden" name="Surname" value={ result.query.surname } />
        <input type="hidden" name="SelectedCouncilId" value={ result.query.selectedCouncilId } />
        <input type="hidden" name="MaxSearchDistance" value={ result.query.maxSearchDistance } />
        <input type="hidden" name="LogId" value={ result.query.logId } />
        <input type="hidden" name="IsExact" value={ isExact } />
        
        <input type="hidden" name="IsLoctaPlusSearch" value="true" />

        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);

const votersRollByAddressForm = (result) => (
    <form method="POST" action="/Search/VotersRollByAddress" target="_blank">
        <input type="hidden" name="HouseNameNumber" value={ result.query.houseNameNumber } />
        <input type="hidden" name="Street" value={ result.query.street } />
        <input type="hidden" name="Postcode" value={ result.query.postcode } />
        <input type="hidden" name="LogId" value={ result.query.logId } />

        <input type="hidden" name="IsLoctaPlusSearch" value="true" />

        <button type="submit" className={ 'btn btn-success align-right' }><i className="icon-play"></i>View</button>
    </form>
);

export default ({ result, isExact }) => {

    switch(result.name)
    {
        case SearchNames.Benefits:
            return benefitsForm(result);

        case SearchNames.BusinessRates:
            return businessRates(result);

        case SearchNames.Deceased:
            return deceased(result);

        case SearchNames.CouncilTaxAddress:
            return councilTaxAddress(result);

        case SearchNames.CouncilTaxName:
            return councilTaxName(result, isExact);

        case SearchNames.Directory:
            return directory(result);

        case SearchNames.VotersRoll:
            return votersRollForm(result, isExact);

        case SearchNames.VotersRollByAddress:
            return votersRollByAddressForm(result);

        default:
            return null;
    }
};
