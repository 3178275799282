import React from 'react';
import './loaders.css';

export const Spinner = ({ className = '', ...rest }) => {
    return (
        <div className={`loader-1 ${className}`} {...rest} />
    );
};

export const LoadingRow = ({ ...rest }) => {
    return (
        <div className="d-flex justify-content-center">
            <Spinner {...rest} />
        </div>
    );
};

export const BootstrapSpinner = ({loadingMessage="Loading...", height="400px", className=""}) => {
    return (
        <div style={{height:height}} className={`d-flex justify-content-center align-items-center flex-column ${className}`}>
            <div className="b4-spinner-border" role="status">
                <span className="b4-sr-only"/>
            </div>
            <span className='pt-2'>{loadingMessage}</span>
        </div>
    );
}