import React from 'react';
import SBR from '../sbr';
import { HashRouter as Router, Route } from "react-router-dom";
import {routes} from '../../config/routes';
import {BootstrapContainer} from '../bootstrap';


const UserDashboardRouter = () => {
    return (
        <BootstrapContainer className="container-fluid no-padding">
            <div className="pt-4">
                <Router basename="/UserDashboard/Home" hashType="noslash">
                    <Route path={routes.sbr} component={SBR} />
                </Router>
            </div>
        </BootstrapContainer>
    );
};

export default UserDashboardRouter;
