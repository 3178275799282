import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../redux-actions/quick-search-alerts-actions';
import { selector } from '../../../../redux-reducers/quick-search-alerts-reducer';

import AlertSummary from './alert-summary';
import Form from './form';

const AddAlert = ({ query, submitAlert, alertSummary }) => alertSummary
    ? <AlertSummary query={query} alertSummary={alertSummary} />
    : <Form query={query} onSubmit={submitAlert} />;

const mapStateToProps = (state) => ({
    alertSummary: selector.alertSummary(state)
})

const mapDispatchToProps = (dispatch) => ({
    submitAlert: (formValues) => {
        return dispatch(actions.submitAlert(formValues));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAlert);