import * as Actions from '../redux-actions/companies-house-actions';
import _ from "underscore";

function collectionUnion() {
    var args = Array.prototype.slice.call(arguments);
    var it = args.pop();

    return _.uniq(_.flatten(args, true), it);
}

const initialState = {
    companiesData: null,
    companiesForPSCData: null,
    pscData: null,
    officersData: null,
    companiesDataByCompanyNumber: [],
    companiesForPSCDataByCompanyNumber: [],
    pscDataByCompanyNumber: [],
    officerDataByCompanyNumber: [],
    companiesByCompanyNumber: [],
    companyHighestScore: 0,
    pscHighestSCore: 0,
    loading: false,
    additional: {
        loading: false,
        pscDataByCompanyNumber: [],
        officerDataByCompanyNumber: []
    }
}

const reduceByCompanyNumber = (data) => {
    return data.reduce((map, obj) => {
        map[obj.document.companyNumber] = obj;
        return map;
    }, {});
    
}

const reduceToArrayByCompanyNumber = (data) => {
    let result = data.reduce((map, obj) => {
        if(typeof(map[obj.document.companyNumber]) === 'undefined'){
            map[obj.document.companyNumber] = [obj];
        } else {
            map[obj.document.companyNumber].push(obj);
        }
        return map;
    }, {});
    
    return result;
}

const computeCompanyScores = (companies, psc, officers) => {
    const companyNumbers = Object.keys(companies);
    const result = companyNumbers.reduce((map, companyNumber) => {
        let scores = [companies[companyNumber].score];

        if (psc.hasOwnProperty(companyNumber)) {
            let pscForCompany = psc[companyNumber];
            if(pscForCompany instanceof Array && pscForCompany.length > 0) {
                let pscScores = pscForCompany.map(psc => psc.score);
                scores = [...scores, ...pscScores ];
            }
        }

        if (officers.hasOwnProperty(companyNumber)) {
            let officerForCompany = officers[companyNumber];
            if(officerForCompany instanceof Array && officerForCompany.length > 0) {
                let officerScores = officerForCompany.map(psc => psc.score);
                scores = [...scores, ...officerScores ];
            }
        }
        
        map[companyNumber] = Math.max(...scores);

        return map;
    }, {});

    return result;
}

const queryAllEnd = (state, action) => {
    let newState = {...state, ...action.data, loading: false};
    newState.companiesDataByCompanyNumber = reduceByCompanyNumber(action.data.companiesData.data);
    newState.companiesForPSCDataByCompanyNumber = reduceByCompanyNumber(action.data.companiesForPSCData.data);
    newState.pscDataByCompanyNumber = reduceToArrayByCompanyNumber(action.data.pscData.data);
    newState.officerDataByCompanyNumber = reduceToArrayByCompanyNumber(action.data.officersData.data);
    newState.companiesByCompanyNumber = {...newState.companiesDataByCompanyNumber, ...newState.companiesForPSCDataByCompanyNumber};
    newState.companyScoresAgg = computeCompanyScores(newState.companiesByCompanyNumber, newState.pscDataByCompanyNumber, newState.officerDataByCompanyNumber);
    
    const companyHighestScore = Math.max(...Object.values(newState.companyScoresAgg)) || 0;
    const pscHighestScore = newState.pscData.highestScore || 0;
    const officerHighestScore = newState.officersData.highestScore || 0;
    newState.highestScore = Math.max(companyHighestScore, pscHighestScore, officerHighestScore);

    return newState;
}

const queryCompaniesStart = (state, action) => {
    let newState = { ...state };

    newState.additional.loading = true;

    return newState;
}

const mergeRecords = (existing, additional) => {
    let allData = reduceToArrayByCompanyNumber(additional);
    let companyNumbers = Object.keys(allData);
    let allDataByCompanyNumbers = companyNumbers.reduce((map, companyNumber) => {
        let pscs = existing.hasOwnProperty(companyNumber) ? existing[companyNumber] : [];
        let additional = allData[companyNumber];
        let result = collectionUnion(pscs, additional, function (item) {
            return JSON.stringify(item.document);
        });
        map[companyNumber] = result;
        return map;
    }, {});

    return allDataByCompanyNumbers;
}

const queryCompaniesEnd = (state, action) => {
    let newState = {...state};
    
    newState.additional.pscDataByCompanyNumber = mergeRecords(newState.pscDataByCompanyNumber, action.data.pscData.data);
    newState.additional.officerDataByCompanyNumber = mergeRecords(newState.officerDataByCompanyNumber, action.data.officersData.data);
    newState.additional.loading = false;

    return newState;
}

export default function reducer(state = initialState, action = { type: 'NOT_SET' }) {
    switch (action.type) {
        case Actions.QUERY_ALL_START:
            return {...state, query: action.data, loading: true}
        case Actions.QUERY_ALL_END:
            return queryAllEnd(state, action);
        case Actions.QUERY_COMPANIES_START:
            return queryCompaniesStart(state, action);
        case Actions.QUERY_COMPANIES_END:
            return queryCompaniesEnd(state, action);
        default:
            return state;
    }
}

export const selector = {
    data: (state) => Object.values(state.companieshouse.companiesDataByCompanyNumber),
    companies: (state) => Object.values(state.companieshouse.companiesByCompanyNumber),
    pscs: (state) => state.companieshouse.pscDataByCompanyNumber,
    additionalPscs: (state) => state.companieshouse.additional.pscDataByCompanyNumber,
    officers: (state) => state.companieshouse.officerDataByCompanyNumber,
    additionalOfficers: (state) => state.companieshouse.additional.officerDataByCompanyNumber,
    loading: (state) => state.companieshouse.loading,
    additionalLoading: (state) => state.companieshouse.additional.loading,
    companyScores: (state) => state.companieshouse.companyScoresAgg,
    highestScore: (state) => state.companieshouse.highestScore,
    query: (state) => state.companieshouse.query,

    bycompany: {
        pscs: (state) => (companyNumber) => {
            let data = selector.pscs(state);
            if (data.hasOwnProperty(companyNumber)) {
                return data[companyNumber];
            }
            return [];
        },
        additionalPscs: (state) => (companyNumber) => {
            let data = selector.additionalPscs(state);
            if (data.hasOwnProperty(companyNumber)) {
                return data[companyNumber];
            }
            return [];
        },
        officers: (state) => (companyNumber) => {
            let data = selector.officers(state);
            if (data.hasOwnProperty(companyNumber)) {
                return data[companyNumber];
            }
            return [];
        },
        additionalOfficers: (state) => (companyNumber) => {
            let data = selector.additionalOfficers(state);
            if (data.hasOwnProperty(companyNumber)) {
                return data[companyNumber];
            }
            return [];
        },
        additionalPscsExist: (state) => (companyNumber) => {
            let orig = selector.bycompany.pscs(state)(companyNumber);
            let add = selector.bycompany.additionalPscs(state)(companyNumber);
            return add.length > orig.length;
        },
        additionalOfficersExist: (state) => (companyNumber) => {
            let orig = selector.bycompany.officers(state)(companyNumber);
            let add = selector.bycompany.additionalOfficers(state)(companyNumber);
            return add.length > orig.length;
        },
        additionalRecordsExist: (state) => (companyNumber) => selector.bycompany.additionalPscsExist(state)(companyNumber) || selector.bycompany.additionalOfficersExist(state)(companyNumber)
    }
};