import * as quickSearch from '../redux-actions/quick-search-alerts-actions';

const initialState = {
    initialFormValues: null,
    alertSummary: null,
    error: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case quickSearch.SET_ALERT_SUMMARY:
            return { ...state, alertSummary: action.data, error: false };
        case quickSearch.REMOVE_ALERT_SUMMARY:
            return { ...state, alertSummary: null, error: false };
        case quickSearch.ADD_ALERT_FAIL:
            return { ...state, error: true };
        case quickSearch.SET_ALERT_FORM_INITIAL_VALUES:
            return { ...state, initialFormValues: action.data };
        default:
            return state;
    }
};

export const selector = {
    alertSummary: (state) => state.quickSearchAlerts.alertSummary,
    initialFormValues: (state) => state.quickSearchAlerts.initialFormValues,
}