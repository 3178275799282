import React from 'react';

export default ({ isVisible, header, content, footer, height, width, onClose }) => {

    height = height + 49 + 59 + 30;  // header + footer + body padding

    let style = { 
        width: width + 'px', 
    };

    return (
        <div>
            <div className={ `react-modal-anchor ${(isVisible ? ' show' : '')}`}>
                <div className={ 'react-modal' + (isVisible ? ' show' : '') } style={ style }>
                    <div className="react-modal-header">
                        <button type="button" className="close" onClick={ onClose }>&times;</button>
                        { header }
                    </div>
                    <div className="react-modal-body">
                        { content }
                        <div className="clearfix"></div>
                    </div>
                    <div className="react-modal-footer">
                        <div className="content">
                            { footer }
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ 'react-modal-backdrop' + (isVisible ? ' show' : '') }></div>
        </div>
    );
};

