import usersReducer from './users-reducer';
import councilsReducer from './councils-reducer';

import ninoMatchReducer from './nino-match-reducer';
import sbrReducer from './sbr-reducer';
import globalsReducer from  './globals';

import modalReducer from './modal-reducer';
import { reducer as formReducer } from 'redux-form';
import {combineReducers} from 'redux';

import dissolvedCompaniesReducer from './dissolved-companies-reducer';

const rootReducer = combineReducers(
        {
            users: usersReducer, 
            councils: councilsReducer,
            ninoMatch: ninoMatchReducer,
            sbr: sbrReducer, 
            globals: globalsReducer,
            dissolvedCompanies: dissolvedCompaniesReducer,
            modal: modalReducer, 
            form: formReducer
        }
    );

export default rootReducer;
