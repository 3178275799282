import React from 'react';
import {Row, Col} from './index';

export const Button = ({className = '', onClick = () => {}, small, large, children, type = 'button', disabled = false, ...rest}) => (
    <button type={type} className={`b4-btn ${small ? 'b4-btn-sm' : ''} ${large ? 'b4-btn-lg': ''} ${className}`} onClick={onClick} disabled={disabled} {...rest}>{children}</button>
);

export const PrimaryButton = ({outline = false, ...rest}) => (
    <Button {...rest} className={`${outline ? 'b4-btn-outline-primary' : 'b4-btn-primary'} ${rest.className ? rest.className : ''}`}/>
);

export const DangerButton = ({outline = false, ...rest}) => (
    <Button {...rest} className={`${outline ? 'b4-btn-outline-danger' : 'b4-btn-danger'} ${rest.className ? rest.className : ''}`}/>
);

export const WarningButton = ({outline = false, ...rest}) => (
    <Button {...rest} className={`${outline ? 'b4-btn-outline-warning' : 'b4-btn-warning'} ${rest.className ? rest.className : ''}`}/>
);

export const SuccessButton = ({outline = false, ...rest}) => (
    <Button {...rest} className={`${outline ? 'b4-btn-outline-success' : 'b4-btn-success'} ${rest.className ? rest.className : ''}`}/>
);

export const SecondaryButton = ({outline = false, ...rest}) => (
    <Button {...rest} className={`${outline ? 'b4-btn-outline-secondary' : 'b4-btn-secondary'} ${rest.className ? rest.className : ''}`}/>
);

export const LinkButton = ({outline = false, ...rest}) => (
    <Button {...rest} className={`${outline ? 'b4-btn-outline-link' : 'b4-btn-link'} ${rest.className ? rest.className : ''}`}/>
);

export const LoctaButton = ({ icon, textComponent, ButtonFn = PrimaryButton, iconSize ="24px", fontSize="10px", className="", fontWeight ="bold", ...rest }) => {
    const iconCol = icon !== null && typeof(icon) !== 'undefined' ? 
        (<Col size={3} className="d-flex align-items-center justify-content-center">
            <i className={icon} style={{ fontSize: iconSize }}></i>
        </Col>) : <></>;
    const buttonColSize = icon !== null && typeof(icon) !== 'undefined' ? 9 : 12;
    return (
        <ButtonFn className={`w-100 ${className}`} {...rest}>
            <Row className='d-flex justify-content-center align-items-center'>
                {iconCol}
                <Col size={buttonColSize} className="d-flex align-items-center justify-content-center" style={{ fontSize: fontSize, textTransform: "uppercase", fontWeight: fontWeight }}>
                    {textComponent}
                </Col>
            </Row>
        </ButtonFn>
    )
}