import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import tableFn from '../../react-table-extras/table';
import {LoctaButton, PrimaryButton, DangerButton, Row, Col, BootstrapSpinner} from '../../bootstrap';

export const EditCouncilButton = ({id}) => {
    return (
        <Row center>
            <Link to={{ pathname: `Edit/${id}` }}>
                <PrimaryButton small>Edit</PrimaryButton>
            </Link>
        </Row>
    );
};

const onDeleteClick = async (clientId) => {
    await axios.delete(`/api/clients/${clientId}`);
}

const ManageClient = ({clientId}) => (
    <Row className='d-flex justify-content-between align-items-center w-100'>
        <Col size={5}>
            <EditCouncilButton id={clientId}/>
        </Col>
        <Col size={5}>
            <LoctaButton ButtonFn={DangerButton} textComponent={<>Remove</>} fontSize="9px" fontWeight="normal" onClick={() => onDeleteClick(clientId)} />
        </Col>
    </Row>
);

const columns = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Password Policy',
        accessor: 'passwordPolicyDays',
        Cell: ({value}) => <>{value} days</>,
        disableFilters: true
    },
    {
        Header: 'Council Name',
        accessor: 'councilName'
    },
    {
        Header: 'Manage Client',
        accessor: 'id',
        Cell: ({value}) => <ManageClient clientId={value}/>,
        disableFilters: true
    }
];
const FilterTable = tableFn(columns);

const api = {
    getAllClients: "/api/clients"
}

const Table = () => {
    const [clients, setClients] = useState([]);
    
    useEffect(() => {
        axios.get(api.getAllClients).then(res => setClients(res.data.records));
    }, [setClients]);

    let result = <></>;

    if(clients.length === 0)
        result = <BootstrapSpinner loadingMessage='Loading clients data ...'/>
    else 
        result = <FilterTable data={clients}/>;

    return result;
};

export default Table;