import axios from "axios";

const urls = {
    existingPersonProfiles: "/api/person-profile/my-profiles",
    addToProfile: "/api/person-profile/add-to-profile",
}

export const getProfilesAsync = async () => {
    var result = await axios.post(urls.existingPersonProfiles);
    return result.data;
}

export const addToProfileAsync = async (values) => {
    var result = await axios.post(urls.addToProfile, values);
    return result.data;
}