import React from 'react';
import SearchTypes from './search-types';
import BusinessRatesDetail from './business-rates/results-detail';
import CouncilTaxDetail from './council-tax/results-detail';
import BusinessRatesSummaries from './business-rates/results-summaries';
import CouncilTaxSummaries from './council-tax/results-summaries';
import BenefitsSummaries from './benefits/results-summaries';

const ResultsDetailView = ({data, searchValues, resultType}) => {
    switch(resultType) {
        case SearchTypes.BusinessRates:
            return <BusinessRatesDetail searchType={resultType} detail={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        case SearchTypes.CouncilTaxName:
            return <CouncilTaxDetail searchType={resultType} detail={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        case SearchTypes.CouncilTaxAddress:
            return <CouncilTaxDetail searchType={resultType} detail={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        default:
            return null;
    }
}

export const ResultsSummmaryView = ({data, searchValues, resultType}) => {
    switch(resultType) {
        case SearchTypes.BusinessRates:
            return <BusinessRatesSummaries searchType={resultType} results={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        case SearchTypes.CouncilTaxName:
            return <CouncilTaxSummaries searchType={resultType} results={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        case SearchTypes.CouncilTaxAddress:
            return <CouncilTaxSummaries searchType={resultType} results={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        case SearchTypes.Benefits:
            return <BenefitsSummaries searchType={resultType} results={data} hasLinkedSearch={true} searchedValues={searchValues}/>;
        default:
            return null;
    }
}

export default ResultsDetailView;
