import * as Actions from '../redux-actions/superuser-actions';
import keyMirror from 'keymirror';

export const changeLogState = keyMirror({
    UNAVAILABLE: null,
    LOADING: null,
    AVAILABLE: null
});

const initialChangeLog = {
    state: changeLogState.UNAVAILABLE,
    data: {}
};

const initialState = {
    users: [],
    changeLog: initialChangeLog
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_USERS_DATA_FINISHED:
            return {...state, users: action.data.data.aaData};
        case Actions.GET_USER_CHANGELOG_STARTED:
            return {...state, changeLog: {state: changeLogState.LOADING, data:action.data}};
        case Actions.GET_USER_CHANGELOG_FINISHED:
            return {...state, changeLog: {state: changeLogState.AVAILABLE, data: action.data.data}};
        case Actions.GET_USER_CHANGELOG_ERROR:
            return {...state, changeLog: initialChangeLog};
        default:
            return state;
    }
};

export const selector = {
    users: (state) => state.users.users,
    changeLogData: (state) => state.users.changeLog.data,
    changeLogState: (state) => state.users.changeLog.state
};