import axios from 'axios';

import BaseSearchApi from '../base-api';

export const urls = {
    companyList: `/api/search/business-rates/company-list`,
    search: `/api/search/business-rates/search`,
    validate: `/api/search/business-rates/validate`
}

class Api extends BaseSearchApi {
    baseCompanyList = async values => {
        const searchResponse = await axios.post(urls.companyList, values);
        return searchResponse.data || [];
    }

    companyList = async values => await this.withCache(this.baseCompanyList, "baseCompanyList")(values);
}

const api = new Api(urls);
export default api;