import * as actions from '../redux-actions/locta-plus-actions';
import { SearchStatus, SearchNames} from './globals';


function updateResultsReducer(state, action) {
    let result = action.data;
    let newState = { ...state };

    newState.searches = {
        ...newState.searches,
        [result.type]: {
            ...newState.searches[result.type],
            searchProgressIndicator: result.status,
            searchIsInProgress: false,
            searchHasCompleted: true,
            exactResults: result.exactResults,
            allResults: result.allResults,
            query: result.query
        }
    };

    newState.searchIsInProgress = areWeStillSearching(newState);
    newState.searchError = '';

    return newState;
}

function areWeStillSearching(state) {
    let result = false;
    let { searches } = state;

    for(var prop in searches) {
        if (searches.hasOwnProperty(prop)) {
            if (searches[prop].searchIsInProgress) {
                result = true;
                break;
            }
        }
    }

    return result;
}

function searchHasStartedReducer(state, action) {
    return {
        ...state,
        searchIsInProgress: true,
        searchErrors: [],
        searches: {...initialSearches}
    };
}

function updateSearchProgressReducer(state, action) {
    let result = action.data;
    let newState = { ...state };

    // retain inProgress when status indicator is set to empty via timeout
    let inProgress = newState.searches[result.type].searchIsInProgress;

    switch (result.status) {
        case SearchStatus.Success:
            inProgress = false;
            break;
        case SearchStatus.Failed:
            inProgress = false;
            break;

        case SearchStatus.Searching:
            inProgress = true;
            break;
        default:
            break;
    }

    newState.searches = {
        ...newState.searches,
        [result.type]: {
            ...newState.searches[result.type],
            searchProgressIndicator: result.status,
            searchIsInProgress: inProgress,
            exactResults: result.status === SearchStatus.Failed ? 0 : newState.searches[result.type].exactResults,
            allResults: result.status === SearchStatus.Failed ? 0 : newState.searches[result.type].allResults
        }
    };

    newState.searchIsInProgress = areWeStillSearching(newState);

    return newState;
}

function updateSearchErrorReducer(state, action) {
    let message = action.data;
    let newState = { ...state };
    
    let newErrors = [ ...state.searchErrors, message ];
    newState.searchErrors = [ ...new Set(newErrors) ];

    return newState;
}

function setCouncilsReducer(state, action) {
    return {
        ...state,
        councils: action.data
    };
}

function showHelpModalReducer(state, action) {
    return {
        ...state,
        helpModal: { ...state.helpModal, isVisible: true }
    };
}

function closeHelpModalReducer(state, action) {
    return {
        ...state,
        helpModal: { ...state.helpModal, isVisible: false }
    };
}

function resetReducer() {
    return {
        ...initialState
    };
}

const initialSearches = {
    benefits: {
        name: SearchNames.Benefits,
        title: "Benefits",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: false,
        exactResults: 0,
        allResults: 0,
        query: {}
    },
    deceased: {
        name: SearchNames.Deceased,
        title: "Deceased",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: false,
        exactResults: 0,
        allResults: 0,
        query: {}
    },
    councilTaxAddress: {
        name: SearchNames.CouncilTaxAddress,
        title: "Council Tax Address",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: false,
        exactResults: 0,
        allResults: 0,
        query: {}
    },
    councilTaxName: {
        name: SearchNames.CouncilTaxName,
        title: "Council Tax Name",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: true,
        exactResults: 0,
        allResults: 0,
        query: {}
    },
    directory: {
        name: SearchNames.Directory,
        title: "Telephone Directory",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: false,
        exactResults: 0,
        allResults: 0,
        query: {}
    },
    votersRoll: {
        name: SearchNames.VotersRoll,
        title: "Voters Roll",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: true,
        exactResults: 0,
        allResults: 0,
        query: {}
    },
    votersRollByAddress: {
        name: SearchNames.VotersRollByAddress,
        title: "Voters Roll By Address",
        searchProgressIndicator: SearchStatus.Empty,
        searchIsInProgress: false,
        searchHasCompleted: false,
        hasAllMatchesOption: false,
        exactResults: 0,
        allResults: 0,
        query: {}
    }
};

const initialState = {
    councils: [],

    helpModal: {
        height: 100,
        width: 750,
        isVisible: false
    },

    searches: {...initialSearches},
    searchIsInProgress: false,
    searchErrors: []
};


export default function loctaPlusReducer(state = initialState, action = { type: 'NOT_SET' }) {
    switch (action.type) {
        case actions.UPDATE_RESULT:
            return updateResultsReducer(state, action);
        case actions.SEARCH_HAS_STARTED:
            return searchHasStartedReducer(state, action);
        case actions.UPDATE_SEARCH_PROGRESS:
            return updateSearchProgressReducer(state, action);
        case actions.UPDATE_SEARCH_ERROR:
            return updateSearchErrorReducer(state, action);

        case actions.SHOW_HELP_MODAL:
            return showHelpModalReducer(state, action);
        case actions.CLOSE_HELP_MODAL:
            return closeHelpModalReducer(state, action);

        case actions.SET_COUNCILS:
            return setCouncilsReducer(state, action);

        case actions.RESET_SEARCH:
            return resetReducer(state, action);
        default:
            return state;
    }
}

export const selector = {
    searches: (state) => state.loctaPlus.searches,
    helpModal: (state) => state.loctaPlus.helpModal,
    searchIsInProgress: (state) => state.loctaPlus.searchIsInProgress,
    searchErrors: (state) => state.loctaPlus.searchErrors,
    councilTaxAddressModal: (state) => state.loctaPlus.councilTaxAddressModal
};