import { TextInput, CheckboxInput, SelectInput } from '../../react-final-forms/inputs'
import { Field, Form } from 'react-final-form';
import { LoctaButton, PrimaryButton, SecondaryButton, Row, Col } from '../../bootstrap';
import DatasetSelectionModal from './dataset-selection-modal';
import { useState } from 'react';


/**
 * @typedef {object} SelectOption
 * @property {string} id
 * @property {string} name
 * 
 * @typedef {object} ClientFormOptions
 * @property {object} councils
 * @property {object} dataProviders
 * @property {SelectOption[]} datasetOptions
 * 
 * @typedef {object} ClientFormParams
 * @property {*} initialValues
 * @property {ClientFormOptions} options
 * @property {(values: any) => void} onSubmit
 * 
 * @param {ClientFormParams} param0 
 * @returns {JSX.Element}
 */
const ClientForm = ({ initialValues = null, options, onSubmit }) => {
    
    if (initialValues == null) return null;
    
    const [showDatasetModal, setShowDatasetModal] = useState(false);


    const { datasetOptions, councils, dataProviders } = options;
    
    let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
    let dataProviderOptions = dataProviders.map(dp => (<option value={dp.id} key={dp.id}>{dp.name}</option>));

    return (
        <div className='locta-form bg-grad p-3'>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, form, values, submitting, pristine }) => {
                    
                    const accessibleDatasets = datasetOptions.filter(d => values.datasetIds.includes(d.id)).map(d => <span key={d.id} className="bg-grey p-2 rounded">{d.name}</span>);

                    return (
                        <form onSubmit={handleSubmit}>
                            <TextInput name="name" label="Name" placeholder="Client Name" required={true} />
                            <SelectInput name="councilCode" label="Linked to Local Authority">
                                <option value="">-- Please Select --</option>
                                {councilOptions}
                            </SelectInput>
                            <SelectInput name="dataProviderId" label="Linked to Data Provider">
                                <option value="-1">-- Please Select --</option>
                                {dataProviderOptions}
                            </SelectInput>

                            <Row className='d-flex align-items-center justify-content-center w-100 pb-1'>
                                <Col size={5} className="d-flex justify-content-start align-items-center">
                                    <label>Access to datasets:</label>
                                </Col>
                                <Col size={7} style={styles.accessibleDataSetsContainer}>
                                    <Field name="datasetIds">{()=>null}</Field>
                                    {accessibleDatasets}
                                    <button type="button" className="b4-btn b4-btn-sm b4-btn-primary" style={{marginLeft: "30px"}} onClick={() => setShowDatasetModal(true)}>Change</button> 
                                </Col>
                            </Row>
                            { showDatasetModal && <DatasetSelectionModal onClose={() => setShowDatasetModal(false)} onSelectionChange={(ids) => form.change('datasetIds', ids)} selectedDatasetIds={values.datasetIds} datasetOptions={datasetOptions} /> }

                            <hr />
                            <TextInput label="Grid N" name="gridN" placeholder="Grid N" required={true}/>
                            <TextInput label="Grid E" name="gridE" placeholder="Grid E" required={true}/>
                            <TextInput label="Latitude" name="latitude" placeholder="Latitude" required={true}/>
                            <TextInput label="Longitude" name="longitude" placeholder="Longitude" required={true}/>
                        
                            <hr />
                            <CheckboxInput name="datamatchEnabled" label="NINO Match" />
                            <TextInput name="datamatchContactEmail" label="NINO Match Email" placeholder="NINO Match Email" />
                            <TextInput name="datamatchContactName" label="NINO Match Contact Name" placeholder="NINO Match Contact Name" />
                            <TextInput name="datamatchContactTel" label="NINO Match Contact Telephone" placeholder="NINO Match Contact Telephone" />
                            <CheckboxInput name="showNinoMatchDownloads" label="Show Nino Match Analysis Docs" />
                            <hr />
                            <CheckboxInput name="sbrEnabled" label="SBRR/SBBS Alert Enabled" />
                            <TextInput name="sbrContactEmail" label="SBRR/SBBS Alert Email" placeholder="SBRR/SBBS Alert Email" />
                            <hr />
                            <CheckboxInput name="dissolvedCompaniesEnabled" label="Dissolved Companies" />
                            <TextInput name="dissolvedCompaniesEmail" label="Dissolved Companies Email" placeholder="Dissolved Companies Email" />
                            <hr />
                            <TextInput name="passwordPolicyExpiration" label="Password Expiration" placeholder="Password Expiration" required={true} />
                            <CheckboxInput name="annualLicense" label="Annual License" />
                            <CheckboxInput name="sendWelcomeEmails" label="Send Welcome Emails" />

                            <hr />
                            <h5>Modules</h5>

                            <CheckboxInput name="loctaPlusEnabled" label="LoCTA Plus Enabled" />
                            <CheckboxInput name="benefitsEnabled" label="Benefits Module Enabled" />
                            <CheckboxInput name="cTaxNameEnabled" label="Council Tax Name Module Enabled" />
                            <CheckboxInput name="cTaxAddressEnabled" label="Council Tax Address Module Enabled" />
                            <CheckboxInput name="businessRatesEnabled" label="Business Rates Module Enabled" />
                            <CheckboxInput name="companiesHouseEnabled" label="Companies House Module Enabled" />
                            <CheckboxInput name="votersRollEnabled" label="Voters Roll Module Enabled" />
                            <CheckboxInput name="votersRollByAddressEnabled" label="Voters Roll By Address Module Enabled" />
                            <CheckboxInput name="vrAlertsEnabled" label="Voters Roll Alerts Enabled" />
                            <CheckboxInput name="telephoneDirectoryEnabled" label="Telephone Directory Module Enabled" />
                            <CheckboxInput name="deceasedEnabled" label="Deceased Module Enabled" />
                            <CheckboxInput name="advancedBatchEnabled" label="Trace Batch Module Enabled" />
                            <CheckboxInput name="businessRatesBatchEnabled" label="Business Rates Batch Module Enabled" />
                            <CheckboxInput name="equifaxConsumerEnabled" label="Equifax Consumer Module Enabled" />
                            <CheckboxInput name="transUnion360ReportEnabled" label="TransUnion 360 Report Enabled" />
                            <CheckboxInput name="transUnionCallReportEnabled" label="TransUnion Call Report Enabled" />
                            <CheckboxInput name="quickSearchAlertsEnabled" label="Quick Search Alerts Enabled" />

                            <hr />
                            <h5>Witness Protection / Withheld Records</h5>
                            <CheckboxInput name="cTaxFilterEnabled" label="CTax Records" />
                            <CheckboxInput name="ninoFilterEnabled" label="Benefit Records" />

                            <hr />
                            <h5>Alert Thresholds</h5>

                            <TextInput name="thresholdBens" label="Benefit Threshold" placeholder="Benefit Threshold" />
                            <TextInput name="thresholdCtaxAddr" label="CTAX Address Threshold" placeholder="CTAX Address Threshold" />
                            <TextInput name="thresholdCtaxName" label="CTAX Name Threshold" placeholder="CTAX Name Threshold" />
                            <TextInput name="thresholdNndr" label="Business Rates Threshold" placeholder="Business Rates Threshold" />
                            <TextInput name="thresholdTelephone" label="Telephone Directory Threshold" placeholder="Telephone Directory Threshold" />
                            <TextInput name="thresholdVotersRoll" label="Voters Roll Threshold" placeholder="Voters Roll Threshold" />
                            <TextInput name="thresholdDeceased" label="Deceased Threshold" placeholder="Deceased Threshold" />
                            <TextInput name="thresholdAddr" label="Address Threshold" placeholder="Address Threshold" />
                            <Row className='d-flex align-items-center justify-content-end'>
                                <Col size={1}>
                                    <LoctaButton small={true} className="h-100 mt-2" disabled={submitting || pristine} ButtonFn={SecondaryButton} textComponent={<>Reset</>} fontSize="18px" fontWeight="normal" onClick={form.reset} />
                                </Col>
                                <Col size={1} className='pl-2'>
                                    <LoctaButton type="submit" small={true} className="h-100 mt-2" disabled={submitting || pristine} ButtonFn={PrimaryButton} textComponent={<>Save</>} fontSize="18px" fontWeight="normal" />
                                </Col>
                            </Row>

                            <ul className="inline-fields search-buttons">
                                <li className="align-right b4" style={{ width: "100px", height: "32px" }}>
                                </li>
                                <li className="align-right b4" style={{ width: "100px", height: "32px" }}>
                                </li>
                            </ul>
                        </form>
                    );
                }}
            />
        </div>

    )
};

export default ClientForm;

const styles = {
    accessibleDataSetsContainer: {
        marginTop: "0.5rem",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        gap: "10px"
    }
}