import axios from 'axios';

import BaseSearchApi from '../base-api';

export const urls = {
    personList: `/api/search/voters-roll/person-list`,
    search: `/api/search/voters-roll`,
    validate: `/api/search/voters-roll/validate`
}

class Api extends BaseSearchApi {
    basePersonList = async values => {
        const searchResponse = await axios.post(urls.personList, values);
        return searchResponse.data || [];
    }

    personList = async values => await this.withCache(this.basePersonList, "basePersonList")(values);
}

const api = new Api(urls);
export default api;