import React, {useEffect} from "react";
import { councils } from './server-values';
import sliderConfig from '../config';
import { BootstrapContainer, Row } from '../../bootstrap';
import { Form } from 'react-final-form';
import { searchFormName } from './config';
import { TextInput, CheckboxInput, SelectInput, RangeInput } from '../../react-final-forms';
import Loading from '../loading';
import { isAutoSearch } from "./server-values";


const containerStyles = { border: 0, borderBottom: "1px solid rgba(0, 0, 0, .1)", marginTop: "10px" };

let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
councilOptions.sort((a, b) => a.props.children.localeCompare(b.props.children));

const MyForm = ({ handleSubmit, values, form, searchForm, searchDone }) => {
    searchForm.current = form;
    useEffect(() => {
        if (isAutoSearch && !searchDone) {
            form.submit();
        }
    }, [searchDone, form]);

    const maxSearchDistanceInt = parseInt(values.maxSearchDistance || 0);
    let tabIndex = 1;

    return (
        <div className={"locta-form block-grey rb rt"} style={containerStyles}>
            <div className="inner">
                <form id={searchFormName} className="form-search rf" autoComplete="off" onSubmit={handleSubmit} >
                    <ul className="inline-fields">
                        <li className="no-bottom-margin">
                            <TextInput name="forename" label="Forename" placeholder="Forename" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <TextInput name="surname" label="Surname" placeholder="Surname" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <CheckboxInput name="isExact" label="Exact Match" tabIndex={tabIndex++} inLine={false} />
                        </li>
                    </ul>
                    <div className="pt-3">
                        <span className="font-weight-bold">By Distance from Council</span>
                        <Row className="inline-fields d-flex align-items-center justify-content-start w-50 pt-2">
                            <div>
                                <SelectInput name="selectedCouncilId" tabIndex={tabIndex++} inLine={false}>
                                    <option value="0">Current Council</option>
                                    {councilOptions}
                                </SelectInput>
                            </div>
                            <div className="pl-2">
                                <RangeInput name="maxSearchDistance" inLine={false} inputClassName="white" min={sliderConfig.sliderMinSearchDistance} max={sliderConfig.sliderMaxSearchDistance} step={sliderConfig.sliderDistanceStep} tabIndex={tabIndex++} />
                            </div>
                            <div className="pl-1">
                                <span style={{ lineHeight: '30px' }}>{maxSearchDistanceInt >= 640 ? 'National' : `${maxSearchDistanceInt} miles`}</span>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </div>
    );
}


const SearchFields = ({ onSubmit, initialValues, onReset, searchForm, isLoading, ...rest }) => {
    return (
        <BootstrapContainer style={{ height: "100%" }}>
            <div className={isLoading ? "" : "d-none"}>
                <Loading message="Searching data ..." />
            </div>
            <div className={isLoading ? "d-none" : ""}>
                <Form
                    onSubmit={onSubmit}
                    onReset={onReset}
                    initialValues={initialValues}
                    searchForm={searchForm}
                    {...rest}
                >
                    {MyForm}
                </Form>
            </div>
        </BootstrapContainer>
    );
}

export default SearchFields;