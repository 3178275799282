import { useEffect, useState } from 'react';
import axios from 'axios';

export const ClientLoginLinks = () => {

    const [selectedClientId, setSelectedClientId] = useState(-1);

    const [clients, setClients] = useState([]);
    const [loginLinks, setLoginLinks] = useState([]);

    const getClients = () => `/api/clients`;
    const getClientsLoginLinks = (clientId) => `/api/clients/login-links/${clientId}`;
    const addClientsLoginLink = (clientId) => `/api/clients/login-links/${clientId}`;
    const deleteClientsLoginLink = (clientId, token) => `/api/clients/login-links/${clientId}/${token}`;

    useEffect(() => {
        axios.get(getClients()).then(response => {
            const data = response.data.records;
            setClients(data);
         });
    }, []);

    const onClientChange = (ev) => {
        var clientId = ev.target.value;
        setSelectedClientId(clientId);

        axios.get(getClientsLoginLinks(clientId)).then(response => {
            const data = response.data;
            setLoginLinks(data);
         });
    }

    const onAddClick = () => {
        axios.post(addClientsLoginLink(selectedClientId)).then(response => {
            const data = response.data;
            setLoginLinks(data);
         });
    }

    const onDeleteClick = (clientId, token) => {
        axios.delete(deleteClientsLoginLink(clientId, token)).then(response => {
            const data = response.data;
            setLoginLinks(data);
         })
    }

    let existing = (
        <ul>
            { loginLinks.length > 0 
                ? loginLinks.map((a) => (
                    <li>
                        https://www.loctatraining.co.uk/access/{a.token} <button className="btn btn-link red pull-right mr-20" type="button" onClick={() => onDeleteClick(a.clientId, a.token)}>X</button>
                    </li>
                ))
                : (
                    <li>None</li>
                )
            }
        </ul>
    );

    return (
        <div className="bg-grad edit-record">
            <ul className="admin-edit">
                <li className="row-fluid">
                    <label className="span2">Client</label>
                    <div className="span6">
                        <select className="input-block-level" value={selectedClientId} onChange={onClientChange}>
                            <option>-- Please Select --</option>
                            { clients.map((c) => <option value={c.id} key={c.id}>{c.name}</option> )}
                        </select>
                        <button className="btn" onClick={onAddClick}><i className="icon-play"></i> Generate New Link</button>
                    </div>
                </li>
                <li className="row-fluid existing-links">
                    <label className="span2">Existing</label>
                    <div className="span6">
                        { existing }
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ClientLoginLinks;