import React, { useEffect } from "react";
import { Form } from 'react-final-form';

import config from '../config';

import {councils} from './server-values';

import { LoctaButton, PrimaryButton, SecondaryButton, BootstrapContainer, Row } from '../../bootstrap';
import { TextInput, DateInput, CheckboxInput, SelectInput, RangeInput } from '../../react-final-forms';

let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
councilOptions.sort((a, b) => a.props.children.localeCompare(b.props.children));

const MyForm = ({ handleSubmit, submitting, searchDone, form, isAutoSearch, values, onReset}) => {
    const resetDisabled = isAutoSearch;
    const continueDisabled = isAutoSearch;
    const submitDisabled = submitting || continueDisabled;

    useEffect(() => {
        if (isAutoSearch && !searchDone) {
            form.submit();
        }
    }, [searchDone, form, isAutoSearch]);
    const maxSearchDistanceInt = parseInt(values.maxSearchDistance || 0);

    let tabIndex = 1;
    return (
        <>
            <div className={"locta-form block-grey rb " + (isAutoSearch ? "hidden" : "")}>
                <div className="inner">
                    <form className="form-search rf" autoComplete="off" onSubmit={handleSubmit}>
                        <ul className="inline-fields mb-0">
                            <li className="no-bottom-margin">
                                <TextInput name="forename" label="Forename" placeholder="Forename" tabIndex={tabIndex++} inLine={false}/>
                            </li>
                            <li>
                                <TextInput name="surname" label="Surname" placeholder="Surname" tabIndex={tabIndex++} inLine={false}/>
                            </li>
                            <li>
                                <DateInput name="searchStartDate" label="Search Start" tabIndex={tabIndex++}  inLine={false}/>
                            </li>
                            <li>
                                <DateInput name="searchEndDate" label="Search End" tabIndex={tabIndex++}  inLine={false}/>
                            </li>
                            <li>
                                <CheckboxInput name="isLive" label="Live Cases Only" tabIndex={tabIndex++}  inLine={false}/>
                            </li>
                            <li>
                                <CheckboxInput name="isExact" label="Exact Match" tabIndex={tabIndex++}  inLine={false}/>
                            </li>

                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton type="submit" small={true} className="h-100 mt-20" disabled={submitDisabled} icon={submitting ? "fas fa-spinner" : "fas fa-play-circle"} ButtonFn={PrimaryButton} textComponent={<>Search</>} iconSize="16px" fontSize="11px" fontWeight="normal" />
                            </li>
                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton small={true} className="h-100 mt-20" icon="fas fa-sync" disabled={submitting || resetDisabled} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset(form)}/>
                            </li>
                        </ul>
                        <Row className="inline-fields d-flex align-items-center justify-content-start mt-0 w-25">
                            <CheckboxInput name="blankForename" label="Blank forename" labelClassName="m-0 font-weight-normal" tabIndex={tabIndex++}  inLine={true} inverse={true} sizeLabel={10} sizeInput={2}/>
                        </Row>
                        <span className="font-weight-bold">By Distance from Council</span>
                        <Row className="inline-fields d-flex align-items-center justify-content-start w-50 pt-2">
                            <div >
                                <SelectInput name="selectedCouncilId" tabIndex={tabIndex++} inLine={false}>
                                    <option value="0">Current Council</option>
                                    {councilOptions}
                                </SelectInput>
                            </div>
                            <div className="pl-2">
                                <RangeInput name="maxSearchDistance" inLine={false} inputClassName="white" min={config.sliderMinSearchDistance} max={config.sliderMaxSearchDistance} step={config.sliderDistanceStep} tabIndex={tabIndex++}/>
                            </div>
                            <div  className="pl-1">
                                <span style={{ lineHeight: '30px' }}>{maxSearchDistanceInt >= 640 ? 'National' : `${maxSearchDistanceInt} miles`}</span>
                            </div>
                            
                        </Row>
                    </form>
                </div>
            </div>
            {isAutoSearch && submitting ? <div className="auto-search-loading">Loading...</div> : null}
        </>
    );
}

const SearchFields = ({ onSubmit, onReset, initialValues, ...rest }) => {
    return (
        <BootstrapContainer style={{ height: "100%" }}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                onReset={onReset}
                {...rest}
            >
                {MyForm}
            </Form>
        </BootstrapContainer>
    );
}

export default SearchFields;