import React, { Component } from 'react';
import './index.css';

class AddressFinder extends Component {
    componentDidMount() {
        if(this.props.searchResult.IsValid) {
            this.updateCanvas();
        }
    }

    updateCanvas() {
        const longitude = this.props.searchResult.Result.Longitude;
        const latitude = this.props.searchResult.Result.Latitude;
        // Size of the map
        var width = 360;
        var height = 500;

        // X and Y working boundaries
        var westLong = -8.166667;
        var eastLong = 1.762833;
        var northLat = 58.666667;
        var southLat = 49.95;

        //Box Position
        var x = width * ((westLong - longitude) / (westLong - eastLong));
        var y = (height * ((northLat - latitude) / (northLat - southLat)));

        //canvas
        const ctx = this.refs.myCanvas.getContext('2d');
        ctx.width = 360;
        ctx.height = 500;

        //draw it
        ctx.strokeRect(x, y, 20, 20);
    }

    render() {
        const searchData = this.props.searchResult.result;

        const councils = searchData.neighbouringCouncils.map(council => {
            return (
                <tr key={council.CouncilName}>
                    <td className="table_cell_grey">{council.CouncilName}</td>
                    <td className="table_cell_white" style={{color: council.IsOnline ? "green" : "red"}}>{council.IsOnline ? "Online": "Not a LoCTA Subscriber"}</td>
                </tr>
            );
        });


        const addressOptions = searchData.addressesAtPostcode.map(address => <option key={address}>{address}</option>);

        let addressOptionsSize = searchData.addressesAtPostcode.length; 
        if(addressOptionsSize > 10){
            addressOptionsSize = 10;
        }

        let addressResult = null;

        if(this.props.searchResult.IsValid === false) {
            addressResult = (
                <div>
                    No address found at postcode.
                </div>
            );
        } else {
            addressResult = (
                <div className="tab-content">
                        <div className="row" style={{marginTop:'20px'}}>
                            <div className="span12 address-finder-span">
                                <div className="inner">
                                    <header className="block-header blue-header">
                                        <h3>Addresses at postcode</h3>
                                    </header>

                                    <select multiple="multiple" className="address-finder-multiple-select" size={addressOptionsSize}>
                                        {addressOptions}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'20px'}}>
                            <div className="span5 address-finder-span-5">
                                <div className="inner">
                                    <div className="inner">
                                        <img src="/images/locta_map.png" alt="Map" id="mapImage" width="360" height="500" />
                                        <canvas ref="myCanvas" id="myCanvas" width="360" height="500"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="span5 address-finder-span-5">
                                <div className="inner">
                                    <header className="block-header blue-header">
                                        <h3>Authority Details</h3>
                                    </header>
                                    <table className="table table-bordered table-condensed" style={{marginTop: '3px'}}>
                                        <tbody>
                                            <tr>
                                                <td className="table_cell_grey">Local Authority Name</td>
                                                <td className="table_cell_white">{searchData.localAuthorityName}</td>
                                            </tr>
                                            <tr>
                                                <td className="table_cell_grey">Contact Tel Number</td>
                                                <td className="table_cell_white">{searchData.contactTelNumber}</td>
                                            </tr>
                                            <tr>
                                                <td className="table_cell_grey">Distance from your council</td>
                                                <td className="table_cell_white">{searchData.distanceFromYourCouncil} miles</td>
                                            </tr>
                                            <tr>
                                                <td className="table_cell_grey">Locta Status</td>
                                                <td className="table_cell_white" style={{color: searchData.loctaStatus === "Online" ? "green" : "red"}}>{searchData.loctaStatus}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <header className="block-header blue-header">
                                        <h3>Neighboring Councils</h3>
                                    </header>
                                    <table className="table table-bordered table-condensed" style={{marginTop: '3px'}}>
                                        <tbody>
                                            {councils}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            );
        }
        
        return (
            <div>
                <section  className="search-container">
                    <header className="block-header block-grey">
                        <span className="icon-surround"><i className="icon-house"></i></span>
                        <h2>Address Finder</h2>
                    </header>

                    <section className="search-results">
                        <header className="block-header bg-blue-grad blue-header has-tabs">
                            <ul className="nav nav-tabs pull-left">
                                <li className="active"><a href="#all-results" data-toggle="tab">All Results</a></li>
                            </ul>
                            <ul className="nav option-tabs pull-right">
                                <li className="active pull-right">
                                    <a href={"/FurtherActions/ExportAddressToPDF?postcode=" + searchData.postcode} target="_blank" rel="noopener noreferrer">Export to PDF</a>
                                    <button onClick={() => {window.print()}}>Send to Print</button>
                                </li>
                            </ul>
                        </header>
                        {addressResult}
                    </section>
                </section>
            </div>
        );
    }
}

export default AddressFinder;