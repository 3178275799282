import { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {onToastFn} from '../toasts';
import {HttpTransportType} from '@microsoft/signalr';

const SignalR = () => {
    const [ connection, setConnection ] = useState(null);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/hubs/toastr', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
        
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(_ => {
                    connection.on('ReceiveToast', message => {
                        onToastFn(message);
                    });

                    connection.send("Connect");
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return null;
};

export default SignalR;