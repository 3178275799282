import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';

export const TELEPHONE_DIRECTORY_SEARCH_START = 'TELEPHONE_DIRECTORY_SEARCH_START';
export const TELEPHONE_DIRECTORY_SEARCH_SUCCESS = 'TELEPHONE_DIRECTORY_SEARCH_SUCCESS';
export const TELEPHONE_DIRECTORY_SEARCH_FAIL = 'TELEPHONE_DIRECTORY_SEARCH_FAIL';
export const TELEPHONE_DIRECTORY_SEARCH_RESET = 'TELEPHONE_DIRECTORY_SEARCH_RESET';

export const urls = {
    search: `/api/search/telephone-directory`,
    validate: `/api/search/telephone-directory/validate`
}

export const formName = "telephone-directory-search";

class TelephoneDirectorySearchViewModel {
    constructor(model) {
        this.Surname = model.surname;
        this.CompanyName = model.companyName;
        this.Location = model.location;
        this.IsLinkedSearch = model.isLinkedSearch;
        this.IsLoctaPlusSearch = model.isLoctaPlusSearch;
        this.IsQuickSearch = model.isQuickSearch;

        this.__RequestVerificationToken = model.__requestVerificationToken;
    }
}

const action = (type, data = null) => ({
    type, data
});

export function performSearch(model) {
    return async (dispatch) => {

        dispatch(action(TELEPHONE_DIRECTORY_SEARCH_START, model));

        const formValues = new TelephoneDirectorySearchViewModel(model);

        const validateResponse = await axios
            .post(urls.validate, formValues);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            //goToStep(2);
            const searchResponse = await axios.post(urls.search, formValues);
            dispatch(action(TELEPHONE_DIRECTORY_SEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(TELEPHONE_DIRECTORY_SEARCH_FAIL));
        }
    }
}

export function validateSearch(model) {
    return async (dispatch) => {
        const formValues = new TelephoneDirectorySearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export function resetSearch(values) {
    return (dispatch) => {

        dispatch(action(TELEPHONE_DIRECTORY_SEARCH_RESET));
        dispatch(initialize(formName, {
            ...values,
            surname: '',
            companyName: '',
            location: ''
        }));
        //goToStep(1);
    }
}
