import React from 'react';
import BusinessRatesExport from './nndr';
import CouncilTaxExport from './ctaxname';
import BenefitsExport from './benefits';
import TelephoneDirectoryExport from './directory';
import VotersRollExport from './votersroll';
import SearchTypes from '../searches-results/search-types';
import { BootstrapContainer } from '../bootstrap';
import './index.css';

export default function index({ data, resultType }) {
    let component = null;
    switch (resultType) {
        case SearchTypes.BusinessRates:
            component = <BusinessRatesExport data={data} />;
            break;
        case SearchTypes.CouncilTaxName:
            component = <CouncilTaxExport data={data} />;
            break;
        case SearchTypes.CouncilTaxAddress:
            component = <CouncilTaxExport data={data} />;
            break;
        case SearchTypes.Benefits:
            component = <BenefitsExport data={data} />;
            break;
        case SearchTypes.Directory:
            component = <TelephoneDirectoryExport data={data} />;
            break;
        case SearchTypes.VotersRoll:
            component = <VotersRollExport data={data} />;
            break;
        default:
            component = null;
            break;
    }

    return (
        <BootstrapContainer className="container-fluid printer-styles">
            {component}
        </BootstrapContainer>
    )
}
