import React from 'react';
import { fuzzyTextFilterFn } from '../bootstrap/table-filters';

const FilterTypesFn = () => React.useMemo(
    () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        },
    }),
    []
);

export default FilterTypesFn;