import * as Actions from '../redux-actions/council-tax-name-search-actions';
import SearchTypes from '../react-components/searches-results/search-types';

export const initialSearchedValues = {
    forename: '',
    blankForename: false,
    surname: '',
    selectedCouncilId: 0,
    maxSearchDistance: 0,
    searchStart: '',
    searchEnd: '',
    isLive: false,
    isExact: false,
    telephone: '',
    email: '',
    initialSearch: 'CouncilTaxName',
    linkedSearch: '',
    secondLinkedSearch: '',
    isLinkedSearch: false,
    isLoctaPlusSearch: false,
    isQuickSearch: false
};

const initialState = {
    searchType: SearchTypes.CouncilTaxName,
    searchedValues: {...initialSearchedValues},
    results: [],
    searchHasBeenPerformed: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.COUNCIL_TAX_NAME_SEARCH_START:
            return {
                ...state,
                searchedValues: action.data,
                searchHasBeenPerformed: false,
                results: []
            };
        case Actions.COUNCIL_TAX_NAME_SEARCH_SUCCESS:
            return {
                ...state,
                searchHasBeenPerformed: true,
                results: action.data
            };
        case Actions.COUNCIL_TAX_NAME_SEARCH_FAIL:
            return {
                ...state,
                searchHasBeenPerformed: true,
                results: []
            };
        case Actions.COUNCIL_TAX_NAME_SEARCH_RESET:
            return {
                ...state,
                searchHasBeenPerformed: false,
                results: []
            };
        default:
            return state;
    }
}

export const selector = {
    searchType: (state) => state.councilTaxNameSearch.searchType,
    searchedValues: (state) => state.councilTaxNameSearch.searchedValues,
    results: (state) => state.councilTaxNameSearch.results,
    hasLinkedSearch: (state) => (state.councilTaxNameSearch.searchedValues.secondLinkedSearch === '' && !state.councilTaxNameSearch.searchedValues.isQuickSearch),
    searchHasBeenPerformed: (state) => state.councilTaxNameSearch.searchHasBeenPerformed
};
