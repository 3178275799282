import React, { useState, useCallback, useEffect } from 'react';
import { Field } from 'react-final-form';
import { Row, Col, ReactDatePicker as DatePicker } from '../bootstrap';
import { Multiselect } from 'react-widgets';
import CustomPostcode from '../shared/postcode-input';

const requiredValidator = value => (value ? undefined : 'Required');
const arrayRequiredValidator = value => (value && value.length > 0 ? undefined : 'Required');
const composeValidators = (validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const LabelContainerInLine = ({ label, children, inverse = false, sizeLabel = 5, sizeInput = 7, labelClassName = '' }) => {
    const labelCol = label !== "" ?
        <Col size={sizeLabel} className="d-flex justify-content-start align-items-center">
            <label className={`${labelClassName}`}>{label}</label>
        </Col> : null;

    const inputCol =
        <Col size={sizeInput}>
            {children}
        </Col>;

    return (
        <Row className='d-flex align-items-center justify-content-center w-100 pb-1'>
            {inverse ? <>{inputCol}{labelCol}</> : <>{labelCol}{inputCol}</>}
        </Row>
    );
};

const LabelContainerInColumn = ({ label, children }) => {
    return (
        <>
            {label ?
                <Row className='d-flex align-items-center'>
                    <label>{label}</label>
                </Row> : null}
            <Row className='d-flex align-items-center'>
                {children}
            </Row>
        </>
    );
};

const LabelContainer = ({ inLine, ...rest }) => inLine ? LabelContainerInLine(rest) : LabelContainerInColumn(rest);


const FieldsInLine = ({ children, meta, showError, required, ...rest }) => (
    <Row className='input-row d-flex align-items-center'>
        <Col size={7} className='h-100'>
            {children({ meta, ...rest })}
        </Col>
        {required ? <div className='mb-2 ml-1'>*</div> : null}
        {(showError && (meta.submitError || meta.error)) &&
            <Col className='h-100 d-flex align-items-center justify-content-center'>
                <div className='bg-danger text-white p-1 rounded'>{meta.submitError || meta.error}</div>
            </Col>
        }
    </Row>
);

const FieldsInColumn = ({ children, meta, required, showError, ...rest }) => (
    <Row className='d-flex flex-column'>
        <Row className='input-row d-flex align-items-center'>
            {children({ meta, ...rest })}
        </Row>
        {required ? <div className='mb-2 ml-1'>*</div> : null}
        {(showError && (meta.submitError || meta.error)) &&
            <Row className='input-row d-flex align-items-center justify-content-center'>
                <div className='bg-danger text-white p-1 rounded'>{meta.submitError || meta.error}</div>
            </Row>
        }
    </Row>
);

const FieldContainer = ({ inLine, name, validate, ...rest }) => (
    <Field
        name={name}
        validate={validate}
    >
        {(props) => inLine ? FieldsInLine({ ...props, ...rest }) : FieldsInColumn({ ...props, ...rest })}
    </Field>
)

const MultiselectAdapter = ({ input, meta, showError, showCount, required, filter, showSelectedItemsInList, options = [], ...rest }) => {
    const [myValues, setValues] = useState(input.value === "" ? [] : input.value);
    const [myOptions, setOptions] = useState(options);

    const handleOnChange = (newValues) => {
        setValues(newValues);
        input.onChange(newValues);
    }

    const handleCreate = (option) => {
        handleOnChange([...myValues, option]);
        setOptions(data => [option, ...data]);
    };

    return (
        <Row className='d-flex align-items-center'>
            <Col size={7}>
                <Multiselect
                    data={myOptions}
                    value={myValues}
                    dataKey='id'
                    textField='name'
                    onCreate={handleCreate}
                    onChange={handleOnChange}
                    showSelectedItemsInList={showSelectedItemsInList}
                    filter={filter}
                    {...rest}
                />
            </Col>
            {showCount && <div className='ml-1'>[{myValues.length}]</div>}
            {required ? <div className='mb-2 ml-1'>*</div> : null}
            {(showError && (meta.submitError || meta.error)) &&
                <Col className='h-100 d-flex align-items-center justify-content-center'>
                    <div className='bg-danger text-white p-1 rounded'>{meta.submitError || meta.error}</div>
                </Col>
            }
        </Row>
    );
};

export const MultiselectInput = ({ label, name, options, showCount=false, filter=null, showSelectedItemsInList = false, showError = false, required = false, inLine = true, allowCreate = false }) => {
    let validate = null;
    let validators = {};
    if (required) {
        showError = true;
    }
    if (required) {
        validators = { ...validators, arrayRequiredValidator }
    }

    if (Object.keys(validators).length > 0) {
        validate = composeValidators(Object.values(validators));
    }

    return (
        <LabelContainer label={label} inLine={inLine}>
            <Field
                name={name}
                component={MultiselectAdapter}
                options={options}
                showError={showError}
                showSelectedItemsInList={showSelectedItemsInList}
                required={required}
                allowCreate={allowCreate}
                showCount={showCount}
                filter={filter}
                validate={validate}
            />
        </LabelContainer>
    );
};

const Input = ({ label, name, placeholder = "", type = "text", inputClassname = '', inverse = false, required = false, inLine = true, showError = false, sizeLabel, sizeInput, labelClassName, ...rest }) => {
    let validate = null;
    let validators = {};
    if (required) {
        showError = true;
    }
    if (required) {
        validators = { ...validators, requiredValidator }
    }

    if (Object.keys(validators).length > 0) {
        validate = composeValidators(Object.values(validators));
    }

    return (
        <LabelContainer label={label} inLine={inLine} sizeLabel={sizeLabel} sizeInput={sizeInput} labelClassName={labelClassName} inverse={inverse}>
            <FieldContainer name={name} validate={validate} inLine={inLine} showError={showError} required={required}>
                {({ input, meta }) => (
                    <input {...input} {...rest} type={type} placeholder={placeholder} className={`${inputClassname} ${meta.submitError ? " invalid-search-input" : ""}`} checked={(type === "checkbox" || type === "radio") ? input.value : null} />
                )}
            </FieldContainer>
        </LabelContainer>
    );
};

export const DateInput = ({ label, name, inLine = true, ...rest }) => {
    return (
        <LabelContainer label={label} inLine={inLine}>
            <FieldContainer name={name} inLine={inLine}>
                {({ input, meta }) => (
                    <DatePicker
                        {...input}
                        {...rest}
                        className={`search-field` + (meta.submitError ? " invalid-search-input" : "")}
                    />
                )}
            </FieldContainer>
        </LabelContainer>
    );
};

export const SelectInput = ({ label, name, inputClassname = '', inLine = true, multiple = false, children, ...rest }) => {
    return (
        <LabelContainer label={label} inLine={inLine}>
            <Field name={name} component="select" multiple={multiple} {...rest} className={inputClassname}>
                {children}
            </Field>
        </LabelContainer>
    );
};

export const PostcodeInput = ({ label, name, tabIndex, inLine = true, handleSubmit = () => {} }) => {
    return (
        <LabelContainer label={label} inLine={inLine}>
            <FieldContainer name={name} inLine={inLine}>
                {({ input, meta }) =>
                    <CustomPostcode tabIndex={tabIndex} handleSubmit={handleSubmit} containerClassName={`search-field` + (meta.submitError ? " invalid-search-input" : "")} {...input} name={name} />
                }
            </FieldContainer>
        </LabelContainer>
    );
};


export const TextInput = ({ ...rest }) => <Input {...rest} type="text" inputClassname='w-100 h-100' />;
export const CheckboxInput = ({ inputClassName = '', ...rest }) => <Input {...rest} type="checkbox" inputClassname={inputClassName} showError={false} />;
export const TextAreaInput = ({ ...rest }) => <Input {...rest} type="textarea" inputClassname='w-100 h-100' />;
export const RangeInput = ({ inputClassName = '', ...rest }) => <Input {...rest} type="range" inputClassname={`w-100 h-100 ${inputClassName}`} />;
export const HiddenInput = ({ ...rest }) => <Field {...rest} component="input" type="hidden" />

