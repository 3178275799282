import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import {connect} from 'react-redux';
import {selector} from '../../redux-reducers/companies-house-reducer';
import {ReactTableContainer, ExpandedTableHOC, SecondaryBadge, SuccessBadge} from '../bootstrap';
import {cellAcc, fuzzyFilter} from '../table';
import CompanyCard from './company-card';
import {ProgressBar} from '../bootstrap';

const AdvancedExpandReactTable = ExpandedTableHOC(ReactTable);

const Company = ({value}) => {
    const {companyName, companyStatus} = value.document;

    const badge = companyStatus === "Active" 
        ? <SuccessBadge className="ml-2 text-uppercase pl-2 pr-2">{companyStatus}</SuccessBadge> 
        : <SecondaryBadge className="ml-2 text-uppercase pl-2 pr-2">{companyStatus}</SecondaryBadge>;
        
    return <span className="font-weight-bold">{companyName}{badge}</span>;
}

const scoreSortFn = (scores) => (a, b) => {
    const aCompanyNumber = a.document.companyNumber;
    const bCompanyNumber = b.document.companyNumber;

    const aScore = scores[aCompanyNumber];
    const bScore = scores[bCompanyNumber];

    return aScore > bScore ? 1 : -1;
};

const Relevancy = ({value, scores, highestScore}) => {
    const {companyNumber} = value.document;
    const score = scores[companyNumber];

    return <ProgressBar value={score} max={highestScore}/>
}

const columns = ({scores, highestScore}) => [
    cellAcc('Company', d => d, props => <Company {...props}/>, 'company', 'text-left', {
        className: "company-tr"
    }),
    cellAcc('Relevancy', d => d, props => <Relevancy {...props} scores={scores} highestScore={highestScore}/>, 'relevancy', 'text-left', {
        sortMethod: scoreSortFn(scores),
        maxWidth: 200,
        className: "relevancy-tr"
    })
];

const SubComponent = (props) => {
    const {viewIndex, row} = props;
    const even = viewIndex % 2 === 0;
    const className = `rt-tr ${even ? "-odd" : ""}`;
    return (
        <div className={className}>
            <CompanyCard company={row.company}/>
        </div>
    );
}

class CompaniesResultsTable extends Component {
    render() {
        const {companies, highestScore, scores} = this.props;

        return (
            <ReactTableContainer>
                <AdvancedExpandReactTable
                    data={companies}
                    expandAll={true}
                    columns={columns({highestScore, scores})}
                    showPagination={true}
                    defaultPageSize={10}
                    className="-striped"
                    filterable={false}
                    defaultFilterMethod={fuzzyFilter}
                    defaultSortDesc={true}
                    defaultSorted={[{
                        id   : 'relevancy',
                        desc : true,
                      }]}
                    SubComponent = {SubComponent}
                />
            </ReactTableContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    companies: selector.companies(state),
    data: selector.data(state),
    highestScore: selector.highestScore(state),
    scores: selector.companyScores(state)
})

export default connect(mapStateToProps)(CompaniesResultsTable);