import React, { useState } from 'react';
import {Row, Col, BootstrapContainer, LoctaButton} from '../../bootstrap';
import ResultsGrid from './results-grid';

import LinkedSearchModal from '../../modals/linked-search-modal';
import EmailCouncilModal from '../../modals/email-council';
import AddToProfileModal from '../../modals/add-to-profile';
import AddToJournalModal from '../../modals/add-to-journal';

const ResultsDetail = ({ detail, searchType, hasLinkedSearch, searchedValues }) => {
    const [showLinkedSearchModal, setShowLinkedSearchModal] = useState(false);
    const [showEmailCouncilModal, setShowEmailCouncilModal] = useState(false);
    const [showAddToProfileModal, setShowAddToProfileModal] = useState(false);
    const [showAddToJournalModal, setShowAddToJournalModal] = useState(false);

    const borderColor = detail.isOpenRecord ? "#5ab45a" : "#f0f0f0";
    const borderStyle = { borderLeft: `6px solid ${borderColor}` };

    const linkedSearch = (
        hasLinkedSearch
            ? <LoctaButton icon="fas fa-plus-circle" textComponent={(<>linked<br />searches</>)} onClick={() => setShowLinkedSearchModal(true)} className="mb-3" />
            : null
    );

    const linkedSearchModal = showLinkedSearchModal ? <LinkedSearchModal onClose={() => setShowLinkedSearchModal(false)} searchType={searchType} searchedValues={searchedValues} result={detail} /> : null;
    const emailCouncilModal = showEmailCouncilModal ? <EmailCouncilModal onClose={() => setShowEmailCouncilModal(false)} searchType={searchType} result={detail} /> : null;
    const addToProfileModal = showAddToProfileModal ? <AddToProfileModal onClose={() => setShowAddToProfileModal(false)} searchType={searchType} result={detail} /> : null;
    const addToJournalModal = showAddToJournalModal ? <AddToJournalModal onClose={() => setShowAddToJournalModal(false)} searchType={searchType} result={detail} /> : null;

    return (
        <BootstrapContainer className="container-fluid result">
            <Row className="mt-3 mb-3 pl-3" style={borderStyle}>
                <Col size={10} className="lo_sensitive">
                    <ResultsGrid data={detail} />
                </Col>
                <Col size={2}>
                    {linkedSearch}
                    <LoctaButton icon="fas fa-envelope" textComponent={(<>email<br />council</>)} onClick={() => setShowEmailCouncilModal(true)} className="mb-3" />
                    <LoctaButton icon="fas fa-user-plus" textComponent={(<>add to<br />profile</>)} onClick={() => setShowAddToProfileModal(true)} className="mb-3" />
                    <LoctaButton icon="fas fa-book-medical" textComponent={(<>add to<br />journal</>)} onClick={() => setShowAddToJournalModal(true)} className="mb-3" />
                </Col>
            </Row>
            { linkedSearchModal}
            { emailCouncilModal}
            { addToProfileModal}
            { addToJournalModal }
        </BootstrapContainer>
    );
}

export default ResultsDetail;
