import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux-actions/superuser-actions';
import {selector} from '../../../redux-reducers/users-reducer';
import Table from './table';
import ModalRoot from '../../modals/modal-root';
import {BootstrapContainer} from '../../bootstrap';

class Users extends Component {
    render() {
        return (
            <BootstrapContainer>
                <div className="container-fluid p-0 pt-2">
                    <Table users={this.props.users}/>
                </div>
                <ModalRoot/>
            </BootstrapContainer>
        );
    }

    componentDidMount() {
        this.props.queryUsers();
    }
}

const mapStateToProps = (state) => {
    return {
        users: selector.users(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        queryUsers: (query) => {
            dispatch(actions.fetchUsersData(query));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);