import React from "react";
import { Row, Col } from "../../bootstrap";
import {Footer, Logo} from './extra';

const EmbedIframe = () => (
  <iframe
    title="kibana.loctahub.co.uk"
    src="https://logs.locta.co.uk/s/locta/app/kibana#/dashboard/5bece580-bd1b-11ea-8be8-b5421cc6cca9?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1d%2Fd%2Cto%3Anow))"
    style={{ width: "100%", height: "100%" }}
  />
);
const LogsDashboard = () => {
  return (
    <>
      <Row className="d-flex h-100">
        <Row className="w-100">
          <Col size={4}>
            <Logo />
          </Col>
          <Col
            size={8}
            className="d-flex justify-content-center align-items-center"
          >
            <h2>Loctahub.co.uk Logs dashboard for today</h2>
          </Col>
        </Row>
        <EmbedIframe />
        <Footer />
      </Row>
    </>
  );
};

export default LogsDashboard;
