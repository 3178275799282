import moment from 'moment';

Date.loctaFormFormat = (date) => {
    if(date){
        return moment(date).format('YYYY-MM-DD HH:mm');
    }
    return "";
};

Date.loctaFormat8 = (date) => {
    if(date){
        return moment(date,'YYYYMMDD').format('DD-MM-YYYY');
    }
    return "N/A";
};

Date.parseFromUkStringFormat = (date) => {

    let m = moment(date, "DD/MM/YYYY");
    return m.isValid() ? m._d : null;
}

Date.parseForReact = (date) => {

    if (!date)
        return null;
    
    let m = moment(date);
    return m.isValid() ? m._d : null;
}


Date.formatForServer = (date) => date ? removeTimezoneOffset(date) : null;

const removeTimezoneOffset = (date) => {
    var result = new Date(date.getTime());
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}