import React from 'react';
import BTable from 'react-bootstrap/Table';
import { useTable, useGlobalFilter, useFilters } from 'react-table';
import { DefaultColumnFilter } from '../bootstrap/table-filters';
import FilterTypesFn  from './filter-types';

export default (columns) => ({ data }) => {
    const dataMemo = React.useMemo(() => data, [data]);
    const columnsMemo = React.useMemo(() => columns, []);

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = FilterTypesFn();

    const tableInstance = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            defaultColumn,
            filterTypes
        },
        useFilters,
        useGlobalFilter
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <>
            <div className="container-fluid p-0 mt-2 mb-2" style={{ overflow: "auto", maxHeight: "40vh" }}>
                <BTable striped bordered hover bsPrefix='b4-table' size="sm" {...getTableProps()}>
                <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="align-top">
                                        {column.render('Header')}
                                        {/* Render the columns filter UI */}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} style={{height:'40px'}}>
                                                <div className='d-flex align-items-center'>
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
            </div>
        </>
    );
};