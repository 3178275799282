const hasValue = value => (value || typeof value === 'number')

export default (values) => {

    const errors = {}

    if (!hasValue(values.alertName)) {
        errors.alertName = 'Please enter an alert name.'
    }

    return errors;
};