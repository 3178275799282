import React from 'react';
import ReactTable from 'react-table-v6';
import {stringAcc, cellAcc, dateAcc8, fuzzyFilter} from '../table';
import {ReactTableContainer} from '../bootstrap'

// CouncilID: 3539
// CouncilName: "Trafford"
// DateCompleted: "/Date(1549557707860)/"
// DateCreated: "/Date(1549557707817)/"
// ID: 20070
// NewMatchesFound: 0
// ReportEndDate: 20190207
// ReportStartDate: 20190207

const Download = (props) => {
    const {id} = props.value;
    return (
        <div>
            <a href={`/api/sbr-reports/download/${id}`} target="_blank" rel="noopener noreferrer">Download Report</a>
        </div>
    );
};

const columnsDefault = [
    stringAcc('Id', 'id', 'id', 'text-left'),
    dateAcc8('Start Date', d => d.reportStartDate, 'startDate', 'text-left'),
    dateAcc8('End Date', d => d.reportEndDate, 'endDate', 'text-left'),
    stringAcc('New Matches', 'newMatchesFound', 'newMatches', 'text-left'),
    cellAcc("Download", d => d, props => <Download {...props}/>, 'download', 'text-left')
];

let columnsSU = [...columnsDefault];
columnsSU.push(stringAcc('Council Name', 'councilName', 'councilName', 'text-left'))

const Table = ({data, isSU}) => {
    if(typeof(data) === 'undefined' || data === null || data.length === 0){
        return <div>Loading...</div>
    }

    return (
        <ReactTableContainer>
            <ReactTable
                data={data}
                columns={isSU ? columnsSU : columnsDefault}
                defaultPageSize={5}
                filterable={true}
                defaultFilterMethod={fuzzyFilter}
            />
        </ReactTableContainer>
    );
};

export default Table;