import React from 'react';
import { Field, Form } from 'react-final-form';

import { LoctaButton, SecondaryButton } from '../../bootstrap';
import { renderInput } from './inputs';

import validate from './validate';


const MyForm = ({handleSubmit, onClose, values}) => {
    
    const { surname, nino, dateOfBirth } = values;

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <div>
                <span className="w-100 p-15 option-label">Alert Name</span>
                <div className="w-100 p-15">
                    <Field
                        name="alertName"
                        type="text"
                        render={renderInput}
                        tabIndex={1} />
                </div>
            </div>

            <div>
                <span className="w-100 p-15 option-label">Expiry</span>
                <div className="w-100 p-15">
                    <span className="form-value">6 months</span>
                </div>
            </div>

            <hr className="light-gray" />

            <div className="w-100 p-15">
                <h6>Search Details</h6>
                <div className="dl">
                    <div className="dt">Surname -</div>
                    <div className="dd">{surname}</div>
                    <div className="dt new-line">NINO -</div>
                    <div className="dd">{nino}</div>
                    <div className="dt new-line">Date Of Birth -</div>
                    <div className="dd">{dateOfBirth}</div>
                </div>
            </div>

            <hr className="light-gray" />

            <div className="button-row p-15">            
                <LoctaButton icon="fas fa-times-circle" ButtonFn={SecondaryButton} onClick={onClose} textComponent="cancel" className="mb-3" />
                <LoctaButton icon="far fa-calendar-plus" type="submit" textComponent="save" className="mb-3" />
            </div>

            <div className="alert alert-warning">
                <p>Once your NINO Alert has been submitted it will then search once a week for the next 6 months until it either finds a new result or it expires. &nbsp;If a new record is found or if the NINO Alert has expired you will be notified via email which will advise you to log in to view or resubmit your search.</p>
                <p>Your NINO Alerts can be viewed via the User Dashboard page on the main menu.</p>
            </div>
        </form>
    );
}

const AddNinoAlertForm = ({ onSubmit, onClose, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={MyForm}
            onClose={onClose}
        />
    );
}

export default AddNinoAlertForm;
