import axios from "axios";

export const LOAD_NINO_MATCH_SUMMARIES_DATA_FINISHED = 'LOAD_NINO_MATCH_SUMMARIES_DATA_FINISHED';
export const LOAD_NINO_MATCH_SUMMARIES_DATA_STARTED = 'LOAD_NINO_MATCH_SUMMARIES_DATA_STARTED';

const url = '/api/nino-match/summaries';

const action = (type, data) => ({
    type, data
})

const loadSummaries = () => {
    return dispatch => {
        axios.get(url).then(res => {
            dispatch(action(LOAD_NINO_MATCH_SUMMARIES_DATA_FINISHED, res.data))
        });
    }
}

export const startLoadingSummaries = () => {
    return dispatch => {
        dispatch(action(LOAD_NINO_MATCH_SUMMARIES_DATA_STARTED));
        dispatch(loadSummaries());
    }
}