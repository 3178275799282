import React, { Component } from 'react';
import {connect} from 'react-redux';
import {hideModal, showModal} from '../../../redux-actions/modal-actions';
import ManageUserModal from './manageUserModal';
import {ModalSizes} from '../../modals/index'

class ManageUser extends Component {
    constructor(props){
        super(props);
        this.openUserManageModal = this.openUserManageModal.bind(this);
    }

    openUserManageModal() {
        this.props.showModal({
            open: true,
            title: 'Manage User',
            body: <ManageUserModal user={this.props.value}/>,
            closeModal: this.props.hideModal
          }, 'generic', ModalSizes.large)
    }

    render() {
        return (
            <div>
                <button onClick={this.openUserManageModal}>Manage</button>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({ 
    hideModal: () => dispatch(hideModal()),
    showModal: (modalProps, modalType, modalSize) => {
        dispatch(showModal({ modalProps, modalType, modalSize }))
    }
});

export default connect(null, mapDispatchToProps)(ManageUser);