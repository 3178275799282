import * as Actions from '../redux-actions/sbr-actions';

const initialState = {
    summaries: [],
    summariesV2: [],
    loading: false
};


export default function reducer(state = initialState, action = { type: 'NOT_SET' }) {
    switch (action.type) {
        case Actions.LOAD_SBR_SUMMARIES_DATA_STARTED:
            return {...state, summaries: [], loading: true};
        case Actions.LOAD_SBR_SUMMARIES_DATA_FINISHED:
            return {...state, summaries: action.data, loading:false};
        case Actions.LOAD_SBRV2_SUMMARIES_DATA_STARTED:
            return {...state, summariesV2: [], loading: true};
        case Actions.LOAD_SBRV2_SUMMARIES_DATA_FINISHED:
            return {...state, summariesV2: action.data, loading:false};
        default:
            return state;
    }
}

export const selector = {
    getSummaries: (state) => state.sbr.summaries,
    getSummariesV2: (state) => state.sbr.summariesV2,
    isLoading: (state) => state.sbr.loading
};