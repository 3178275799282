import axios from "axios";
import { FORM_ERROR } from 'final-form';
import hash from 'object-hash';

class BaseSearchApi {
    constructor(urls){
        this.urls = urls;
        this.localCache = {};
    }

    withCache = (fnWrap, keyPrefix = '') => async (values) => {
        const valueHash = `${keyPrefix}-${hash(values)}`;
        if(valueHash in this.localCache){
            return this.localCache[valueHash];
        }

        const result = await fnWrap(values);
        this.localCache[valueHash] = result;
        return result;
    }

    baseValidate = async values => {
        const response = await axios.post(this.urls.validate, values);
        let data = response.data;
        const hasValidateErrors = data.IsInvalid;
        if(hasValidateErrors){
            data = {
                ...data,
                FieldErrors: {
                    ...data.FieldErrors,
                    [FORM_ERROR]: data.Message
                }
            };
        }

        return data;
    }

    validate = async values => {
        const fn = this.withCache(this.baseValidate, "validate");
        return await fn(values);
    }

    baseSearch = async values => {
        const response = await axios.post(this.urls.search, values);
        return response.data;
    }

    search = async values => {
        const fn = this.withCache(this.baseSearch, "search");
        return await fn(values);
    }
}

export default BaseSearchApi;