import React from 'react';
import { Row, Col, Hr } from '../../bootstrap';
import { Field } from '../field';
import OccupantsView from './occupants';

import { isCaseInsensitiveMatch, isSameDate } from "../../../services/compare";


const highlight = { backgroundColor: 'yellow' };

export default ({ data, searchedValues = null }) => {
    const { 
        id,
        claimRef,
        persRef,
        forename,
        surname,
        startDate,
        endDate,
        claimType,
        claimantsTelephoneNumber,
        claimantsEmail,
        live,
        nino,
        dob,
        // claimantsGender,
        claimantsDod,
        claimantNonDependantStatus,
        dwpBenefit,
        studentIndicator,
        landlordTitle,
        landlordName,
        landlordSurname,
        propertyAgentName,
        address,
        occupants
    } = data;

    let surnameStyles, dateOfBirthStyles, ninoStyles;


    if(searchedValues){
        surnameStyles = isCaseInsensitiveMatch(searchedValues.surname, surname) ? highlight : {}
        dateOfBirthStyles = isSameDate(searchedValues.dateOfBirth, dob) ? highlight : {}
        ninoStyles = isCaseInsensitiveMatch(searchedValues.nino, nino) ? highlight : {}
    }
    

    return (
        <>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Person Reference" value={ persRef } />
                </Col>
                <Col size={4}>
                    <Field label="Forename" value={ forename } />
                </Col>
                <Col size={4}>
                    <Field label="Surname" value={surname} style={surnameStyles} />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Claim Reference" value={ claimRef } />
                </Col>
                <Col size={4}>
                    <Field label="Start Date" value={ startDate } />
                </Col>
                <Col size={4}>
                    <Field label="End Date" value={ endDate } />
                    
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Claim Type" value={ claimType } />
                </Col>
                <Col size={4}>
                    <Field label="Status" value={ live } />
                </Col>
                <Col size={4}>
                    <Field label="DWP Benefit" value={ dwpBenefit } />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="NINO" value={ nino } style={ninoStyles} />
                </Col>
                <Col size={4}>
                    <Field label="DOB" value={ dob } style={dateOfBirthStyles} />
                </Col>
                <Col size={4}>
                    {/* 
                    <Field label="Gender" value={ claimantsGender } /> 
                    https://trello.com/c/d9jxIUuq/1296-locta-benefits-search-results-need-to-hide-the-gender-field-across-all-results-also-need-to-hide-any-value-that-references-uc-un
                    */}
                </Col>
            </Row>
            <Hr className="dashed" />
            <Row className="pb-3">
                <Field label="Address" value={ address } />
            </Row>
            <OccupantsView data={{ occupants, resultId: id }} />
            <Hr className="dashed" />
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Claimants Telephone Number" value={ claimantsTelephoneNumber } />
                </Col>
                <Col size={8}>
                    <Field label="Claimants Email" value={ claimantsEmail } />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Claimants Date Of Death" value={ claimantsDod } />
                </Col>
                <Col size={4}>
                    <Field label="Claimant Non Dependant Status" value={ claimantNonDependantStatus } />
                </Col>
                <Col size={4}>
                    <Field label="Student Award indicator" value={ studentIndicator } />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Landlord" value={ `${landlordTitle} ${landlordName} ${landlordSurname}` } />&nbsp;
                </Col>
                <Col size={8}>
                    <Field label="Property Agent" value={ propertyAgentName } />
                </Col>
            </Row>
        </>
    );
};