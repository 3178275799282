import React from 'react';
import { LoctaButton } from '../../../bootstrap';

export default ({searchType, searchedValues, results, selectedCouncil, onCouncilChange, selectedSummaryDetails}) => (

    <form autoComplete="off" target="_blank" method="post" action="/FurtherActions/ExportToPrintSelectedCouncil">
        <div className="row-fluid content">
            <div className="span12 center-text">
                <span className="option-label">Select Council -</span>
                <select className="selectcouncil" name="SelectedCouncils" value={selectedCouncil} onChange={onCouncilChange}>
                    <option value="0">Please Select</option>
                    { results.map(summary => <option value={summary.dataProviderId} key={summary.dataProviderId}>{summary.councilName}</option>) }
                </select>
                <div style={{display: 'inline-block', marginLeft: '30px', width: '140px'}}>
                    <LoctaButton icon="fas fa-play-circle" type="submit" textComponent={(<>PDF / Print<br/>Council</>)} disabled={selectedCouncil === 0} />
                </div>

                <input type="hidden" name="SearchType" value={searchType} />
                <input type="hidden" name="IsLoctaPlusSearch" value={searchedValues.isLoctaPlusSearch} />
                <input type="hidden" name="IsLinkedSearch" value={searchedValues.isLinkedSearch} />
                <input type="hidden" name="IsQuickSearch" value={searchedValues.isQuickSearch} />
                
                { selectedSummaryDetails.map(detail => <input type="hidden" name="SelectedRecords" value={detail.id} key={detail.id} />) }

            </div>
        </div>
    </form>
);