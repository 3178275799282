import React from 'react';

const ErrorMessage = ({text}) => (
    <div style={{color: "#f00"}}>{text}</div>
)

export const renderInput = ({input, placeholder, type, meta: { touched, error }}) => (
    <>
        <input
            {...input}
            type={type}
            placeholder={placeholder}
            className={
                "input-block-level bold" +
                (touched && error ? " invalid-search-input" : "")
            }
        />
        {touched && error ? <ErrorMessage text={error} /> : null}
    </>
);

export const renderSelect = ({input, children, meta: { touched, error, submitError }}) => (
    <>
        <select
            {...input}
            className={
                "input-block-level uppercase bold" +
                (touched && (error || submitError) ? " invalid-search-input" : "")
            }
        >
            { children }
        </select>
        {touched && (error || submitError) ? <ErrorMessage text={error} /> : null}
    </>
);
