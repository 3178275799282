import axios from 'axios';
import { initialize } from 'redux-form';
import { selector } from '../redux-reducers/quick-search-alerts-reducer';

export const formName = 'QuickSearchAddAlert';

export const SET_ALERT_FORM_INITIAL_VALUES = 'SET_ALERT_FORM_INITIAL_VALUES';
export const SET_ALERT_SUMMARY = 'SET_ALERT_SUMMARY';
export const REMOVE_ALERT_SUMMARY = 'REMOVE_ALERT_SUMMARY';
export const ADD_ALERT_FAIL = 'ADD_ALERT_FAIL';

const urls = {
    apiUrl: '/api/alerts/quick-search'
}

const action = (type, data = null) => ({
    type, data
});

export const initialiseQuickSearchAlerts = ({query,data}) => {
    return (dispatch) => {
        let initialValues = {
            query,
            hitCount: data.AlertHitCount
        };

        dispatch(initialize(formName, initialValues, true));
        dispatch(action(SET_ALERT_FORM_INITIAL_VALUES, initialValues));
        
        if (data.AlertSummary) {
            dispatch(action(SET_ALERT_SUMMARY, data.AlertSummary));
        }
    }
}

export const submitAlert = (values) => {
    return async dispatch => {
        try{
            var request = await axios.post(urls.apiUrl, values);
            dispatch(action(SET_ALERT_SUMMARY, request.data));
        }
        catch(err){
            dispatch(action(ADD_ALERT_FAIL));
        }
        
    }
}

export const removeAlert = (alertId) => {
    return async (dispatch, getState) => {
        try{
            let url = `${urls.apiUrl}/${alertId}`;
            var isSuccess = await axios.delete(url);
            if (isSuccess) {
                dispatch(action(REMOVE_ALERT_SUMMARY));
                
                let initialFormValues = selector.initialFormValues(getState());
                dispatch(initialize(formName, initialFormValues, true));
            }
        }
        catch(err){
            dispatch(action(ADD_ALERT_FAIL));
        }
        
    }
}
