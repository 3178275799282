import React, {useEffect} from 'react';
import BTable from 'react-bootstrap/Table';
import { useTable, useRowSelect, useGlobalFilter, useFilters } from 'react-table';
import { DefaultColumnFilter } from '../../bootstrap/table-filters';
import IndeterminateCheckbox from '../../react-table-extras/indeterminate-checkbox';
import FilterTypesFn  from '../../react-table-extras/filter-types';
import './index.css';


const columns = [
    {
        Header: 'Forename',
        accessor: 'forename',
    },
    {
        Header: 'Surname',
        accessor: 'surname',
    },
    {
        Header: 'Address',
        accessor: 'address',
    },
    {
        Header: 'Open Record',
        accessor: 'isOpenRecordFormatted'
    }
];

const StagingTable = ({ data, setSelectedFn }) => {
    const dataMemo = React.useMemo(() => data, [data]);
    const columnsMemo = React.useMemo(() => columns, []);

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = FilterTypesFn();

    const tableInstance = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            defaultColumn,
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds }
    } = tableInstance;

    useEffect(() => {
        var originals = selectedFlatRows.map(x => x.original);
        setSelectedFn(originals);
    }, [setSelectedFn, selectedFlatRows]);

    return (
        <>
            <div className="container-fluid p-0 mt-2 mb-2" style={{ overflow: "auto", maxHeight: "40vh" }}>
                <BTable striped bordered hover bsPrefix='b4-table' size="sm" {...getTableProps()} style={{minHeight:"40vh"}}>
                <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="align-top">
                                        {column.render('Header')}
                                        {/* Render the columns filter UI */}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={row.getToggleRowSelectedProps().onChange} className={`${row.original.isOpenRecord ? 'record-active' : 'record-inactive'}`} style={{height:"32px"}}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} style={{maxHeight: '32px'}}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
            </div>
            <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
            {Object.keys(selectedRowIds).length === 0 ? <p className="b4-text-danger" style={{fontSize:"18px", fontWeight:"bold"}}>Please select at least 1 row to continue</p> : null}
        </>
    );
}

export default StagingTable;