import React from 'react';
import {BootstrapContainer, BootstrapSpinner} from '../bootstrap';

const Loading = ({message}) => {
  return (
    <BootstrapContainer>
        <BootstrapSpinner className="pt-2 pb-2" loadingMessage={message}/>
    </BootstrapContainer>
  );
}

export default Loading;