import React, {useState, useEffect} from 'react';
import ClientForm from './client-form';
import axios from 'axios';
import {BootstrapSpinner} from '../../bootstrap';
import {useHistory} from 'react-router-dom';

const AddForm = () => {
    const [initialValues, setInitialValues] = useState(null);
    const [options, setOptions] = useState({
        councils: {},
        dataProviders: [],
        datasetOptions: []
    });
    const history = useHistory();

    useEffect(() => {
        axios.get("/api/clients/new").then(res => {
            setInitialValues(res.data.values);
            setOptions(res.data.options);
        });
    }, [])
    
    const onSubmit = async (values) => {
        let response = await axios.post("/api/clients", values);
        if(response.data.length === 0){
            history.goBack();
        }
        return response.data;
    };

    if(initialValues === null){
        return <BootstrapSpinner loadingMessage='Loading ...'/>
    }

    return <ClientForm initialValues={initialValues} options={options} onSubmit={onSubmit}/>;
};

export default AddForm;