import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuickSearchData } from '../../redux-actions/quick-search-actions'
import Loading from '../shared/Loading';
import QuickSearchResults from './results';
import QuickSearchError from './error';
import { selector } from '../../redux-reducers/quick-search-reducer';
import { BootstrapContainer, Row } from '../bootstrap';
import './index.scss';

const searchValues = window.location.search;
const QuickSearch = () => {
    const dispatch = useDispatch();

    const quickSearchError = useSelector(selector.selectError);
    const quickSearchValidation = useSelector(selector.selectValidationModel);

    useEffect(() => {
        dispatch(fetchQuickSearchData(searchValues))
    }, [dispatch]);

    let content = <Loading message="Loading..." />;;
    if (quickSearchError) {
        content = <QuickSearchError errorMessage="An error occurred ... please try searching again." />
    }

    if (quickSearchValidation && quickSearchValidation.isValidSearch) {
        content = <QuickSearchResults />
    }

    if (quickSearchValidation && quickSearchValidation.isValidSearch === false) {
        content = <QuickSearchError errorMessage="The search query is invalid. Please try a new search term. Valid terms: postcode, telephone, email." />
    }

    return (
        <>
            <div className="locta-react quick-search-container">
                <BootstrapContainer>
                    <Row>
                        <header className="block-header block-grey w-100">
                            <span className="icon-surround"><i className="fab fa-searchengin quick-search-icon"></i></span>
                            <h2>Quick Search</h2>
                        </header>
                    </Row>
                </BootstrapContainer>
                {content}
            </div>
        </>

    );
};

export default QuickSearch;