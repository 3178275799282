import React, {useState, useEffect} from "react";

const calculateAvailablePages = (currentPage, totalPages) => {
    var result = [];

    var maxPagesToShowOnPaging = 7;
    var itemsBeforeCurrentPage = currentPage - 1;
    var itemsAfterCurrentPage = totalPages - currentPage;
    var pageOverflow = maxPagesToShowOnPaging - 1 - itemsBeforeCurrentPage - itemsAfterCurrentPage;

    // do we have more pages than available spaces in the pagination control?
    if (pageOverflow < 0) {
        pageOverflow = pageOverflow * -1;

        for (var i = 0; i < pageOverflow; i++) {
            if (itemsAfterCurrentPage >= itemsBeforeCurrentPage) {
                itemsAfterCurrentPage--;
            } else {
                itemsBeforeCurrentPage--;
            }
        }
    }

    var startPage = currentPage - itemsBeforeCurrentPage;
    var endPage = currentPage + itemsAfterCurrentPage;

    for (var p = startPage; p <= endPage; p++) {
        result.push(p);
    }

    return result;
};

const Pagination = ({page, pages, onPageChange, pageSize, pageSizeOptions, onPageSizeChange}) => {
    const [visiblePages, setVisiblePages] = useState(calculateAvailablePages((page + 1), pages));

    useEffect(() => {
        setVisiblePages(calculateAvailablePages((page + 1), pages));
    }, [page, pages]);

    const changePage = (page) => {
        const activePage = page + 1;

        if (page === activePage) {
            return;
        }

        setVisiblePages(calculateAvailablePages(page, pages));

        onPageChange(page - 1);
    }

  
    const onFirstPageClick = () => {
        let activePage = page + 1;
        if (activePage === 1) return;
        changePage(0);
    }

    const onPreviousPageClick = () => {
        let activePage = page + 1;
        if (activePage === 1) return;
        changePage(activePage - 1);
    }

    const onNextPageClick = () => {
        let activePage = page + 1;
        if (activePage === pages) return;
        changePage(activePage + 1);
    }

    const onLastPageClick = () => {
        let activePage = page + 1;
        if (activePage === pages) return;
        changePage(pages);
    }

    const activePage = page + 1;

    return (
        <div className="result-options clear hide-on-print">
            <div className="results-per-page">
                <span>Results per page:</span>
                <select className="itemsPerPage" onChange={e => onPageSizeChange(Number(e.target.value))} value={pageSize}>
                    { pageSizeOptions.map((option, i) => <option key={i} value={option}>{option}</option>)}
                </select>
            </div>
            <nav className="rt-pagination">
                <ul>
                    <li className={activePage === 1 ? "disabled" : ""}>
                        <button onClick={onFirstPageClick} disabled={activePage === 1}>&lt;&lt;</button>
                    </li>
                    <li className={activePage === 1 ? "disabled" : ""}>
                        <button onClick={onPreviousPageClick} disabled={activePage === 1}>&lt;</button>
                    </li>

                    {
                        visiblePages.map((pageNumber) => (
                            <li className={activePage === pageNumber ? "active" : ""} key={pageNumber}>
                                <button onClick={() => changePage(pageNumber)}>
                                    {pageNumber}
                                </button>
                            </li>
                        ))
                    }  

                    <li className={activePage === pages ? "disabled" : ""}>
                        <button onClick={onNextPageClick} disabled={activePage === pages}>&gt;</button>
                    </li>
                    <li className={activePage === pages ? "disabled" : ""}>
                        <button onClick={onLastPageClick} disabled={activePage === pages}>&gt;&gt;</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Pagination;