import React from 'react';

const BenefitsOptions = ({records}) => (
    <>
        {records.map(detail => <option value={detail.id} key={detail.id}>{`${detail.surname} - ${detail.nino} - ${detail.dob}`}</option>)}
    </>
)

const BusinessRatesOptions = ({records}) => (
    <>
        {records.map(detail => <option value={detail.id} key={detail.id}>{`${detail.chargePayer} - ${detail.address}`}</option>)}
    </>
)

const CouncilTaxAddressOptions = ({records}) => (
    <>
        {records.map(detail => <option value={detail.id} key={detail.id}>{`${detail.forename} ${detail.surname} - ${detail.address}`}</option>)}
    </>
)

const CouncilTaxNameOptions = ({records}) => (
    <>
        {records.map(detail => <option value={detail.id} key={detail.id}>{`${detail.forename} ${detail.surname} - ${detail.address}`}</option>)}
    </>
)

const TelephoneDirectoryOptions = ({records}) => (
    <>
        {records.map(detail => <option value={detail.id} key={detail.id}>{`${detail.name} - ${detail.telephone} - ${detail.address}`}</option>)}
    </>
)

const VotersRollOptions = ({records}) => (
    <>
        {records.map(detail => <option value={detail.id} key={detail.id}>{`${detail.forename} ${detail.surname} - ${detail.address}`}</option>)}
    </>
)

export default (searchType, records) => {

    switch (searchType) {

        case "Benefits":
            return <BenefitsOptions records={records} />;

        case "BusinessRates":
            return <BusinessRatesOptions records={records} />;

        case "CouncilTaxAddress":
            return <CouncilTaxAddressOptions records={records} />;

        case "CouncilTaxName":
            return <CouncilTaxNameOptions records={records} />;

        case "Directory":
            return <TelephoneDirectoryOptions records={records} />;

        case "VotersRoll":
            return <VotersRollOptions records={records} />;

        default:
            return null
    }
}
