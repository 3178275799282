import React, { useState, useCallback } from 'react';
import { initialValues, isAutoSearch } from './server-values';
import api from './api';

import SearchFields from './search-fields';
import ResultsSummaries from '../../searches-results/council-tax/results-summaries';
import NoResults from '../no-results';
import Loading from '../loading';

const CouncilTaxNameSearch = () => {

    const [searchedValues, setSearchedValues] = useState(initialValues);
    const [results, setResults] = useState([]);
    const [searchDone, setSearchDone] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const onFormSubmit = useCallback(async searchValues => {

        setIsLoading(true);

        var serverValues = {
            ...searchValues,
            searchStartDate: Date.formatForServer(searchValues.searchStartDate),
            searchEndDate: Date.formatForServer(searchValues.searchEndDate),
        }

        const validate = await api.validate(serverValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors) {
            setResults([]);
            setSearchDone(false);
            setIsLoading(false);
            return validate.fieldErrors;
        }
        else {

            try {
                const search = await api.search(serverValues);
                setResults(search || []);
                setSearchedValues(searchValues);
            }
            catch (err) {
                setResults([]);
            }

            setIsLoading(false);
            setSearchDone(true);
        }

    }, [setResults, setIsLoading, setSearchDone]);


    const onFormReset = useCallback(form => (_) => {

        form.reset();

        setSearchedValues(initialValues);
        setIsLoading(false);
        setSearchDone(false);

    }, [setSearchDone, setSearchedValues, setIsLoading]);


    const hasResults = results.length > 0;
    const hasLinkedSearch = searchedValues.secondLinkedSearch === '' && !searchedValues.isQuickSearch;
    
    const content = isLoading 
        ? <Loading message="Searching data ..." /> 
        : !searchDone 
            ? null 
            : hasResults 
                ? <ResultsSummaries searchedValues={searchedValues} searchType={"CouncilTaxName"} hasLinkedSearch={hasLinkedSearch} results={results} /> 
                : <NoResults />;

    return (
        <section className="span12 search-container searches ctax search-by-distance">
            <header className="block-header block-grey rt">
                <span className="icon-surround"><i className="council-tax-name-small"></i></span>
                <h2>Council Tax Name</h2>
                <span className="search-info-link remove-bold" title="Please enter: &#013; - Surname + Forename or Blank Forename check, + Start Date required. &#013; - Forename/Surname fields require a minimum of 2 characters, and can be used with the wildcard character * (asterisk). &#013; - Tick the Blank Forename box to search specifically for records where they have no forename recorded &#013; - Enter a Move in Date field to search between that date and today's date &#013; - Enter a Move in Date range to search between 2 specific dates &#013; - The default search is a National search. &#013; - Use the Search by Distance filter to search results from the selected council within a mileage area." href="#">(?)</span>
            </header>
            <SearchFields onSubmit={onFormSubmit} onReset={onFormReset} initialValues={initialValues} isAutoSearch={isAutoSearch} searchDone={searchDone}/>

            <div id="spacer" style={{ height: "0px", clear: "both" }}></div>

            {content}
        </section>
    );
}

export default CouncilTaxNameSearch;
