import React from 'react';
import ResultsSummaries from '../../searches-results/voters-roll';
import { BootstrapSpinner, Row, BootstrapContainer } from '../../bootstrap';

const ResultsStep = ({searchDone, stagingSearchDone, results, searchedValues}) => {
    if(!searchDone) return null;
    const showLoading = !stagingSearchDone;

    if (showLoading) {
        return (
            <BootstrapContainer>
                <Row className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "40vh" }}>
                    <BootstrapSpinner className="pt-2 pb-2" loadingMessage='Fetching records ...'/>
                </Row>
            </BootstrapContainer>
        );
    }

    const noResults = results && results.length <= 0;
    if (noResults) {
        return (
            <BootstrapContainer>
                <Row className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "40vh" }}>
                    <p style={{fontSize:"18px", fontWeight:"bold"}}>
                        No results available.
                    </p>
                </Row>
            </BootstrapContainer>
        );
    }

    return <ResultsSummaries results={results} searchedValues={searchedValues} searchType="VotersRoll"/>;
}

export default ResultsStep;
