import React, { useEffect } from "react";
import { LoctaButton, PrimaryButton, SecondaryButton, BootstrapContainer } from '../../bootstrap';
import { TextInput } from '../../react-final-forms';
import { Form } from 'react-final-form';

const MyForm = ({ handleSubmit, submitting, searchDone, form, isAutoSearch, onReset}) => {
    const disableButtons = submitting || isAutoSearch;

    useEffect(() => {
        if (isAutoSearch && !searchDone) {
            form.submit();
        }
    }, [searchDone, form, isAutoSearch]);

    let tabIndex = 1;
    return (
        <>
            <div className={"locta-form block-grey rb " + (isAutoSearch ? "hidden" : "")}>
                <div className="inner">
                    <form className="form-search rf" autoComplete="off" onSubmit={handleSubmit}>

                        <ul className="inline-fields">
                            <li>
                                <TextInput name="surname" label="Surname" placeholder="Surname" tabIndex={tabIndex++} inLine={false}/>
                            </li>
                            <li className="or color-gray">
                                - OR -
                            </li>
                            <li>
                                <TextInput name="companyName" label="Company Name" placeholder="Company Name" tabIndex={tabIndex++} inLine={false}/>
                            </li>
                            <li>
                                <TextInput name="location" label="Location" placeholder="Location" tabIndex={tabIndex++} inLine={false}/>
                            </li>

                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton type="submit" small={true} className="h-100 mt-20" disabled={disableButtons} icon={submitting ? "fas fa-spinner" : "fas fa-play-circle"} ButtonFn={PrimaryButton} textComponent={<>Search</>} iconSize="16px" fontSize="11px" fontWeight="normal" />
                            </li>
                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton small={true} className="h-100 mt-20" icon="fas fa-sync" disabled={disableButtons} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset(form)}/>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            {isAutoSearch && submitting ? <div className="auto-search-loading">Loading...</div> : null}
        </>
    );
}


const SearchFields = ({ onSubmit, onReset, initialValues, ...rest }) => {
    return (
        <BootstrapContainer style={{ height: "100%" }}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                onReset={onReset}
                {...rest}
            >
                {MyForm}
            </Form>
        </BootstrapContainer>
    );
}

export default SearchFields;