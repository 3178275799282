import React, { useEffect } from 'react';
import BTable from 'react-bootstrap/Table';
import { useTable, useRowSelect, useGlobalFilter, useFilters } from 'react-table';
import { DefaultColumnFilter, fuzzyTextFilterFn } from '../../bootstrap/table-filters';
import { Row } from '../../bootstrap';

const columns = [
    {
        Header: 'Address',
        accessor: 'address'
    }
];

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
);

const StagingTable = ({ data, setSelectedFn }) => {
    const dataMemo = React.useMemo(() => data, [data]);
    const columnsMemo = React.useMemo(() => columns, []);

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const tableInstance = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
        },
        useFilters,
        useGlobalFilter,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state,
        // visibleColumns,
        // preGlobalFilteredRows,
        // setGlobalFilter,
    } = tableInstance;

    const { selectedRowIds } = state;

    useEffect(() => {
        var originals = selectedFlatRows.map(x => x.original);
        setSelectedFn(originals);
    }, [setSelectedFn, selectedFlatRows]);

    return (
        <div className="d-flex flex-column justify-content-start align-items-center w-100" style={{ overflow: "auto", maxHeight: "40vh", minHeight:"40vh" }}>
            <Row className="container-fluid p-0 mt-2 mb-2" style={{ overflow: "auto", maxHeight: "40vh" }}>
                <BTable striped bordered hover bsPrefix='b4-table' size="sm" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="align-top">
                                        {column.render('Header')}
                                        {/* Render the columns filter UI */}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* Global Filter Maybe */}
                        {/* <tr>
                            <th
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: 'left',
                                }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </th>
                        </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={row.getToggleRowSelectedProps().onChange} className={`${row.original.isOpenRecord ? 'record-active' : 'record-inactive'}`} style={{ height: "32px" }}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} className="row-cell">
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
            </Row>
            <Row>
                <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
            </Row>
            <Row>
                {Object.keys(selectedRowIds).length === 0 ? <p className="b4-text-danger" style={{ fontSize: "18px", fontWeight: "bold" }}>Please select at least 1 row to continue</p> : null}
            </Row>
        </div>
    );
}

export default StagingTable;