import React from "react";
import { councils } from './server-values';
import sliderConfig from '../config';
import { BootstrapContainer, Row } from '../../bootstrap';
import { Form } from 'react-final-form';
import { searchFormName } from './config';
import { TextInput, DateInput, CheckboxInput, HiddenInput, SelectInput, RangeInput, PostcodeInput } from '../../react-final-forms';
import Loading from '../loading';

const containerStyles = { border: 0, borderBottom: "1px solid rgba(0, 0, 0, .1)", marginTop: "10px" };

let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
councilOptions.sort((a, b) => a.props.children.localeCompare(b.props.children));

const MyForm = ({ handleSubmit, values, form, searchForm }) => {
    const maxSearchDistanceInt = parseInt(values.maxSearchDistance || 0);
    let tabIndex = 1;
    searchForm.current = form;

    return (
        <div className={"locta-form block-grey rb rt"} style={containerStyles}>
            <div className="inner">
                <form id={searchFormName} className="form-search rf" autoComplete="off" onSubmit={handleSubmit} >
                    <ul className="inline-fields mb-0">
                        <li>
                            <TextInput name="companyName" label="Company Name" placeholder="Person/Company Name" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <DateInput name="searchStartDate" label="Search Start" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <DateInput name="searchEndDate" label="Search End" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <CheckboxInput name="isLive" label="Live Cases Only" tabIndex={tabIndex++} inLine={false} />
                        </li>
                    </ul>
                    <div>
                        <PostcodeInput name="postcode" label="Postcode" tabIndex={tabIndex++} inLine={false} />
                    </div>
                    <div className="pt-3">
                        <span className="font-weight-bold">By Distance from Council</span>
                        <Row className="inline-fields d-flex align-items-center justify-content-start w-50 pt-2">
                            <div >
                                <SelectInput name="selectedCouncilId" tabIndex={tabIndex++} inLine={false}>
                                    <option value="0">Current Council</option>
                                    {councilOptions}
                                </SelectInput>
                            </div>
                            <div className="pl-2">
                                <RangeInput name="maxSearchDistance" inLine={false} inputClassName="white" min={sliderConfig.sliderMinSearchDistance} max={sliderConfig.sliderMaxSearchDistance} step={sliderConfig.sliderDistanceStep} tabIndex={tabIndex++} />
                            </div>
                            <div className="pl-1">
                                <span style={{ lineHeight: '30px' }}>{maxSearchDistanceInt >= 640 ? 'National' : `${maxSearchDistanceInt} miles`}</span>
                            </div>
                        </Row>
                    </div>
                    <div className="pt-3">
                        <span className="font-weight-bold">Only return companies:</span>
                    </div>
                    <Row className="inline-fields d-flex align-items-center justify-content-start w-50 mt-0">
                        <CheckboxInput name="hasSbr" label="Claiming SBRR/SBBS" labelClassName="m-0 font-weight-normal colour-navy-blue text-nowrap text-nowrap pl-2 mt-1" tabIndex={tabIndex++} inLine={true} inverse={true} sizeLabel={11} sizeInput={1} />
                    </Row>
                    <Row className="inline-fields d-flex align-items-center justify-content-start w-50 mt-0">
                        <CheckboxInput name="hasMixedHereditament" label="With Mixed Hereditament" labelClassName="m-0 font-weight-normal colour-navy-blue text-nowrap pl-1 mt-1" tabIndex={tabIndex++} inLine={true} inverse={true} sizeLabel={11} sizeInput={1} />
                    </Row>
                </form>
            </div>
        </div>
    );
}


const SearchFields = ({ onSubmit, initialValues, onReset, searchForm, isLoading, ...rest }) => {
    return (
        <BootstrapContainer style={{ height: "100%" }}>
            <div className={isLoading ? "" : "d-none"}>
                <Loading message="Searching data ..." />
            </div>
            <div className={isLoading ? "d-none" : ""}>
                <Form
                    onSubmit={onSubmit}
                    onReset={onReset}
                    initialValues={initialValues}
                    searchForm={searchForm}
                    {...rest}
                >
                    {MyForm}
                </Form>
            </div>
        </BootstrapContainer>
    );
}

export default SearchFields;