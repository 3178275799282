import React, { useState, useCallback } from 'react';
import SearchFields from './search-fields';
import ResultsSummaries from '../../searches-results/benefits/results-summaries';
import NoResults from '../no-results';
import { initialValues, isAutoSearch } from './server-values';
import api from './api';
import Loading from '../loading';

const BenefitsSearch = () => {

    const [searchedValues, setSearchedValues] = useState(initialValues);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchDone, setSearchDone] = useState(false);


    const onFormReset = useCallback(form => (_) => {

        form.reset();

        setSearchedValues(initialValues);
        setIsLoading(false);
        setSearchDone(false);

    }, [setSearchDone, setSearchedValues, setIsLoading]);


    const onFormSubmit = useCallback (async searchValues => {

        setIsLoading(true);

        var serverValues = {
            ...searchValues,
            dateOfBirth: Date.formatForServer(searchValues.dateOfBirth)
        }

        const validate = await api.validate(serverValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors){

            setResults([]);
            setSearchDone(false);
            setIsLoading(false);

            return validate.fieldErrors;

        }
        else {

            try {
                const search = await api.search(serverValues);
                setResults(search || []);
                setSearchedValues(searchValues);
            }
            catch (err) {
                setResults([]);
            }

            setIsLoading(false);
            setSearchDone(true);
        }

    }, [setResults, setIsLoading, setSearchDone]);


    const hasResults = results.length > 0;
    const hasLinkedSearch = searchedValues.secondLinkedSearch === '' && !searchedValues.isQuickSearch;

    const content = isLoading 
        ? <Loading message="Searching data ..."/> 
        : !searchDone 
            ? null 
            : hasResults 
                ? <ResultsSummaries searchedValues={searchedValues} searchType={"Benefits"} hasLinkedSearch={hasLinkedSearch} results={results} /> 
                : <NoResults />;

    return (
        <>
            <section className="span12 search-container searches">
                <header className="block-header block-grey rt">
                    <span className="icon-surround"><i className="benefits-small"></i></span>
                    <h2>Benefits</h2>
                    <span className="search-info-link remove-bold" title="Searches may be made on specific combinations of the fields.&#013;Permitted searches are:&#013; - NINO only &#013; - NINO + Surname/Date Of Birth &#013; - Surname + Date Of Birth." href="#">(?)</span>
                </header>
                <SearchFields onSubmit={onFormSubmit} onReset={onFormReset} initialValues={initialValues} isAutoSearch={isAutoSearch} searchDone={searchDone}/>

                <div id="spacer" style={{ height: "0px", clear: "both" }}></div>

                {content}
            </section>
        </>
    );
}

export default BenefitsSearch;