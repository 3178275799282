import React from 'react';
import store from '../redux-stores/website-store';
import ResultsViewExport from '../react-components/export'
import Base from './base';
import SearchTypes from '../react-components/searches-results/search-types';

const Main = (props) => {
    const {resultasjson, viewtype} = props;
    let result = null;
    if(resultasjson) {
        result = JSON.parse(resultasjson);
    }
    else {
        return null;
    }

    let resultType;
    switch(viewtype) {
        case 'nndr':
            resultType = SearchTypes.BusinessRates;
            break;
        case 'ctax-name':
            resultType = SearchTypes.CouncilTaxName;
            break;
        case 'ctax-address':
            resultType = SearchTypes.CouncilTaxAddress;
            break;
        case 'benefits':
            resultType = SearchTypes.Benefits;
            break;
        case 'directory':
            resultType = SearchTypes.Directory;
            break;
        case 'votersroll':
            resultType = SearchTypes.VotersRoll;
            break;
        default:
            resultType = SearchTypes.None;
            break;
    }
    return <ResultsViewExport data={result} resultType={resultType}/>;
}

export default Base('react-results-view-export', store, Main);