import DomConfigRepository from '../../../repositories/domConfigRepository';

const sv = DomConfigRepository.getJsonConfigById('searchValues');
export const initialValues = {
    houseNameNumber: sv.houseNameNumber,
    street: sv.street,
    postcode: sv.postcode? sv.postcode.trim() : "",
    isLive: sv.isLive,
    
    selectedAddressIds: sv.selectedAddressIds,
    
    initialSearch: sv.initialSearch,
    linkedSearch: sv.linkedSearch,
    secondLinkedSearch: sv.secondLinkedSearch,

    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};

export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;