import React from 'react';
import { Row, Col } from '../../bootstrap';
import { Field } from '../field';


export default ({ data }) => {
    const { 
        name,
        address,
        telephone
    } = data;
    return (
        <>
            <Row className="pb-3">
                <Col size={6}>
                    <Field label="Name" value={ name } />
                </Col>
                <Col size={6}>
                    <Field label="Telephone" value={ telephone } />
                </Col>
            </Row>
            <Row className="pb-3">
                <Col size={12}>
                    <Field label="Address" value={ address } />
                </Col>
            </Row>
        </>
    );
};