import React, {useState} from 'react';

import ReactTable from 'react-table-v6';
import Pagination from '../pagination';
import columns from './results-columns';
import ResultsDetail from './results-detail';

import PrintModal from '../../modals/print';

const SubComponent = ({ searchType, searchedValues, hasLinkedSearch}) => (props) => {
    const {row} = props;
    return row.records.map(data => <ResultsDetail key={data.id} detail={data} searchType={searchType} searchedValues={searchedValues} hasLinkedSearch={hasLinkedSearch}/>);
}

const ResultsSummaries = ({searchedValues, searchType, hasLinkedSearch, results}) => {
    const [showExportToPrint, setShowExportToPrint] = useState(false);

    let exportToPrintModal = showExportToPrint ? <PrintModal onClose={() => setShowExportToPrint(false)} searchedValues={searchedValues} results={results} searchType={searchType} /> : null;

    return (
        <section className="search-results">
            <header className="block-header bg-blue-grad blue-header has-tabs" id="resultsHeader">
                <ul className="nav nav-tabs pull-left">
                    <li className="active"><a href="#all-results" data-toggle="tab">All Results</a></li>
                </ul>
                <ul className="nav option-tabs pull-right">
                    <li className="active pull-right">
                        <button onClick={() => setShowExportToPrint(true)}>PDF / Print</button>
                    </li>
                </ul>
            </header>

            <section className="results-group">
                <ReactTable
                    PaginationComponent={Pagination}
                    data={results}
                    expandAll={false}
                    columns={columns}
                    showPagination={true}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    defaultPageSize={10}
                    filterable={false}
                    minRows={0}
                    SubComponent={(props) => SubComponent({searchType, searchedValues, hasLinkedSearch})(props)}
                />
            </section>
            
            { exportToPrintModal }
        </section>
    );
}

export default ResultsSummaries;
