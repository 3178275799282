import React from 'react';
import { useDispatch } from 'react-redux';
import SearchForm from './search-form';
import SearchResults from './search-results';
import * as actions from '../../../redux-actions/locta-plus-v2-actions';
import SearchButtons from './search-buttons';
import { BootstrapContainer, Row, Col } from '../../bootstrap';

const LoctaSearch = () => {
    const dispatch = useDispatch();
    const onFormSubmit = (formProps) => {
        return dispatch(actions.search(formProps));
    };

    return (
        <>
            <section className="span12 block-grey search-container searches">
                <BootstrapContainer>
                    <Row>
                        <Col size={8} className="pb-0 pt-1">
                            <header className="block-header rt pb-0">
                                <span className="icon-surround"><i className="locta-plus-small"></i></span>
                                <h2>Locta Plus</h2>
                                <a
                                    className="search-info-link remove-bold"
                                    title="Please click for search rules."
                                    target="_blank"
                                    href="/cdn/downloads/LoCTA Plus Search criteria_2023.pdf"
                                >
                                    (?)
                                </a>
                            </header>
                        </Col>
                        <Col size={4} className="block-header pb-0 pt-4 pr-4">
                            <SearchButtons />
                        </Col>
                    </Row>

                </BootstrapContainer>

                <div className="locta-plus-container">
                    <div className="plus-search-container">
                        <SearchForm onSubmit={onFormSubmit} />
                    </div>
                    <div className="plus-result-container-v2">
                        <SearchResults />
                    </div>
                </div>

            </section>
        </>
    );
};

export default LoctaSearch;