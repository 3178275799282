export default (searchType, result) => {
    
    switch (searchType) {
        case 'Benefits':
            return {
                recordType: "Person",
                recordName: `${result.forename} ${result.surname}`,
                recordGender: result.gender,
                recordReference: "N/A",
                recordAddress: result.address,
                recordStartDate: result.startDate,
                recordEndDate: result.endDate
            }
        case 'BusinessRates':
            return {
                recordType: "Company",
                recordName: result.chargePayer,
                recordGender: "N/A",
                recordReference: result.accountReference,
                recordAddress: result.address,
                recordStartDate: result.dateOfOccupation,
                recordEndDate: result.dateOfVacation
            }
        case 'CouncilTaxAddress':
            return {
                recordType: "Person",
                recordName: `${result.forename} ${result.surname}`,
                recordGender: "N/A",
                recordReference: "N/A",
                recordAddress: result.address,
                recordStartDate: result.startDateFormatted,
                recordEndDate: result.endDateFormatted
            }
        case 'CouncilTaxName':
            return {
                recordType: "Person",
                recordName: `${result.forename} ${result.surname}`,
                recordGender: "N/A",
                recordReference: "N/A",
                recordAddress: result.address,
                recordStartDate: result.startDate,
                recordEndDate: result.endDate
            }
        default:
            return {}
    }
}