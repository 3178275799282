import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.scss';

const PostcodeInput = ({onChange, onBlur, value, handleSubmit, containerClassName = "", tabIndex = 1, ...rest}) => {
    const characters = 8;
    const hiddenSpaceCharacter = 4;
    const firstIncodeCharacter = 5;
    const allowedCharacters = '^[A-Za-z0-9]*$';
    const allowedPasteableCharacters = '^[A-Za-z0-9\\s]*$';
    const inputsRef = useRef([]);
    const [blurTimeout, setBlurTimeout] = useState(null);

    const getInputValues = useCallback(() => {
        const currentInput = inputsRef.current;
        return currentInput.map((input) => input.value === "" ? " " : input.value).join('').trim();
    }, [inputsRef]);

    const triggerGlobalOnChange = useCallback(() => {
        const result = getInputValues();
        onChange(result);
    }, [onChange, getInputValues]);

    const triggerGlobalOnBlur = useCallback(() => {
        var weStillHaveFocus = false;
        for(var i = 0; i < inputsRef.current.length; i++)
        {
            if (inputsRef.current[i] === document.activeElement) {
                weStillHaveFocus = true;
            }
        }
        
        if (!weStillHaveFocus) {
            const result = getInputValues();
            onBlur(result);
        }
    }, [onBlur, getInputValues]);

    const resetInputs = useCallback(() => {
        for(var i = 0; i < inputsRef.current.length; i++) {
            inputsRef.current[i].value = "";
        }
    }, [inputsRef]);


    const setInputsToValue = useCallback((data) => {
        for (let i = 0; i < characters && i < data.length; i++) {
            inputsRef.current[i].value = data.charAt(i);
        }
    }, [inputsRef]); 

    const handleOnChange = useCallback((e) => {
        const { target } = e;
        if (target.value.match(allowedCharacters)) {
            let { nextElementSibling } = target;

            if (nextElementSibling.type === "hidden")
                nextElementSibling = nextElementSibling.nextElementSibling;

            if (nextElementSibling !== null && nextElementSibling.type === "text") {
                (nextElementSibling).focus();
            }
        } else {
            target.value = '';
        }
        triggerGlobalOnChange();
    }, [triggerGlobalOnChange]);

    const handleOnKeyDown = useCallback((e) => {
        const { key } = e;
        const target = e.target;
      
        if (key === 'Backspace') {
          if (target.value === '' && target.previousElementSibling !== null) {
            if (target.previousElementSibling !== null) {
              (target.previousElementSibling).focus();
              e.preventDefault();
            }
          } else {
            target.value = '';
          }
          triggerGlobalOnChange();
        } else if (key === 'Enter') {
          handleSubmit();
        } else if (key === " ") {
          // did we press space on one of the first 4 inputs?
          for (var i = 0; i <= 3; i++) {
            if (target === inputsRef.current[i]) {
              target.value = "";
              inputsRef.current[firstIncodeCharacter].focus();
              e.preventDefault();
              break;
            }
          }
        }
      }, [triggerGlobalOnChange, inputsRef, firstIncodeCharacter]);

    const handleOnFocus = useCallback((e) => {
        if (blurTimeout !== null){
            clearTimeout(blurTimeout);
        }

        e.target.select();
    }, [blurTimeout]);

    const handleOnBlur = useCallback((e) => {
        if (blurTimeout !== null){
            clearTimeout(blurTimeout);
        }

        var t = setTimeout(() => triggerGlobalOnBlur(e), 200);
        setBlurTimeout(t);
    }, [blurTimeout, triggerGlobalOnBlur]);


    const handleOnPaste = useCallback((e) => {
        e.preventDefault();

        const clipboardText = e.clipboardData.getData('Text');
        
        let inputPosition = 0;
        if (clipboardText.match(allowedPasteableCharacters)) {
            for (let i = 0; i < characters && i < clipboardText.length; i++) {
                var letter = clipboardText.charAt(i);
                if (letter === " ") {
                    inputPosition = firstIncodeCharacter;
                }
                else {
                    if (inputPosition === hiddenSpaceCharacter) inputPosition++;

                    inputsRef.current[inputPosition].value = letter;
                    inputPosition++;
                }
            }

            triggerGlobalOnChange();
        }
    }, [triggerGlobalOnChange, inputsRef]);

    const handleContainerClick = useCallback((e) => {
        e.preventDefault();

        var lastInputWithValue = 0;

        for(var i = inputsRef.current.length - 1; i >= 0; i--) {
            if (inputsRef.current[i].value !== "") {
                lastInputWithValue = i;
                break;
            }
        }

        if (lastInputWithValue === 0) {
            if (inputsRef.current[0].value === "")
                inputsRef.current[0].focus();
            else 
                inputsRef.current[1].focus();
        } 
    }, [inputsRef]);


    const handleClearClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        resetInputs();
        inputsRef.current[0].focus();

        triggerGlobalOnChange();
    }, [triggerGlobalOnChange, resetInputs, inputsRef]);

    // ---

    useEffect(() => {
        if (value === "") {
            resetInputs()
        } else {
            setInputsToValue(value);
        }
    }, [value, resetInputs, setInputsToValue]);

    const inputs = [];
    for (let i = 0; i < characters; i++) {
        inputs.push(
            <input
                key={i}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onFocus={handleOnFocus}
                onPaste={handleOnPaste}
                onBlur={handleOnBlur}
                type={`${i === 4 ? 'hidden' : 'text'}`}
                ref={(el) => (inputsRef.current[i] = el)}
                maxLength={1}
                className={`postcode-input-digit ${i === 3 ? 'margin-right-bigger' : ''}`}
                tabIndex={tabIndex}
            />
        );
    }

    return (
        <div className={`postcode-input-container ${containerClassName}`} onClick={handleContainerClick}>
            {inputs}
            <button type='reset' onClick={handleClearClick} className="postcode-input-clear-button" tabIndex={tabIndex+1}>X</button>
        </div>
    );
};

export default PostcodeInput;