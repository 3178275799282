import * as Actions from '../redux-actions/locta-plus-v2-actions';

export const initialSearchedValues = {
    ...Actions.baseValues,
    isLinkedSearch: false,
    secondLinkedSearch: '',
    isLoctaPlusSearch: true,
    isQuickSearch: false,
    __requestVerificationToken: ''
};

const initialState = {
    searchType: "LoctaPlus",
    searchedValues: {...initialSearchedValues},
    searchDone: false,
    searchLoading: false,
    results: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.LOCTA_PLUS_V2_SEARCH_RESET:
            return {
                ...state,
                searchDone: false,
                searchLoading: false,
                results: {}
            };
        case Actions.LOCTA_PLUS_V2_PERFORMSEARCH_START:
            return {
                ...state,
                searchedValues: action.data,
                searchDone: false,
                searchLoading: true,
                results: {}
            };
        case Actions.LOCTA_PLUS_V2_PERFORMSEARCH_SUCCESS:
            return {
                ...state,
                searchDone: true,
                searchLoading: false,
                results: action.data
            };
        case Actions.LOCTA_PLUS_V2_PERFORMSEARCH_FAIL:
            return {
                ...state,
                searchDone: true,
                searchLoading: false,
                results: {}
            };
        default:
            return state;
    }
}

export const selector = {
    searchedValues: (state) => state.loctaPlusV2.searchedValues,
    results: (state) => state.loctaPlusV2.results,
    searchDone: (state) => state.loctaPlusV2.searchDone,
    searchValid: (state) => !state.form['locta-plus-v2-search'].submitFailed,
    searchLoading: (state) => state.loctaPlusV2.searchLoading,
    searchFeedback: (state) => typeof(state.form['locta-plus-v2-search'].submitErrors) !== 'undefined' ? state.form['locta-plus-v2-search'].submitErrors['error-feedback'] : null,

    benefitsCount: (state) => state.loctaPlusV2.results.BenefitsCount ?? 0,
    ctaxAddressCount: (state) => state.loctaPlusV2.results.CtaxAddressCount ?? 0,
    ctaxNameCount: (state) => state.loctaPlusV2.results.CtaxNameCount ?? 0,
    deceasedCount: (state) => state.loctaPlusV2.results.DeceasedCount ?? 0,
    votersRollCount: (state) => state.loctaPlusV2.results.VotersRollCount ?? 0,
    directoryCount: (state) => state.loctaPlusV2.results.DirectoryCount ?? 0,

    benefitsResults: (state) => state.loctaPlusV2.results.BenefitsResults,
    deceasedResults: (state) => state.loctaPlusV2.results.DeceasedResults,
    votersRollResults: (state) => state.loctaPlusV2.results.VotersRollResults,
    telephoneDirectoryResults: (state) => state.loctaPlusV2.results.TelephoneDirectoryResults,
    councilTaxAddressResults: (state) => state.loctaPlusV2.results.CouncilTaxAddressResults,
    councilTaxNameResults: (state) => state.loctaPlusV2.results.CouncilTaxNameResults,

    hasResults: (state) => state.loctaPlusV2.results.HasResults ?? false
};
