import React, {useEffect} from "react";
import { councils } from './server-values';
import { BootstrapContainer } from '../../bootstrap';
import { Form } from 'react-final-form';
import { searchFormName } from './config';
import { TextInput, PostcodeInput } from '../../react-final-forms';
import Loading from '../loading';
import { isAutoSearch } from "./server-values";


const containerStyles = { border: 0, borderBottom: "1px solid rgba(0, 0, 0, .1)", marginTop: "10px" };

let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
councilOptions.sort((a, b) => a.props.children.localeCompare(b.props.children));

const MyForm = ({ handleSubmit, values, form, searchForm, searchDone }) => {
    searchForm.current = form;
    useEffect(() => {
        if (isAutoSearch && !searchDone) {
            form.submit();
        }
    }, [searchDone, form]);

    let tabIndex = 1;

    return (
        <div className={"locta-form block-grey rb rt"} style={containerStyles}>
            <div className="inner">
                <form id={searchFormName} className="form-search rf" autoComplete="off" onSubmit={handleSubmit} >
                    <ul className="inline-fields">
                        <li>
                            <TextInput name="houseNameNumber" label="House Number/Name" placeholder="House Number/Name" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <TextInput name="street" label="Street Name" placeholder="Street Name" tabIndex={tabIndex++} inLine={false} />
                        </li>
                        <li>
                            <PostcodeInput name="postcode" label="Postcode" tabIndex={tabIndex++} inLine={false} handleSubmit={handleSubmit}/>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    );
}


const SearchFields = ({ onSubmit, initialValues, onReset, searchForm, isLoading, ...rest }) => {
    return (
        <BootstrapContainer style={{ height: "100%" }}>
            <div className={isLoading ? "" : "d-none"}>
                <Loading message="Searching data ..." />
            </div>
            <div className={isLoading ? "d-none" : ""}>
                <Form
                    onSubmit={onSubmit}
                    onReset={onReset}
                    initialValues={initialValues}
                    searchForm={searchForm}
                    {...rest}
                >
                    {MyForm}
                </Form>
            </div>
        </BootstrapContainer>
    );
}

export default SearchFields;