import React from 'react';

export const Row = ({className = '', style = null, onClick = () => {}, center = false, children}) => (
        <div className={`row no-gutters ${center === true ? "justify-content-center " : ""} ${className}`} style={style} onClick={onClick}>
            {children}
        </div>
);

export const Col = ({className = '', style = null, size = null, onClick = () => {}, children}) => (
    <div className={`col ${size ? `col-${size}` : ''} ${className}`} style={style} onClick={onClick}>
        {children}
    </div>
);

export const Hr = ({className = ''}) => (
    <hr className={className}/>
);