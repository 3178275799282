import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Table from './table';
import {Row} from '../bootstrap';
import {BootstrapSpinner} from '../bootstrap';

const UserAccounts = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get('/api/admin-dashboard/users').then(res => setData(res.data));
    }, [setData]);

    const table = data === null 
        ? <BootstrapSpinner loadingMessage='Loading ...'/> 
        : <Table data={data}/>;

    return (
        <>
            <Row>
                {table}
            </Row>
        </>
    );
};

export default UserAccounts;