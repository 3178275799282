import React from 'react';

import ModalRoot from '../../modals/modal-root';
import { HashRouter as Router, Route } from "react-router-dom";
import List from './list';
import Edit from './edit';
import Add from './add';

const CouncilsRoot = () => {
    return (
        <>
            <div className="container-fluid p-0 pt-2">
                <Router hashType="noslash">
                    <Route exact path={"/"} component={List}/>
                    <Route exact path={"/Add"} component={Add}/>
                    <Route exact path={"/Edit/:id"} component={Edit}/>
                </Router>
            </div>
            <ModalRoot/>
        </>
    );
}


export default CouncilsRoot;