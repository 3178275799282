import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import { selector } from '../../../redux-reducers/voters-roll-search-reducer';

import { BootstrapContainer, Row, Col, LoctaButton } from '../../bootstrap';
import ResultsGrid from './results-grid';

import AddToProfileModal from '../../modals/add-to-profile';
//import AddVotersrollAlert from '../../modals/add-votersroll-alert';


const ResultsDetail = ({ detail }) => {

    const searchedValues = useSelector(state => selector.searchedValues(state));
    const searchType = useSelector(state => selector.searchType(state));

    const [showAddToProfileModal, setShowAddToProfileModal] = useState(false);
    //const [showAddVotersRollAlertModal, setShowAddVotersRollAlertModal] = useState(false);

    const addToProfileModal = showAddToProfileModal ? <AddToProfileModal onClose={() => setShowAddToProfileModal(false)} searchType={searchType} result={detail} /> : null;
    //const addVotersRollAlertModal = showAddVotersRollAlertModal ? <AddVotersrollAlert onClose={() => setShowAddVotersRollAlertModal(false)} result={detail} /> : null;

    return (
        <BootstrapContainer className="container-fluid result">
            <Row className="mt-3 mb-3 pl-3">
                <Col size={10} className="lo_sensitive">
                    <ResultsGrid data={detail}/>
                </Col>
                <Col size={2} className="hide-on-print">
                    <LoctaButton icon="fas fa-user-plus" textComponent={(<>add to<br />profile</>)} onClick={() => setShowAddToProfileModal(true)} className="mb-3 hide-on-print" />
                    {/* <LoctaButton icon="fas fa-exclamation-triangle" textComponent={(<>add<br />alert</>)} onClick={() => setShowAddVotersRollAlertModal(true)} className="mb-3" /> */}
                </Col>
            </Row>
            { addToProfileModal }
            {/* { addVotersRollAlertModal } */}
        </BootstrapContainer>
    );
}

export default ResultsDetail;
