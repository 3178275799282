import ResultDetail from './results-detail';
import React from 'react';

export default ({ results, fs }) => {

    let { benefits, deceased, councilTaxAddress, councilTaxName, directory, votersRoll, votersRollByAddress } = results;

    return (
        <div className="plus-result-container">
            <ResultDetail result={ councilTaxName } />
            <ResultDetail result={ councilTaxAddress } />
            <ResultDetail result={ benefits } />
            <ResultDetail result={ deceased } />
            <ResultDetail result={ votersRoll } />
            { fs.showVotersRollByAddress ? <ResultDetail result={ votersRollByAddress } /> : null }
            <ResultDetail result={ directory } />
        </div>
    );    
}
