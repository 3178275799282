import React, { useState, useEffect } from 'react';

import Confirmation from './confirmation';
import AddToJournalForm from './form';

import { addToJournalAsync } from './api';
import GetAddToJournalInitialFormValues from './get-initial-form-values';

export default ({onClose, searchType, result}) => {

    const [visible, setVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    
    let initialValues = {
        debtType: "UNDEFINED",
        searchType: searchType,
        ...GetAddToJournalInitialFormValues(searchType, result)
    };


    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);


    const onSubmit = async (formValues) => {
        let result = await addToJournalAsync(formValues);

        if (Object.keys(result).length) {
            return result;
        }

        setShowConfirmation(true);
    }

   
    const style = showConfirmation ? { width: '400px' } : { width: '700px'};
    const page = showConfirmation
        ? <Confirmation onClose={onClose} />
        : <AddToJournalForm 
            onSubmit={onSubmit} 
            onClose={onClose}
            initialValues={initialValues} />;

    return (
        <>
            <div className={ `react-modal-anchor b4 ${(visible ? ' show' : '')}`}>
                <div className={ `react-modal add-to-journal ${visible ? 'show' : ''}` } style={style}>
                    <button onClick={onClose} className="close-button"><i className="fas fa-times"></i></button>
                    <h1>
                        <i className="fas fa-book-medical"></i>
                        Add to Journal
                    </h1>

                    { page }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
}