import React from 'react';

export const renderInput = ({input, label, type, placeholder, meta: { touched, error }}) => (
    <div className="w-100">
        <span className="w-100 p-15 option-label">{label}</span>
        <div className="w-100 p-15">
            <input
                {...input}
                type={type}
                placeholder={placeholder}
                className={
                    "input-block-level" +
                    (touched && error ? " invalid-search-input" : "")
                }
            />
        </div>
    </div>
);

export const renderTextArea = ({input, label, rows, meta: { touched, error }}) => (
    <div className="w-100">
        <span className="w-100 p-15 option-label">{label}</span>
        <div className="w-100 p-15">
            <textarea
                    {...input}
                    rows={rows}
                    className={
                        "input-block-level" +
                        (touched && error ? " invalid-search-input" : "")
                    }
                >
            </textarea>
        </div>
    </div>
);

export const renderSelect = ({input, label, options, meta: { touched, error }}) => (
    <div className="w-100">
        <span className="w-100 p-15 option-label">{label}</span>
        <div className="w-100 p-15">
            <select
                {...input}
                className={
                    "input-block-level" +
                    (touched && error ? " invalid-search-input" : "")
                }
            >
                { Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
            </select>
        </div>
    </div>
);
