import React, {useState, useEffect} from 'react';
import CouncilForm from './council-form';
import axios from 'axios';
import {BootstrapSpinner} from '../../bootstrap';
import {useHistory} from 'react-router-dom';

const AddForm = () => {
    const [initialValues, setInitialValues] = useState(null);
    const [options, setOptions] = useState(null);
    const history = useHistory();

    useEffect(() => {
        axios.get(`/api/data-providers/0`).then(res => {
            const result = res.data;
            setInitialValues(result.values);
            setOptions(result.options);
        });
    }, [])
    
    const onSubmit = async (values) => {
        let response = await axios.post(`/api/data-providers`, values);
        if(response.data.length === 0){
            history.goBack();
        }
        return response.data;
    }

    if(initialValues === null || options === null){
        return <BootstrapSpinner loadingMessage='Loading ...'/>
    }

    return <CouncilForm initialValues={initialValues} options={options} onSubmit={onSubmit} />;
};

export default AddForm;