import React, {useState, useEffect, useCallback, useRef} from 'react';
import StepWizard from "react-step-wizard";
import StagingDataStep from './staging-data-step';
import ResultsStep from './results-step';
import ControlCard from './control-card';
import { initialValues } from './server-values';
import api from './api';
import SearchForm from './search-form';
import customTransitions from '../transitions';

const goToStep = (step) => window.location.hash=`step${step}`;

const BusinessRatesSearch = () => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const searchForm = useRef(null);

    // step 1 form
    const [searchedValues, setSearchedValues] = useState(initialValues);
    const [searchDone, setSearchDone] = useState(false);

    //step 2 form - staging
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchStagingResult, setSearchStagingResult] = useState(null);
    const [stagingSearchDone, setStagingSearchDone] = useState(false);

    //results
    const [results, setResults] = useState([]);

    const onReset = useCallback(_ => {
        searchForm.current.restart(initialValues);
        setSearchDone(false);
        setStagingSearchDone(false);
        setSearchedValues(initialValues);
        setResults(null);
        setIsLoading(false);
        goToStep(1);
    }, [setSearchedValues, setSearchDone, setIsLoading]);

    const onSearch = useCallback (async searchValues => {
        setSearchDone(false);
        setStagingSearchDone(false);
        setIsLoading(true);
        setSearchedValues(searchValues);
        
        var serverValues = {
            ...searchValues,
            searchStartDate: Date.formatForServer(searchValues.searchStartDate),
            searchEndDate: Date.formatForServer(searchValues.searchEndDate),
        }

        const validate = await api.validate(serverValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors){
            setSearchStagingResult(null);
            setSearchDone(false);
            goToStep(1);
            setIsLoading(false);
            return validate.fieldErrors;
        }
        else {
            try {
                const search = await api.companyList(serverValues);
                setSearchStagingResult(search || null);
                setSearchDone(true);
                goToStep(2);
                setIsLoading(false);
            }
            catch (err) {
                setSearchStagingResult(null);
                setSearchDone(false);
                goToStep(1);
                setIsLoading(false);
                //should really provide some feedback here - maybe a toastr message
            }
        }
    }, [setSearchStagingResult, setSearchDone, setSearchedValues, setIsLoading]);

    const onStagingSearch = useCallback (async _ => {
        setIsLoading(true);

        const validate = await api.validate(searchedValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors)
        {
            setIsLoading(false);
            return validate.fieldErrors;
        }
        else {
            try {
                if (selectedRows.length > 0) {
                    const selectedCompanyIds = selectedRows.map(x => x.id);
                    const search = await api.search({...searchedValues, logId: searchStagingResult.logId, selectedCompanyIds});
                    setResults(search || null);
                    setStagingSearchDone(true);
                    goToStep(3);
                    setIsLoading(false);
                }
            }
            catch (err) {
                setResults(null);
                setStagingSearchDone(true);
                goToStep(2);
                setIsLoading(false);
                //toastr?
            }
        }
    }, [setResults, setStagingSearchDone, searchStagingResult, searchedValues, selectedRows]);

    useEffect(() => {
        if(initialLoad){
            window.location.hash="step1";
            setInitialLoad(false);
        }
    }, [initialLoad, setInitialLoad]);

    const controlCardProps = {selectedRows, searchDone, onReset, setResults, setStagingSearchDone, onStagingSearch, stagingSearchDone, searchForm};
    const searchStepProps = {onSubmit: onSearch, initialValues: searchedValues, onReset, searchForm, isLoading};
    const stagingStepProps = {setSelectedRows, selectedRows, searchStagingResult, searchDone, isLoading};
    const resultsStepProps = {searchDone, results, searchedValues, stagingSearchDone, isLoading};

    return (
        <>
            <section className="span12 search-container searches search-by-distance"> 
                <header className="block-header block-grey rt">
                    <span className="icon-surround"><i className="business-rates-small"></i></span>
                    <h2>Business Rates</h2>
                    <span className="search-info-link remove-bold" title="Searches may be made on specific combinations of the fields.&#013;Permitted searches are:&#013; - Company Name only &#013; - Company Name plus Postcode &#013; - Postcode only &#013; All of the above can be combined with the Occupation Date Range if necessary." href="#">(?)</span>
                </header>

                <StepWizard transitions={customTransitions} isHashEnabled={true} nav={<ControlCard {...controlCardProps}/>}>
                    <SearchForm {...searchStepProps}/>
                    <StagingDataStep {...stagingStepProps} />
                    <ResultsStep {...resultsStepProps} />
                </StepWizard>
            </section>
        </>
        
    );
}
 
export default BusinessRatesSearch;