export default [
    /source/,
    /tags/,
    /uri/,
    /offset/,
    /prospector/,
    /beat/,
    /input/,
    /joins/,
    /etag/,
    /host.name/,
    /document.type/,
    /links/,
    /mortgages/,
    /companynumber/,
    /name/,
    /kind/
];