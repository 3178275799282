import React, { useEffect } from 'react';
import ModalIframe from './modal-iframe';
import SearchTypes from '../searches-results/search-types';

import serialize from '../../services/serialize';

const getQueryParamsFn = ({ result, searchedValues, searchType }) => {
    switch (searchType) {
        case SearchTypes.BusinessRates: 
            return serialize({
                chargePayer: result.chargePayer,
                address: result.address,
                
                councilId: result.councilId,
                initialSearch: searchedValues.initialSearch,
                linkedSearch: searchedValues.linkedSearch,
                isLoctaPlusSearch: searchedValues.isLoctaPlusSearch,
                isQuickSearch: searchedValues.isQuickSearch
            });
        case SearchTypes.Benefits: 
            return serialize({
                forename: result.forename,
                dob: result.dob,
                surname: result.surname,
                startDate: result.startDate,
                endDate: result.endDate,
                gender: result.gender,
                address: result.address,
                nino: result.nino,
                ref: result.ref,
                councilId: result.councilId,
                initialSearch: searchedValues.initialSearch,
                linkedSearch: searchedValues.linkedSearch,
                isLoctaPlusSearch: searchedValues.isLoctaPlusSearch,
                isQuickSearch: searchedValues.isQuickSearch
            });
        case SearchTypes.CouncilTaxName: 
            return serialize({
                forename: result.forename,
                dob: result.dob,
                surname: result.surname,
                startDate: result.startDate,
                endDate: result.endDate,
                address: result.address,
                ref: result.ref,
                councilId: result.councilId,
                initialSearch: searchedValues.initialSearch,
                linkedSearch: searchedValues.linkedSearch,
                isLoctaPlusSearch: searchedValues.isLoctaPlusSearch,
                isQuickSearch: searchedValues.isQuickSearch
            });
        case SearchTypes.CouncilTaxAddress: 
            return serialize({
                forename: result.forename,
                dob: result.dob,
                surname: result.surname,
                startDate: result.startDate,
                endDate: result.endDate,
                address: result.address,
                ref: result.ref,
                councilId: result.councilId,
                initialSearch: searchedValues.initialSearch,
                linkedSearch: searchedValues.linkedSearch,
                isLoctaPlusSearch: searchedValues.isLoctaPlusSearch,
                isQuickSearch: searchedValues.isQuickSearch
            });
        default: 
            console.warn(`Linked search type not implemented ${searchType}`)
            return null;
    };
}

const LinkedSearchModal = ({ onClose, searchType, searchedValues, result }) => {
    useEffect(() => {
        let onDispatchClose = window.dispatcher.subscribe("linked-search-close", onClose);

        return () => {
            onDispatchClose && onDispatchClose.unsubscribe();
        }
    });

    let queryParams = getQueryParamsFn({result, searchedValues, searchType});

    let url = `/LinkedSearch/${searchType}?${queryParams}`;

    return <ModalIframe url={url} height={370} width={980} onClose={onClose} />;
}

export default LinkedSearchModal;