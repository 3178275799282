import DomConfigRepository from '../../../repositories/domConfigRepository';
import config from '../config';

const sv = DomConfigRepository.getJsonConfigById('searchValues');
export const initialValues = {
    forename: sv.forename,
    surname: sv.surname,
    selectedCouncilId: sv.selectedCouncilId,
    isExact: sv.isExact,
    maxSearchDistance: typeof(sv.maxSearchDistance) === 'undefined' || sv.maxSearchDistance === 0 ? config.sliderStartingValue : sv.maxSearchDistance,
    
    selectedRecordIds: sv.selectedRecordIds,

    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};
export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;
export const councils = sv.councils || {};