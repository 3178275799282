import React from 'react';
import store from '../redux-stores/website-store';
import ResultsView from '../react-components/searches-results';
import Base from './base';
import SearchTypes from '../react-components/searches-results/search-types';

const Main = ({resultasjson, searchvaluesasjson, viewtype}) => {
    let data = null;
    let searchValues = null;
    if(resultasjson && searchvaluesasjson) {
        data = JSON.parse(resultasjson);
        searchValues = JSON.parse(searchvaluesasjson);
    } else {
        return null;
    }

    let resultType = SearchTypes.None;
    switch(viewtype) {
        case 'nndr':
            resultType = SearchTypes.BusinessRates;
            break;
        case 'ctax-name':
            resultType = SearchTypes.CouncilTaxName;
            break;
        case 'ctax-address':
            resultType = SearchTypes.CouncilTaxAddress;
            break;
        default: break;
    }
    return <ResultsView data={data} searchValues={searchValues} resultType={resultType}/>
}

export default Base('react-results-view', store, Main);