const ToQsPair = (key, value) => {
  return encodeURIComponent(key) + "=" + encodeURIComponent(value);
}

const serialize = (obj) => {
  var str = [];
  for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
          var value = typeof obj[p] === 'function' ? obj[p]() : obj[p];
          if (Array.isArray(value)) {
              for (var item in value) {
                  str.push(ToQsPair(p, value[item]));
              }
          } else {
              str.push(ToQsPair(p, value));
          }
      }
  }
  return str.join("&");
}

export default serialize;