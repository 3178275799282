import React from 'react';

import ResultsDetail from './results-detail';

export default [
    {
        id: 'details',
        accessor: (row) => row,
        Cell: (props) => (
            <ResultsDetail key={props.value.id} detail={props.value} />
        ) 
    }
];