import React from "react";
import { Row, Col } from "../../bootstrap";
import {Footer, Logo} from './extra';

const EmbedIframe = () => (
  <iframe
    title="kibana.loctahub.co.uk"
    src="https://kibana.loctahub.co.uk/app/kibana#/dashboard/1f570cb0-b175-11ea-91e5-0532fe7c59de?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'1900-01-01T00%3A00%3A00.000Z'%2Cto%3Anow))"
    style={{ width: "100%", height: "100%" }}
  />
);
const DataDashboard = () => {
  return (
    <>
      <Row className="d-flex h-100">
        <Row className="w-100">
          <Col size={4}>
            <Logo />
          </Col>
          <Col
            size={8}
            className="d-flex justify-content-center align-items-center"
          >
            <h2>Loctahub.co.uk Data Dashboard</h2>
          </Col>
        </Row>
        <EmbedIframe />
        <Footer />
      </Row>
    </>
  );
};

export default DataDashboard;
