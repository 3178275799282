import ResultDetailForm from './results-detail-form';
import React from 'react';

export default ({ result }) => {

    if (result.searchIsInProgress) {
        return (
            <div className="plus-article">
                <header className="block-header blue-header">
                    <h3>{ result.title } Results</h3>
                </header>
                <div className="inner grad-white-grey clearfix">
                    <div className="spinner-container">
                        <img src="/images/searching.gif" alt="searching" className="progress-spinner" height="20" width="20" />
                    </div>
                </div>
            </div>
        );
    }

    const disabledButton = <button className="btn btn-success align-right disabled"><i className="icon-play"></i>View</button>;

    let AllMatches = <div/>;

    if (result.hasAllMatchesOption) {
        let allForm = result.searchHasCompleted && result.allResults > 0
            ? <ResultDetailForm result={ result } isExact={ false } /> 
            : disabledButton;

        AllMatches = (
            <div>
                <div className="dt searchdash">{ result.searchHasCompleted ? result.allResults : 0 } All Matches</div>
                <div className="dd pull-right">
                    { allForm }
                </div>
            </div>
        );
    }
    
    let exactForm = (result.searchHasCompleted && result.exactResults > 0)
        ? <ResultDetailForm result={ result } isExact={ true } />  
        : disabledButton;

    return (
        <div className="plus-article">
            <header className="block-header blue-header">
                <h3>{ result.title } Results</h3>
            </header>
            <div className="inner grad-white-grey clearfix">
                <div className="dl">
                    <div className="dt searchdash">{ result.searchHasCompleted ? result.exactResults : 0 } Exact Matches</div>
                    <div className="dd pull-right">
                        { exactForm }
                    </div>
                    { AllMatches }
                </div>
            </div>
        </div>
    );
}
