import React from 'react';

export const Card = ({className='', title, children}) => (
    <div className={`card mb-3 ${className}`} style={{maxWidth:"18rem"}}>
        <div className="card-header">{title}</div>
        <div className="card-body">
            {children}
        </div>
    </div>
);

export const PrimaryCard = ({...rest}) => <Card className='border-primary' {...rest}/>;
export const SecondaryCard = ({...rest}) => <Card className='border-secondary' {...rest}/>;
export const SuccessCard = ({...rest}) => <Card className='border-success' {...rest}/>;