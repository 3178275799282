import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';
import { selector } from '../redux-reducers/council-tax-address-search-reducer';

export const COUNCIL_TAX_ADDRESS_SEARCH_STAGING_START = 'COUNCIL_TAX_ADDRESS_SEARCH_STAGING_START';
export const COUNCIL_TAX_ADDRESS_SEARCH_STAGING_SUCCESS = 'COUNCIL_TAX_ADDRESS_SEARCH_STAGING_SUCCESS';
export const COUNCIL_TAX_ADDRESS_SEARCH_STAGING_FAIL = 'COUNCIL_TAX_ADDRESS_SEARCH_STAGING_FAIL';

export const COUNCIL_TAX_ADDRESS_SEARCH_RESET = 'COUNCIL_TAX_ADDRESS_SEARCH_RESET';

export const COUNCIL_TAX_ADDRESS_PERFORMSEARCH_START = 'COUNCIL_TAX_ADDRESS_PERFORMSEARCH_START';
export const COUNCIL_TAX_ADDRESS_PERFORMSEARCH_SUCCESS = 'COUNCIL_TAX_ADDRESS_PERFORMSEARCH_SUCCESS';
export const COUNCIL_TAX_ADDRESS_PERFORMSEARCH_FAIL = 'COUNCIL_TAX_ADDRESS_PERFORMSEARCH_FAIL';

export const urls = {
    addressList: `/api/search/council-tax-address/address-list`,
    search: `/api/search/council-tax-address/search`,
    validate: `/api/search/council-tax-address/validate`
}

export const formName = "council-tax-address-search";

class CouncilTaxAddressSearchViewModel {
    constructor(formValues, logId) {
        this.HouseNameNumber = formValues.houseNameNumber;
        this.StreetName = formValues.streetName;
        this.Postcode = formValues.postcode;
        this.IsLive = formValues.isLive;
        this.SelectedAddressIds = formValues.selectedAddressIds;
       
        this.InitialSearch = formValues.initialSearch;
        this.LinkedSearch = formValues.linkedSearch;
        this.SecondLinkedSearch = formValues.secondLinkedSearch;
        this.IsLoctaPlusSearch = formValues.isLoctaPlusSearch;
        this.IsQuickSearch = formValues.isQuickSearch;

        if(logId){
            this.LogId = logId;
        }

        this.__RequestVerificationToken = formValues.__requestVerificationToken;
    }
}

export const baseValues = {
    houseNameNumber: '',
    streetName: '',
    postcode: '',
    isLive: false,
    selectedAddressIds: []
};

const action = (type, data = null) => ({
    type, data
});

export const searchStaging = (formValues) => {
    return async (dispatch) => {

        dispatch(action(COUNCIL_TAX_ADDRESS_SEARCH_STAGING_START, formValues));

        const viewModel = new CouncilTaxAddressSearchViewModel(formValues);

        const validateResponse = await axios.post(urls.validate, viewModel);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            goToStep(2);
            const searchResponse = await axios.post(urls.addressList, viewModel);
            dispatch(action(COUNCIL_TAX_ADDRESS_SEARCH_STAGING_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(COUNCIL_TAX_ADDRESS_SEARCH_STAGING_FAIL));
        }
    }
}

export const search = (selectedAddressIds) => {
    return async (dispatch, getState) => {
        const state = getState();
        const searchedValues = selector.searchedValues(state);
        const logId = selector.logId(state);
        const newFormValues = {...searchedValues, selectedAddressIds};
        dispatch(action(COUNCIL_TAX_ADDRESS_PERFORMSEARCH_START, newFormValues));

        goToStep(3);
        try {
            const viewModel = new CouncilTaxAddressSearchViewModel(newFormValues, logId);
            const searchResponse = await axios.post(urls.search, viewModel);
            dispatch(action(COUNCIL_TAX_ADDRESS_PERFORMSEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(COUNCIL_TAX_ADDRESS_PERFORMSEARCH_FAIL));
        }
    }
}

export const validateSearch = (model) => {
    return async (dispatch) => {
        const formValues = new CouncilTaxAddressSearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export const resetSearch = (values) => {
    return (dispatch) => {
        dispatch(action(COUNCIL_TAX_ADDRESS_SEARCH_RESET));
        dispatch(initialize(formName, { ...values, ...baseValues }));
        goToStep(1)
    }
}

const goToStep = (step) => window.location.hash=`step${step}`;