import Store from './base/store';
import superuserReducer from '../redux-reducers/superuser-reducer';

const preloadedState = {
    globals: {
        isSU: true
    }
};

let instance = null;
export default () => {
    if(instance === null){
        instance = Store(superuserReducer, '../redux-reducers/superuser-reducer', preloadedState);
    }
    return instance;
}