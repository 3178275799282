import React, { useMemo } from 'react';
import { TextInput, CheckboxInput, MultiselectInput, SelectInput } from '../../react-final-forms/inputs'
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {LoctaButton, PrimaryButton, SecondaryButton} from '../../bootstrap';
import arrayMutators from 'final-form-arrays'

const CouncilForm = ({initialValues = null, options, onSubmit}) => {
    const { tags, councils } = options;

    let councilOptions = useMemo(() => councils ? councils.map(c => (<option value={c.value} key={c.value}>{c.label}</option>)) : null, [councils]);
    let tagOptions = useMemo(() => tags ? tags.map(t => t.value) : [], [tags]);

    return (
        <div className='locta-form bg-grad p-3'>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                mutators={{...arrayMutators}}
                render={({ handleSubmit, form, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <SelectInput name="councilCode" label="Local Authority">
                            <option value="">-- Please Select --</option>
                            {councilOptions}
                        </SelectInput>
                        <br />
                        <MultiselectInput label="Tags" name="tags" options={tagOptions} allowCreate={true} showError={true} required={true} showSelectedItemsInList={true}/>
                        <br />
                        <TextInput label="Name" name="contactName" placeholder="Contact Name" required={true}/>
                        <TextInput label="Main User Contact Tel" name="contactNumber" placeholder="Main Tel" />
                        <TextInput label="Council Tax Email Address" name="councilTaxEmailAddress" placeholder="Council Tax Email" />
                        <TextInput label="Benefits Email Address" name="benefitsEmailAddress" placeholder="Benefits Email" />
                        <TextInput label="NNDR Email Address" name="nndrEmailAddress" placeholder="Nndr Email" />
                        <TextInput label="Extract File Query Email" name="extractWatcherEmail" placeholder="ExtractFile Query Email" />
                        <br />
                        <CheckboxInput label="Show Employment Fields in Council Tax Results" name="showEmploymentFields" placeholder="Show Employment Fields" />
                        <br />
                        <TextInput label="Postcode" name="postcode" placeholder="Postcode" required={true}/>
                        <TextInput label="Grid N" name="gridN" placeholder="Grid N" required={true}/>
                        <TextInput label="Grid E" name="gridE" placeholder="Grid E" required={true}/>
                        <TextInput label="Latitude" name="latitude" placeholder="Latitude" required={true}/>
                        <TextInput label="Longitude" name="longitude" placeholder="Longitude" required={true}/>
                        <br />
                        <CheckboxInput label="Extract Alert Emails: NNDR" name="hasNndrExtractAlert" placeholder="ExtractAlertNNDR" />
                        <CheckboxInput label="Extract Alert Emails: BENSDATA" name="hasBensdataExtractAlert" placeholder="ExtractAlertBENSDATA" />
                        <CheckboxInput label="Extract Alert Emails: LOCTAPER" name="hasLoctaperExtractAlert" placeholder="ExtractAlertLOCTAPER" />
                        <CheckboxInput label="Extract Alert Emails: LOCTAPRP" name="hasLoctaprpExtractAlert" placeholder="ExtractAlertLOCTAPRP" />
                        <br />
                        <hr/>
                        <h2>Sftp Accounts</h2>
                        <FieldArray name="sftpAccounts">
                            {({ fields }) => (
                            <div>
                                {fields.map((name, index) => (
                                <div key={name} style={{marginTop: "20px"}}>
                                    <Field name={`${name}.username`}>
                                        {({ input, meta }) => (
                                            <input {...input} type="text" placeholder="Username" />
                                        )}
                                    </Field>
                                    <Field name={`${name}.password`}>
                                        {({ input, meta }) => (
                                            <input {...input} type="text" placeholder="Password" />
                                        )}
                                    </Field>
                                    <Field name={`${name}.publicKey`}>
                                        {({ input, meta }) => (
                                            <textarea {...input} placeholder="Public Key" />
                                        )}
                                    </Field>
                                    
                                    <button type="button" onClick={() => fields.remove(index)}>
                                    Remove
                                    </button>
                                </div>
                                ))}
                                <button type="button" onClick={() => fields.push({ username: '' })}>
                                Add Item
                                </button>
                            </div>
                            )}
                        </FieldArray>
                        <ul className="inline-fields search-buttons">
                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton type="submit" small={true} className="h-100 mt-20" disabled={submitting || pristine} ButtonFn={PrimaryButton} textComponent={<>Save</>} fontSize="18px" fontWeight="normal" />
                            </li>
                            <li className="align-right b4" style={{width:"100px", height:"28px"}}>
                                <LoctaButton small={true} className="h-100 mt-20" disabled={submitting || pristine} ButtonFn={SecondaryButton} textComponent={<>Reset</>} fontSize="18px" fontWeight="normal" onClick={form.reset}/>
                            </li>
                        </ul>
                    </form>
                )}
            />
        </div>

    )
};

export default CouncilForm;