import React from 'react';
import StagingTable from './staging-table';
import { BootstrapSpinner, BootstrapContainer } from '../../bootstrap';

const SearchStagingStep = ({ searchStagingResult, searchDone, setSelectedRows, isLoading }) => {
    const showLoading = !searchDone || isLoading;
    
    let content = null;
    if (showLoading || !searchStagingResult) {
        content = <BootstrapSpinner className="pt-2 pb-2" loadingMessage='Searching records ...'/>
    }
    else {
        const options = searchStagingResult.options;

        if (options && options.length <= 0) {
            content = (
                <p style={{fontSize:"18px", fontWeight:"bold"}}>
                    No results available.
                </p>
            );
        } else {
            content = <StagingTable data={options} setSelectedFn={setSelectedRows}/>
        }
    }

    return (
        <BootstrapContainer>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{minHeight:'40vh'}}>
                {content}
            </div>
        </BootstrapContainer>
    );
}

export default SearchStagingStep;