import React from 'react';
import {SuccessButton, SecondaryButton} from '../bootstrap';

const SuccessActionModal = ({ closeModal, action, title, message, cancelText, text }) => {
  return (
    <div className="b4-modal-content">
      <div className="b4-modal-header">
        <h5
          className="b4-modal-title"
        >{title}</h5>
        <button type="button" className="close" aria-label="Close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="b4-modal-body">
        <p>{message}</p>
      </div>
      <div className="b4-modal-footer">
        <SecondaryButton onClick={closeModal}>{cancelText || 'Cancel'}</SecondaryButton>
        <SuccessButton onClick={action}>{text || 'Continue'}</SuccessButton>
      </div>
    </div>
  )
}

export default SuccessActionModal;