import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import {Row, PrimaryButton, Col} from '../bootstrap';
import {Spinner} from '../bootstrap'
import {selector} from '../../redux-reducers/companies-house-reducer';
import {externalRoutes} from '../../config/routes';

export const formName = 'ch-search';
export const queryFieldName = 'searchTerm';

const renderField = (field) => {
  return (
    <div className="input-row pr-2 w-100">
      <input {...field.input} type="text" className="b4-form-control" placeholder="Company Name, Company Number or Person" key="query-input"/>
      {field.meta.touched && field.meta.error &&
        <span className="error">{field.meta.error}</span>}
    </div>
  );
}

const FurtherSearch = ({query}) => (
  <Col size={4}>
    <Row>
      <Col size={12} className="d-flex align-items-center justify-content-center">
        Haven't found what you were looking for?
      </Col>
      <Col size={12} className="d-flex align-items-center justify-content-center">
        <a href={externalRoutes.chSearch(query)} target="_blank" rel="noopener noreferrer" className="b4-btn b4-btn-info b4-btn-sm">Search Companies House<i className="fas fa-external-link-alt pl-1"></i></a>
      </Col>
    </Row>

  </Col>
);

let SearchForm = ({handleSubmit, loading = false, additionalLoading = false, query}) => {
  const disabled = loading;
  let furtherSearch = null;
  if(query) {
    furtherSearch = <FurtherSearch query={query}/>
  }

  const message = loading ? "Step 1 of 2 : Performing search..." : additionalLoading ? "Step 2 of 2 : Retrieving additional information..." : "";

  const messageRow = message.length > 0 ? (
    <Row className="justify-content-center mt-4">
          <Col size={1}>
            {loading || additionalLoading ? <Spinner className="ml-4"/> : null} 
          </Col>
          <Col size={3}>
            <div>{message}</div>
          </Col>
        </Row>
  ) : null;

  return (
    <form onSubmit={handleSubmit}>
        <Row className="align-items-center">
          <Col size={6}>
            <Field name="searchTerm" component={renderField}/>
          </Col>
          <Col size={2}>
            <PrimaryButton type="submit" disabled={disabled}>Search</PrimaryButton>
          </Col>
          {furtherSearch}
        </Row>
        {messageRow}
    </form>
  );
}

const mapStateToProps = (state) => ({
  query: selector.query(state)
});

SearchForm = connect(mapStateToProps)(SearchForm);

SearchForm = reduxForm({
  form: formName
})(SearchForm);

export default SearchForm;