import React from 'react';
import { useSelector } from 'react-redux';
import SearchFields from './search-fields';
import Results from './results';
import { selector } from '../../redux-reducers/locta-plus-reducer';

import domConfigRepository from '../../repositories/domConfigRepository';
const fs = domConfigRepository.getJsonConfigById("searchConfig");

const Main = () => {
    const searches = useSelector(selector.searches);

    return (
        <div className="span12 block-grey locta-plus-container">
            <SearchFields fs={fs} />
            <Results results={searches} fs={fs} />
            <div className="clearfix"/>
        </div>
    );
}

export default Main;
