import * as actions from "../../redux-actions/locta-plus-actions";
import React from "react";
import { connect } from "react-redux";
import { selector } from "../../redux-reducers/locta-plus-reducer";
import validate from "./search-fields-validator";
import SearchProgress from "./search-progress";
import LoctaPlusHelpModal from "../modals/locta-plus-help-modal";
import sliderConfig from '../../config/slider-config';
import { LoctaButton, PrimaryButton, SecondaryButton, ReactDatePicker as DatePicker } from '../bootstrap';
import { initialValues, councils } from './server-values';


const tabIndex = 23;

let councilOptions = Object.keys(councils).map(c => (<option value={c} key={c}>{councils[c]}</option>));
councilOptions.sort((a, b) => a.props.children.localeCompare(b.props.children));

const initialState = {
  fields: {
    ...initialValues
  },
  validation: {
    forename: { isValid: true, showError: false, isFormatValid: true },
    surname: { isValid: true, showError: false, isFormatValid: true },
    companyName: { isValid: true, showError: false, isFormatValid: true },
    dateOfBirth: { isValid: true, showError: false, isFormatValid: true },
    nino: { isValid: true, showError: false, isFormatValid: true },
    postcode: { isValid: true, showError: false, isFormatValid: true },
    location: { isValid: true, showError: false, isFormatValid: true },
    selectedCouncilId: { isValid: true, showError: false, isFormatValid: true },
    searchStart: { isValid: true, showError: false, isFormatValid: true },
    searchEnd: { isValid: true, showError: false, isFormatValid: true },
    searchesRequired: { isValid: true, showError: false, isFormatValid: true }
  },
  isValidSearch: false
};

const SearchError = ({ errors }) => {
  if (errors.length > 0) {
    return (
      <div className="alert alert-danger search-errors" role="alert">
        <ul>
          {errors.map(message => (
            <li key={message}>- {message}</li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

const DateTimePicker = ({ onChange, value = null, ...rest }) => (
  <div className={`rw-dateinput-container`}>
    <DatePicker
      onChange={onChange}
      value={value}
      {...rest}
    />
  </div>

);

class SearchFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {...initialState, fields:{...initialState.fields}, validation:{...initialState.validation}};

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.onChanges = {
      onChange: this.onChange.bind(this),
      onInput: this.onChange.bind(this)
    };

    this.onBlurs = {
      onBlur: this.onBlur
    };

    this.fs = props.fs;
  }

  onSearchClick(e) {
    e.preventDefault();

    if (this.props.searchIsInProgress === false) {
      let isValid = this.validate();

      if (isValid) {
        var searchValues = { ...this.state.fields };
        this.props.onSearchClick(searchValues);
      } else {
        this.props.onShowHelpModal();
      }
    }
  }

  validate(fields) {
    fields = fields || this.state.fields;

    let validation = validate(fields, this.fs.showVotersRollByAddress);
    let isValidSearch =
      Object.keys(validation).reduce(
        (prev, current) => (prev = validation[current].showError || prev),
        false
      ) === false;

    this.setState({ fields, validation, isValidSearch });

    return isValidSearch;
  }

  onResetClick(e) {
    e.preventDefault();

    this.setState({ ...initialState });

    this.props.onResetClick();
  }

  onDateInputChange = (inputName) => (value) => {
    const fields = {
      ...this.state.fields
    };
    fields[inputName] = value;

    this.setState({...this.state, fields});
  }

  onChange(e) {
    let fields = {
      ...this.state.fields,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value
    };
    

    if (e.target.type === "checkbox") {
      this.validate(fields);
    } else {
      const newState = {...this.state, fields};
      this.setState(newState);
    }
  }

  onBlur(e) {
    let { fields: f } = this.state;
    if (
      f.searchBenefits ||
      f.searchBusinessRates ||
      f.searchDeceased ||
      f.searchCouncilTaxAddress ||
      f.searchCouncilTaxName ||
      f.searchDirectory ||
      f.searchVotersRoll ||
      f.searchVotersRollByAddress
    ) {
      this.validate();
    }
  }

  

  onBlankForenameChange(e) {
    let { fields: f } = this.state;
    let fields = {
      ...f,
      blankForename: e.target.checked,
      forename: e.target.checked ? "" : f.forename
    };

    if (
      f.searchBenefits ||
      f.searchBusinessRates ||
      f.searchDeceased ||
      f.searchCouncilTaxAddress ||
      f.searchCouncilTaxName ||
      f.searchDirectory ||
      f.searchVotersRoll ||
      f.searchVotersRollByAddress
    ) {
      this.validate(fields);
    } else {
      this.setState({ fields });
    }
  }

  onSelectAllSearchesChange(e) {
    this.setState({
      fields: {
        ...this.state.fields,
        searchBenefits: e.target.checked,
        searchCouncilTaxName: e.target.checked,
        searchCouncilTaxAddress: e.target.checked,
        searchVotersRoll: e.target.checked,
        searchVotersRollByAddress: e.target.checked,
        searchBusinessRates: false,
        searchDeceased: e.target.checked,
        searchDirectory: e.target.checked,
        searchAll: e.target.checked
      }
    });
  }

  render() {
    let { fields: f, validation: v } = this.state;
    let { searchErrors } = this.props;
    let {
      benefits,
      deceased,
      councilTaxAddress,
      councilTaxName,
      directory,
      votersRoll,
      votersRollByAddress
    } = this.props.searches;
    let {
      onHelpModalClose
    } = this.props;


    const votersRollByAddressOption = this.fs.showVotersRollByAddress
    ? (
      <label className="plus-checkbox-label">
        <span>Voters Roll By Address</span>
        <input type="checkbox" id="searchVotersRollByAddress" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchVotersRollByAddress} {...this.onChanges} />
        <SearchProgress status={votersRollByAddress.SearchProgressIndicator} />
      </label>
    )
    : null;

    const telephoneDirectoryOption = (
      <label className="plus-checkbox-label">
        <span>Telephone Directory</span>
        <input type="checkbox" id="searchDirectory" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchDirectory} {...this.onChanges} />
        <SearchProgress status={directory.SearchProgressIndicator} />
      </label>
    )

    return (
      <div className="plus-search-container">
        <header className="block-header">
          <span className="icon-surround">
            <i className="locta-plus-small"></i>
          </span>
          <h2>LoCTA Plus</h2>
          <a
            className="search-info-link remove-bold"
            title="Please click for search rules."
            target="_blank"
            href="/cdn/downloads/LoCTA Plus Search criteria_2023.pdf"
          >
            (?)
          </a>
        </header>
        <form>
          <ul className="inline-fields">
            <li>
              <label>Forename</label>
              <input
                type="text"
                id="forename"
                placeholder="Forename"
                tabIndex={tabIndex}
                value={f.forename}
                className={
                  "remove-margin" +
                  (v.forename.showError ? " input-validation-error" : "")
                }
                disabled={f.blankForename}
                {...this.onChanges}
                {...this.onBlurs}
              />
            </li>
            <li>
              <label>Surname</label>
              <input
                type="text"
                id="surname"
                placeholder="Surname"
                tabIndex={tabIndex}
                value={f.surname}
                className={v.surname.showError ? "input-validation-error" : ""}
                {...this.onChanges}
                {...this.onBlurs}
              />
            </li>
          </ul>
          <ul className="inline-fields">
            <li>
              <label className="blank-forename">
                <input
                  type="checkbox"
                  id="blankForename"
                  className="checkbox underneath"
                  tabIndex={tabIndex}
                  checked={f.blankForename}
                  onChange={this.onBlankForenameChange.bind(this)}
                />
                <span>Blank Forename</span>
              </label>
            </li>
          </ul>
          <ul className="inline-fields">
            <li>
              <label>Date Of Birth</label>
              <DateTimePicker
                name="dateOfBirth"
                id="dateOfBirth"
                onChange={this.onDateInputChange("dateOfBirth")}
                value={f.dateOfBirth}
                className={(v.dateOfBirth.showError ? "input-validation-error m-0" : "m-0")
                }
              />
            </li>
            <li>
              <label>NINO</label>
              <input
                type="text"
                id="nino"
                placeholder="NINO"
                tabIndex={tabIndex}
                value={f.nino}
                className={v.nino.showError ? "input-validation-error" : ""}
                {...this.onChanges}
                {...this.onBlurs}
              />
            </li>
          </ul>
          <ul className="inline-fields">
            <li>
              <label>House Name / Number</label>
              <input
                type="text"
                id="houseName"
                placeholder="House Name / Number"
                tabIndex={tabIndex}
                value={f.houseName}
                {...this.onChanges}
              />
            </li>
            <li>
              <label>Street Name</label>
              <input
                type="text"
                id="streetName"
                placeholder="Street Name"
                tabIndex={tabIndex}
                value={f.streetName}
                {...this.onChanges}
              />
            </li>
          </ul>
          <ul className="inline-fields">
            <li>
              <label>Postcode</label>
              <input
                type="text"
                id="postcode"
                placeholder="Postcode"
                tabIndex={tabIndex}
                value={f.postcode}
                className={v.postcode.showError ? "input-validation-error" : ""}
                {...this.onChanges}
                {...this.onBlurs}
              />
            </li>
            <li>
              <div style={{ marginTop: "27px", fontSize: "10px" }}>Space required in Postcode</div>
            </li>
          </ul>

          <ul className="inline-fields">
            <li>
              <label className="has-description">Location</label>
              <span className="field-description">
                For Deceased / Telephone Directory Searches
              </span>
              <input
                type="text"
                id="location"
                placeholder="For Deceased / Telephone Directory Searches"
                tabIndex={tabIndex}
                value={f.location}
                className={
                  "telephone-location wide" +
                  (v.location.showError ? " input-validation-error" : "")
                }
                {...this.onChanges}
                {...this.onBlurs}
              />
            </li>
          </ul>

          <ul className="inline-fields">
            <li>
              <label>Search Start</label>
              <DateTimePicker
                name="searchStart"
                id="searchStart"
                onChange={this.onDateInputChange("searchStart")}
                value={f.searchStart}
                className={(v.searchStart.showError ? "input-validation-error m-0" : "m-0")
                }
              />
            </li>
            <li>
              <label>Search End</label>
              <DateTimePicker
                onChange={this.onDateInputChange("searchEnd")}
                name="searchEnd"
                id="searchEnd"
                value={f.searchEnd}
                className={(v.searchEnd.showError ? "input-validation-error m-0" : "m-0")
                }
              />
            </li>
          </ul>
          <ul className="inline-fields">
            <li>
              <label className="plus-checkbox-label">Live Cases Only?</label>
              <input type="checkbox" id="liveCasesOnly" className="plus-checkbox" tabIndex={tabIndex} checked={f.liveCasesOnly} {...this.onChanges} />
            </li>
          </ul>

          <ul className="inline-fields">
            <li>
              <label><span>By Distance from Council</span></label>
              <select id="selectedCouncilId" tabIndex={tabIndex} {...this.onChanges}>
                <option value="0">Current Council</option>
                {councilOptions}
              </select>
              <span style={{ paddingLeft: '4px' }}>
                <input id="maxSearchDistance" type="range" className="white" value={f.maxSearchDistance} min={sliderConfig.sliderMinSearchDistance} max={sliderConfig.sliderMaxSearchDistance} step={sliderConfig.sliderDistanceStep} tabIndex={tabIndex} {...this.onChanges} />
              </span>
              <span style={{ lineHeight: '30px' }}>{f.maxSearchDistance >= 640 ? 'National' : `${f.maxSearchDistance} miles`}</span>
            </li>
          </ul>

          <h5>SEARCHES REQUIRED</h5>
          <div className={"alert alert-danger" + (v.searchesRequired.showError ? "" : " hidden")} >
            Please select a search to run
          </div>

          <ul className="searches-required">
            <li>
              <label>
                <span>Select All</span>
                <input type="checkbox" className="pull-right" onChange={this.onSelectAllSearchesChange.bind(this)} checked={f.searchAll} tabIndex={tabIndex} />
              </label>
            </li>
            <li className="new-line">
              <label className="plus-checkbox-label">
                <span>Benefits</span>
                <input type="checkbox" id="searchBenefits" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchBenefits} {...this.onChanges} />
                <SearchProgress status={benefits.SearchProgressIndicator} />
              </label>

              <label className="plus-checkbox-label">
                <span>Deceased</span>
                <input type="checkbox" id="searchDeceased" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchDeceased} {...this.onChanges} />
                <SearchProgress status={deceased.SearchProgressIndicator} />
              </label>

            </li>
            <li>
              <label className="plus-checkbox-label">
                <span>Council Tax Name</span>
                <input type="checkbox" id="searchCouncilTaxName" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchCouncilTaxName} {...this.onChanges} />
                <SearchProgress status={councilTaxName.SearchProgressIndicator} />
              </label>
              <label className="plus-checkbox-label">
                <span>Voters Roll</span>
                <input type="checkbox" id="searchVotersRoll" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchVotersRoll} {...this.onChanges} />
                <SearchProgress status={votersRoll.SearchProgressIndicator} />
              </label>
            </li>
            <li>
              <label className="plus-checkbox-label">
                <span>Council Tax Address</span>
                <input type="checkbox" id="searchCouncilTaxAddress" className="plus-checkbox pull-right" tabIndex={tabIndex} checked={f.searchCouncilTaxAddress} {...this.onChanges} />
                <SearchProgress status={councilTaxAddress.SearchProgressIndicator} />
              </label>
              { this.fs.showVotersRollByAddress ? votersRollByAddressOption : telephoneDirectoryOption }
            </li>

            { this.fs.showVotersRollByAddress
              ? (
                <li>
                  { telephoneDirectoryOption }
                  <label className="plus-checkbox-label">
                  </label>
                </li>
              ) : null
            }
          </ul>
          <div className="clearfix"></div>

          <SearchError errors={searchErrors} />

          <ul className="inline-fields search-buttons">
            <li className="align-right b4" style={{ width: "100px", height: "28px" }}>
              <LoctaButton small={true} className="h-100 mt-20" disabled={this.props.searchIsInProgress} icon={this.props.searchIsInProgress ? "fas fa-spinner" : "fas fa-play-circle"} ButtonFn={PrimaryButton} textComponent={<>Search</>} iconSize="16px" fontSize="11px" fontWeight="normal" tabIndex={tabIndex} onClick={this.onSearchClick} />
            </li>
            <li className="align-right b4" style={{ width: "100px", height: "28px" }}>
              <LoctaButton small={true} className="h-100 mt-20" icon="fas fa-sync" disabled={this.props.searchIsInProgress} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={this.onResetClick.bind(this)} tabIndex={tabIndex} />
            </li>
          </ul>
        </form>

        <LoctaPlusHelpModal modal={this.props.helpModal} onSubmit={onHelpModalClose} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searches: selector.searches(state),
    helpModal: selector.helpModal(state),
    searchIsInProgress: selector.searchIsInProgress(state),
    searchErrors: selector.searchErrors(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSearchClick: searchValues => {
      dispatch(actions.performSearch(searchValues));
    },
    onResetClick: () => {
      dispatch(actions.resetSearch());
    },
    onShowHelpModal: () => {
      dispatch(actions.showHelpModal());
    },
    onHelpModalClose: () => {
      dispatch(actions.closeHelpModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFields);
