import * as Actions from '../redux-actions/telephone-directory-search-actions';

export const initialSearchedValues = {
    surname: '',
    companyName: '',
    location: '',
    isLinkedSearch: false,
    isLoctaPlusSearch: false,
    isQuickSearch: false,
    __requestVerificationToken: ''
};

const initialState = {
    searchType: "Directory",
    searchedValues: {...initialSearchedValues},
    results: [],
    searchHasBeenPerformed: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.TELEPHONE_DIRECTORY_SEARCH_START:
            return {
                ...state,
                searchedValues: action.data,
                searchHasBeenPerformed: false,
                results: []
            };
        case Actions.TELEPHONE_DIRECTORY_SEARCH_SUCCESS:
            return {
                ...state,
                searchHasBeenPerformed: true,
                results: action.data
            };
        case Actions.TELEPHONE_DIRECTORY_SEARCH_FAIL:
            return {
                ...state,
                searchHasBeenPerformed: true,
                results: []
            };
        case Actions.TELEPHONE_DIRECTORY_SEARCH_RESET:
            return {
                ...state,
                searchHasBeenPerformed: false,
                results: []
            };
        default:
            return state;
    }
}

export const selector = {
    searchType: (state) => state.telephoneDirectorySearch.searchType,
    searchedValues: (state) => state.telephoneDirectorySearch.searchedValues,
    results: (state) => state.telephoneDirectorySearch.results,
    hasLinkedSearch: (state) => (state.telephoneDirectorySearch.searchedValues.secondLinkedSearch === '' && !state.telephoneDirectorySearch.searchedValues.isQuickSearch),
    searchHasBeenPerformed: (state) => state.telephoneDirectorySearch.searchHasBeenPerformed
};
