class DomConfigRepository {
  getJsonConfigById(id) {
    let config = {};
    let node = document.getElementById(id);
    if (node) {
      config = JSON.parse(node.text) || {};
    }
    return config;
  }
}

const instance = new DomConfigRepository();

export default instance;
