import DomConfigRepository from '../../../repositories/domConfigRepository';

const sv = DomConfigRepository.getJsonConfigById('searchValues');

export const initialValues = {
    houseNameNumber: sv.houseNameNumber,
    street: sv.street,
    postcode: sv.postcode ? sv.postcode.trim() : "",

    selectedRecordIds: sv.selectedRecordIds,

    isLinkedSearch: sv.isLinkedSearch,
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch
};

export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;
export const councils = sv.councils || {};