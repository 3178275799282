import React, { Component } from 'react';
import {connect} from 'react-redux';
import {startLoadingSummaries} from '../../redux-actions/dissolved-companies-actions';
import {selector} from '../../redux-reducers/dissolved-companies-reducer';
import Table from './table';
import {Loading} from '../shared';

class DissolvedCompanies extends Component {
    componentDidMount() {
        this.props.startLoadingSummaries();
    }
    render() {
        let comp;

        if(this.props.isLoading) {
            comp = <Loading message="Loading data..."/>
        } else {
            if(this.props.summaries.length > 0){
                comp = <Table/>
            } else {
                comp = <p>No data available.</p>
            }
        }
        
        return (
            <>
                {comp}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summaries: selector.getSummaries(state),
    isLoading: selector.isLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
    startLoadingSummaries: () => dispatch(startLoadingSummaries())
})

export default connect(mapStateToProps, mapDispatchToProps)(DissolvedCompanies);