import React, { useEffect, useState } from 'react';

import PrintAllForm from './forms/all';
import PrintSelectedCouncilForm from './forms/selected-council';
import PrintSelectedRecordsForm from './forms/selected-records';

export default ({onClose, results, searchType, searchedValues}) => {

    const [visible, setVisible] = useState(false);
    const [selectedCouncil, setSelectedCouncil] = useState(0);

    console.log('selectedCouncil:', selectedCouncil)

    useEffect(() => {
        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
        }
    }, [visible, setVisible]);

    const style = { 
        width: '750px'
    };

    const selectedSummary = results.find(x => x.dataProviderId === selectedCouncil);
    const selectedSummaryDetails = selectedSummary ? selectedSummary.records : [];
    console.log('selectedSummaryDetails:', selectedSummaryDetails)

    return (
        <>
            <div className={ `react-modal-anchor b4 ${(visible ? ' show' : '')}`}>
                <div className={ `react-modal print ${visible ? 'show' : ''}` } style={ style }>
                    <button onClick={onClose} className="close-button"><i className="fas fa-times"></i></button>
                    <h1>
                        <i className="fas fa-print"></i>
                        PDF / Print
                    </h1>

                    <PrintAllForm 
                        searchType={searchType} 
                        searchedValues={searchedValues} 
                        results={results}
                        showLabel={true} />

                    <div className="row-fluid">
                        <span className="span12 center-text color-light-gray" style={{marginTop: "10px"}}>- OR -</span>
                    </div>

                    <PrintSelectedCouncilForm 
                        searchType={searchType} 
                        searchedValues={searchedValues} 
                        results={results} 
                        selectedCouncil={selectedCouncil} 
                        onCouncilChange={(e) => setSelectedCouncil(parseInt(e.target.value))}
                        selectedSummaryDetails={selectedSummaryDetails} />

                    <PrintSelectedRecordsForm 
                        searchType={searchType} 
                        searchedValues={searchedValues} 
                        selectedSummaryDetails={selectedSummaryDetails} 
                        onClose={onClose} 
                        isDisabledButton={selectedCouncil === 0} />

                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );

};