import React, {useState, useEffect, useCallback, useRef} from 'react';
import StepWizard from "react-step-wizard";
import SearchForm from './search-form';
import StagingDataStep from './staging-data-step';
import { initialValues } from './server-values';
import ResultsStep from './results-step';
import ControlCard from './control-card';
import customTransitions from '../transitions';
import api from './api';

const goToStep = (step) => window.location.hash=`step${step}`;


const VotersRollSearch = () => {

    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const searchForm = useRef(null);

    // step 1 form
    const [searchedValues, setSearchedValues] = useState(initialValues);
    const [searchDone, setSearchDone] = useState(false);

    //step 2 form - staging
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchStagingResult, setSearchStagingResult] = useState(null);
    const [stagingSearchDone, setStagingSearchDone] = useState(false);

    //results
    const [results, setResults] = useState(null);


    const onReset = useCallback(_ => {

        searchForm.current.restart(initialValues);

        setSearchDone(false);
        setStagingSearchDone(false);
        setSearchedValues(initialValues);
        setResults(null);
        setIsLoading(false);
        goToStep(1);

    }, [setSearchedValues, setSearchDone, setIsLoading]);


    const onSearch = useCallback(async searchValues => {

        setSearchDone(false);
        setStagingSearchDone(false);
        setIsLoading(true);
        setSearchedValues(searchValues);

        const validate = await api.validate(searchValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors){

            setSearchStagingResult(null);
            setSearchDone(false);
            goToStep(1);
            setIsLoading(false);

            return validate.fieldErrors;
        }
        else {
            try {
                const search = await api.personList(searchValues);
                setSearchStagingResult(search || null);
                setSearchDone(true);
                goToStep(2);
                setIsLoading(false);
            }
            catch (err) {
                setSearchStagingResult(null);
                setSearchDone(true);
                goToStep(1);
                setIsLoading(false);
                //should really provide some feedback here - maybe a toastr message
            }
        }
    }, [setSearchStagingResult, setSearchDone, setSearchedValues, setIsLoading]);


    const onStagingSearch = useCallback (async _ => {
        setIsLoading(true);

        const validate = await api.validate(searchedValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors)
        {
            setIsLoading(false);
            return validate.fieldErrors;
        }
        else {
            try {
                if (selectedRows.length > 0) {
                    const selectedRecordIds = selectedRows.map(x => x.id);
                    const search = await api.search({...searchedValues, logId: searchStagingResult.logId, selectedRecordIds});
                    setResults(search || null);
                    setStagingSearchDone(true);
                    goToStep(3);
                    setIsLoading(false);
                }
            }
            catch (err) {
                setResults(null);
                setStagingSearchDone(true);
                goToStep(2);
                setIsLoading(false);
                //toastr?
            }
        }
    }, [setResults, setStagingSearchDone, searchStagingResult, searchedValues, selectedRows]);

    useEffect(() => {
        if(initialLoad){
            window.location.hash="step1";
            setInitialLoad(false);
        }
    }, [initialLoad, setInitialLoad]);

    const controlCardProps = {selectedRows, searchDone, onReset, setResults, setStagingSearchDone, onStagingSearch, stagingSearchDone, searchForm};
    const searchStepProps = {onSubmit: onSearch, initialValues: searchedValues, onReset, searchForm, isLoading, searchDone};
    const stagingStepProps = {setSelectedRows, selectedRows, searchStagingResult, searchDone, isLoading};
    const resultsStepProps = {searchDone, results, searchedValues, stagingSearchDone, isLoading};

    return (
        <section className="span12 search-container searches search-by-distance"> 
            <header className="block-header block-grey rt">
                <span className="icon-surround"><i className="voters-roll-small"></i></span>
                <h2>Voters Roll</h2>
                <span className="search-info-link remove-bold" title="Searches may be made on specific combinations of the fields. &#013; Permitted searches are: &#013; - Forename, Surname &#013; - The default search area is a national search &#013; - Use the Search by Distance filter to search results from the selected council within a mileage area &#013; - Forename/Surname fields requires a minimum of 2 characters, and can be used with the wildcard character * (asterisk).">(?)</span>
            </header>

            <StepWizard transitions={customTransitions} isHashEnabled={true} nav={<ControlCard {...controlCardProps}/>}>
                <SearchForm {...searchStepProps}/>
                <StagingDataStep {...stagingStepProps} />
                <ResultsStep {...resultsStepProps} />
            </StepWizard>
        </section>
    );
}
 
export default VotersRollSearch;