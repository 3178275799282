import React from 'react';
import {ResultsSummmaryView} from '../../searches-results';
import SearchTypes from '../../searches-results/search-types';
import {initialSearchedValues as initialBenefitsSearchValues} from '../../../redux-reducers/benefits-search-reducer';
import {initialSearchedValues as initialBusinessRatesSearchValues} from '../../../redux-reducers/business-rates-search-reducer';
import {initialSearchedValues as initialCouncilTaxSearchValues} from '../../../redux-reducers/council-tax-name-search-reducer';

import DomConfigRepository from '../../../repositories/domConfigRepository';
const sv = DomConfigRepository.getJsonConfigById('searchValues');

const ResultsSummaries = ({resultsType, data}) => {

    const {businessRatesSummaries, benefitsSummaries, councilTaxNameSummaries, email, telephone} = data;
    switch(resultsType){
        case SearchTypes.BusinessRates:
            return <ResultsSummmaryView resultType={resultsType} searchValues={{...initialBusinessRatesSearchValues, ...sv, email, telephone, isQuickSearch: true}} data={businessRatesSummaries}/>;
        
        case SearchTypes.Benefits:
            return <ResultsSummmaryView resultType={resultsType} searchValues={{...initialBenefitsSearchValues, ...sv, email, telephone, isQuickSearch: true}} data={benefitsSummaries}/>;

        case SearchTypes.CouncilTaxAddress:
        case SearchTypes.CouncilTaxName:
            return <ResultsSummmaryView resultType={resultsType} searchValues={{...initialCouncilTaxSearchValues, ...sv, email, telephone, isQuickSearch: true}} data={councilTaxNameSummaries}/>;
            
        default:
            return null;
    }
}
 
export default ResultsSummaries;