import DomConfigRepository from '../../../repositories/domConfigRepository';

const sv = DomConfigRepository.getJsonConfigById('searchValues');
export const initialValues = {
    surname: sv.surname,
    nino: sv.nino,
    dateOfBirth: Date.parseForReact(sv.dateOfBirth),
    openCasesOnly: sv.openCasesOnly,
    initialSearch: sv.initialSearch,
    linkedSearch: sv.linkedSearch,
    secondLinkedSearch: sv.secondLinkedSearch,
    telephone: sv.telephone,
    email: sv.email,
    
    isLoctaPlusSearch: sv.isLoctaPlusSearch,
    isQuickSearch: sv.isQuickSearch,
    isLinkedSearch: sv.isLinkedSearch
};

export const isAutoSearch = (initialValues.isLoctaPlusSearch || initialValues.isQuickSearch) && initialValues.isLinkedSearch === false;