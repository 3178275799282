import React from 'react';
import ReactTable from 'react-table-v6';
import {EditCouncilButton, HoldingAreaLink, ArchiveAreaLink} from './buttons';
import {stringAcc, cellAcc, fuzzyFilter} from '../../table';


const columns = [
    stringAcc('Id', d => String(d.id), 'id'),
    stringAcc('Council', 'councilName'),
    stringAcc('Code', 'councilCode'),
    stringAcc('CTAX Email', 'councilTaxEmailAddress'),
    stringAcc('Benefits Email', 'benefitsEmailAddress'),
    stringAcc('NNDR Email', 'nndrEmailAddress'),
    cellAcc("Holding Area", d=>d, props => <HoldingAreaLink id={props.row.id} />, 'holdingarea', '', {filterable: false}),
    cellAcc("Archive Area", d=>d, props => <ArchiveAreaLink id={props.row.id} />, 'archivearea', '', {filterable: false}),
    cellAcc("Edit", d => d, props => <EditCouncilButton id={props.row.id} />, 'edit', '', {filterable: false})
]

const Table = ({councils}) => {
    return (
        <div className="ReactTable">
            <ReactTable
                data={councils}
                columns={columns}
                defaultPageSize={10}
                filterable={true}
                defaultFilterMethod={fuzzyFilter}
            />
        </div>
    );
};

export default Table;