import 'react-app-polyfill/ie9';
import './extensions';
import { dev, prod } from './react-entrypoints';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

moment().tz("Europe/London").format();

if (process.env.NODE_ENV === "production") {
  prod();
} else {
  dev();
}

try {
  window.dispatcher.subscribe('react-views', () => {
    if (process.env.NODE_ENV === "production") {
      prod();
    } else {
      dev();
    }
  });

  window.addEventListener('react-views', () => {
    if (process.env.NODE_ENV === "production") {
      prod();
    } else {
      dev();
    }
  });
} catch {

}
