import axios from 'axios';
export const FETCH_USERS_DATA_STARTED = 'FETCH_USERS_DATA_STARTED';
export const FETCH_USERS_DATA_FINISHED = 'FETCH_USERS_DATA_FINISHED';
export const FETCH_USERS_DATA_ERROR = 'FETCH_USERS_DATA_ERROR';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_END = 'DELETE_USER_END';
export const REENABLE_USER_START = 'REENABLE_USER_START';
export const REENABLE_USER_END = 'REENABLE_USER_END';
export const GET_USER_CHANGELOG_STARTED = 'GET_USER_CHANGELOG_STARTED';
export const GET_USER_CHANGELOG_FINISHED = 'GET_USER_CHANGELOG_FINISHED';
export const GET_USER_CHANGELOG_ERROR = 'GET_USER_CHANGELOG_ERROR';

export const COUNCILS_GETALL_STARTED = 'COUNCILS_GETALL_STARTED';
export const COUNCILS_GETALL_FINISHED = 'COUNCILS_GETALL_FINSHED';
export const DELETE_COUNCIL_START = 'DELETE_COUNCIL_START';
export const DELETE_COUNCIL_END = 'DELETE_COUNCIL_END';

const api = {
    getUsers:(query) => `/Users/GetAllUsers?clientName=${query}`,
    getChangeLog:(userId) => `/Users/GetChangeLog?userId=${userId}`,
    deleteUser:() => `/api/users/DeleteUser`,
    reenableUser: () => `/api/users/ReenableUser`,

    getCouncils: () => `/api/data-providers`,
    deleteCouncil: (id) => `/api/councils/delete/${id}`
}

const action = (type) => ({type});
const actionWithData = (type, data) => ({type, data});

const fetchUsersDataStarted = (props) => {
    return { type: FETCH_USERS_DATA_STARTED, data: props };
}

const fetchUsersDataFinished = (props) => {
    return { type: FETCH_USERS_DATA_FINISHED, data: props };
}

const  fetchUsersDataError = (props) => {
    return { type: FETCH_USERS_DATA_ERROR, data: props };
}

// --- users
export const fetchUsersData = () => {
    return (dispatch) => {
        dispatch(fetchUsersDataStarted());

        axios.get(api.getUsers()).then(response => {
           const data = response.data;
           if(data){
               dispatch(fetchUsersDataFinished({data}));
           } 
        }).catch(err => {
            dispatch(fetchUsersDataError({err}));
        });
    }
}

export const deleteUser = (userId = null) => {
    if(userId === null ) return;
    return dispatch => {
        dispatch(actionWithData(DELETE_USER_START, userId));

        axios.post(api.deleteUser(), {Id:userId}).then(res => {
            dispatch(actionWithData(DELETE_USER_END, userId));
            dispatch(fetchUsersData());
        });
    }
}

export const reenableUser = (userId = null) => {
    if(userId === null ) return;
    return dispatch => {
        dispatch(actionWithData(REENABLE_USER_START, userId));

        axios.post(api.reenableUser(), {Id:userId}).then(res => {
            dispatch(actionWithData(REENABLE_USER_END, userId));
            dispatch(fetchUsersData());
        });
    }
}

export const getUserChangeLog = (userId = null) => {
    if(userId === null) return;

    return dispatch => {
        dispatch(actionWithData(GET_USER_CHANGELOG_STARTED, userId));

        axios.get(api.getChangeLog(userId))
        .then(res => {
            dispatch(actionWithData(GET_USER_CHANGELOG_FINISHED, res.data));
        })
        .catch(err => {
            dispatch(actionWithData(GET_USER_CHANGELOG_ERROR, userId));
        });
    };
}



// --- councils
export const fetchCouncils = () => {
    return (dispatch) => {
        dispatch(action(COUNCILS_GETALL_STARTED));

        axios.get(api.getCouncils()).then(response => {
           const data = response.data;
           if(data){
               dispatch(actionWithData(COUNCILS_GETALL_FINISHED, data));
           } 
        }).catch(err => {
            console.warn(err);
        });
    }
}

export const deleteCouncil = (id = null) => {
    if(id === null ) return;
    return dispatch => {
        dispatch(actionWithData(DELETE_COUNCIL_START, id));

        axios.post(api.deleteCouncil(id)).then(_ => {
            dispatch(actionWithData(DELETE_COUNCIL_END, id));
            dispatch(fetchUsersData());
        });
    }
}