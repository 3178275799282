import React from "react";
import PostcodeInput from '../../shared/postcode-input';
import {BootstrapContainer, Row, ReactWidgetsDatePicker as DatePicker} from '../../bootstrap'

export const renderInput = ({ input, label, placeholder, type, tabIndex, meta: { touched, error }, showLabel = true, className = "search-field" }) => (
    <>
        {showLabel ? <label>{label}</label> : null}
        <input
            {...input}
            type={type}
            placeholder={placeholder ?? label}
            tabIndex={tabIndex}
            className={
                `${className}` +
                (touched && error ? " invalid-search-input" : "")
            }
        />
    </>
);


export const renderDateTimePicker = ({ input, label, placeholder = "dd/mm/yyyy", meta: { touched, error }, ...rest }) => {
    return (
        <>
            <label>{label}</label>
            <div className={`rw-dateinput-container`}>
                <DatePicker
                    {...input}
                    placeholder={placeholder}
                    containerClassName={`search-field` + (error ? " invalid-search-input" : "")}
                    {...rest}
                />
            </div>
        </>
    );
}

export const renderCheckbox = ({ input, label, tabIndex, meta: { touched, error }, className = "" }) => (
    <div className={
        `${className}` +
        (touched && error ? " invalid-search-input" : "")
    }>
        <label>{label}</label>
        <input
            {...input}
            type="checkbox"
            tabIndex={tabIndex}
            checked={input.value}

        />
    </div>
);

export const renderCheckboxInline = ({ input, label, tabIndex, meta: { touched, error }, className = "" }) => (
    <BootstrapContainer>
        <Row className={
            `d-flex align-items-center ${className}` +
            (touched && error ? " invalid-search-input" : "")
        }>
            <label style={{margin:0}}>{label}</label>
            <input
                style={{marginLeft:'8px'}}
                {...input}
                type="checkbox"
                tabIndex={tabIndex}
                checked={input.value}

            />
        </Row>
    </BootstrapContainer>

);

export const renderCheckboxUnderInput = ({ input, label, tabIndex, inputStyle, inputClassName, labelClassName = "" }) => (
    <label className={`checkbox underneath ${labelClassName}`}>
        {label}
        <input
            {...input}
            type="checkbox"
            tabIndex={tabIndex}
            style={inputStyle}
            className={inputClassName}
            checked={input.value}
        />
    </label>
);

export const renderSelectList = ({ input, tabIndex, children, meta: { touched, error } }) => (
    <select
        {...input}
        tabIndex={tabIndex}
        className={
            "search-field" +
            (error ? " invalid-search-input" : "")
        }>
        {children}
    </select>
);

export const renderPostcodeInput = ({ input, label, tabIndex, meta: { error } }) => {
    const theLabel = label ? <label>{label}</label> : null;
    return (
        <>
            {theLabel}
            <div style={{ display: 'flex' }}>
                <PostcodeInput
                    {...input}
                    tabIndex={tabIndex}
                    containerClassName={error ? "error" : ""}
                />
            </div>
        </>
    );
}