import React, {useState} from 'react';
import RWDatePicker from 'react-widgets/DatePicker';
import RDatePicker from 'react-datepicker';
import DatePickerCustomHeader from './datepicker-custom-header';

export const ReactWidgetsDatePicker = ({ value = null, ...rest }) => {
    return <RWDatePicker
        value={!value ? null : value}
        valueEditFormat={{ dateStyle: "short" }}
        valueDisplayFormat={{ dateStyle: "short" }}
        {...rest}
    />;
}

export const ReactDatePicker = ({autoComplete="off", dateFormat="dd/MM/yyyy", placeholder="dd/MM/yyyy", value, isClearable=true, onBlur = () => {}, onChange = () => {}, ...rest}) => {
    const [isOpen, setIsOpen] = useState(false);

    const keyDownFn = e => {
        if (e.keyCode === 9) {
            setIsOpen(false);
        }
    }

    return (
        <RDatePicker
            autoComplete={autoComplete}
            dateFormat={dateFormat}
            placeholderText={placeholder}
            selected={value}
            value={value}
            renderCustomHeader={DatePickerCustomHeader}
            onBlur={onBlur}
            onChange={onChange}
            closeOnScroll={true}
            onKeyDown={keyDownFn}
            {...rest}
            open={isOpen}
            onCalendarOpen={_ => setIsOpen(true)}
            onCalendarClose={_ => setIsOpen(false)}
            onFocus={_ => setIsOpen(true)}
        />
    );
}

