import ActionTypes from '../constants/ActionTypes';

const initialState = {
  modalType: null,
  modalProps: {}
}

const showModal = (state, action) => {

  let modalSize = '';

  if (action.modalSize) {
    modalSize = action.modalSize;
  }

  return {
    modalProps: action.modalProps,
    modalType: action.modalType,
    modalSize: modalSize,
    type: action.type
  }

}


export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL:
      return showModal(state, action);
    case ActionTypes.HIDE_MODAL:
      return initialState;
  
    default:
      return state
  }
}
