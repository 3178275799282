const hasValue = value => (value || typeof value === 'number')
const isNumber = value => (value && !isNaN(Number(value)))

export default (values) => {

    const errors = {}

    if (!hasValue(values.name)) {
        errors.name = 'Required'
    }

    if (!hasValue(values.ref)) {
        errors.ref = 'Required'
    }

    if (!hasValue(values.potentialRecovery) || !isNumber(values.potentialRecovery)) {
        errors.potentialRecovery = 'Required'
    }

    if (!hasValue(values.notes)) {
        errors.notes = 'Required'
    }

    return errors;
};