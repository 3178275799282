import React from 'react';
import ReactTable from 'react-table-v6';
import {stringAcc, cellAcc, dateAcc, fuzzyFilter} from '../table';
import {ReactTableContainer} from '../bootstrap'

// councilId: 3535
// councilName: "Tonbridge and Malling"
// dateCreated: "/Date(1643730640470-0000)/"
// newMatchCount: 1
// reportId: 20220201033820
// summaryId: 3

const Download = (props) => {
    const {reportId} = props.value;
    return (
        <div>
            <a href={`/api/sbr-v2-reports/download/${reportId}`} target="_blank" rel="noopener noreferrer">Download Report</a>
        </div>
    );
};

const DownloadSU = (props) => {
    const {summaryId} = props.value;
    return (
        <div>
            <a href={`/api/sbr-v2-reports/download/${summaryId}`} target="_blank" rel="noopener noreferrer">Download Report</a>
        </div>
    );
};

const columnsUser = [
    dateAcc('Report Date', d => d.dateCreated, 'reportDate', 'text-left'),
    stringAcc('New Matches', 'newMatchCount', 'newMatches', 'text-left'),
    cellAcc("Download", d => d, props => <Download {...props}/>, 'download', 'text-left')
];

const columnsSU = [
    stringAcc('Id', 'summaryId', 'summaryId', 'text-left'),
    stringAcc('Report', 'reportId', 'reportId', 'text-left'),
    dateAcc('Report Date', d => d.dateCreated, 'reportDate', 'text-left'),
    stringAcc('New Matches', 'newMatchCount', 'newMatches', 'text-left'),
    cellAcc("Download", d => d, props => <DownloadSU {...props}/>, 'download', 'text-left'),
    stringAcc('Council Name', 'councilName', 'councilName', 'text-left')
];

const Table = ({data, isSU}) => {
    if(typeof(data) === 'undefined' || data === null || data.length === 0){
        return <div>Loading...</div>
    }

    return (
        <ReactTableContainer>
            <ReactTable
                data={data}
                columns={isSU ? columnsSU : columnsUser}
                defaultPageSize={5}
                filterable={true}
                defaultFilterMethod={fuzzyFilter}
            />
        </ReactTableContainer>
    );
};

export default Table;