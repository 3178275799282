import * as Actions from '../redux-actions/superuser-actions';

const initialState = {
    councils: []
};

export default (state = initialState, action) => {
    switch (action.type) {

        case Actions.COUNCILS_GETALL_STARTED:
            return {...state, initialState};
        case Actions.COUNCILS_GETALL_FINISHED:
            return {...state, councils: action.data};

        default:
            return state;
    }
};

export const selector = {
    councils: (state) => state.councils.councils
};