import React, { Component } from 'react';
import {connect} from 'react-redux';
import {selector} from '../../redux-reducers/companies-house-reducer';
import PscsCard from './pscs-card';
import OfficersCard from './officers-card';
import CompanySummary from './company-summary';
import {Row, Col, LinkButton} from '../bootstrap';

class CompanyCard extends Component {
    constructor(props){
        super(props);

        this.state = {
            expanded: false
        };

        this.onExpandedClick = this.onExpandedClick.bind(this);
    }

    componentDidUpdate(prevProps){
        if (this.props.company !== prevProps.company) {
            this.setState({...this.state, expanded: false});
          }
    }

    onExpandedClick() {
        this.setState({...this.state, expanded: true});
    }

    render() {
        const {companyNumber} = this.props.company.document;
        const {additionalRecordsExistFn} = this.props;

        const showMoreButton = this.state.expanded === false && additionalRecordsExistFn(companyNumber)
        ? (<Col size={12}><LinkButton className="ch-show-more" onClick={this.onExpandedClick}>Show Directors and Additional Records</LinkButton></Col>)
        : null

        return (
            <Row className="w-100 pl-4">
                <Col size={8}>
                    <CompanySummary company={this.props.company}/>
                </Col>
                <PscsCard companyNumber={companyNumber} showAll={this.state.expanded}/>
                <OfficersCard companyNumber={companyNumber} showAll={this.state.expanded}/>
                {showMoreButton}
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    additionalRecordsExistFn: selector.bycompany.additionalRecordsExist(state)
})

export default connect(mapStateToProps)(CompanyCard);