import React from 'react';
import { LoctaButton, SecondaryButton } from '../../../bootstrap';

import GetOptions from './options'; 

export default ({searchType, searchedValues, selectedSummaryDetails, onClose, isDisabledButton, additionalQueryDetails}) => {

    let recordOptions = GetOptions(searchType, selectedSummaryDetails);
    console.log('recordOptions:', recordOptions)

    return (
        <form autoComplete="off" target="_blank" method="post" action="/FurtherActions/ExportToPrintSelectedRecords">
            <div className="row-fluid content">
                <div className="span12">
                    <select id="resultrecords" name="SelectedRecords" multiple="multiple" style={{height: "100px", width: "100%", marginTop: "10px"}}>

                    { recordOptions }

                    </select>
                </div>
            </div>

            <div className="row-fluid content">
                <div className="span12 bottom-left-buttons" style={{marginTop: "20px"}}>
                    <div style={{display: 'inline-block', width: '140px'}}>
                        <LoctaButton icon="fas fa-times-circle" ButtonFn={SecondaryButton} onClick={onClose} textComponent="cancel" />
                    </div>
                    <div style={{display: 'inline-block', marginLeft: '15px', width: '140px'}}>
                        <LoctaButton icon="fas fa-play-circle" type="submit" textComponent={(<>PDF / Print<br/>Selected</>)} disabled={isDisabledButton} />
                    </div>

                    <input type="hidden" name="SearchType" value={searchType} />
                    <input type="hidden" name="IsLoctaPlusSearch" value={searchedValues.isLoctaPlusSearch} />
                    <input type="hidden" name="IsLinkedSearch" value={searchedValues.isLinkedSearch} />
                    <input type="hidden" name="IsQuickSearch" value={searchedValues.isQuickSearch} />

                    {additionalQueryDetails}
                </div>

            </div>
        </form>
    );
};