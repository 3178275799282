import React from 'react';
import NinoMatchDownloads from '../nino-match';
import SBRAdapter from '../sbr';
import DissolvedCompanies from '../dissolved-companies';
import UserAccounts from '../user-accounts';
import { HashRouter as Router, Route } from "react-router-dom";
import {routes} from '../../config/routes';
import {BootstrapContainer} from '../bootstrap';

const AdminDashboardRouter = () => {
    return (
        <BootstrapContainer className="container-fluid no-padding">
            <div className="pt-4">
                <Router basename={routes.adminDashboard} hashType="noslash">
                    <Route path={routes.ninomatch} component={NinoMatchDownloads} />
                    <Route path={routes.sbr} component={SBRAdapter} />
                    <Route path={routes.dissolvedCompanies} component={DissolvedCompanies} />
                    <Route path={routes.userAccounts} component={UserAccounts} />
                </Router>
            </div>
        </BootstrapContainer>

    );
};

export default AdminDashboardRouter;
