import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { ModalsMap } from './index';

const ModalContainer = (props) => {
  const modal = useSelector(state => state.modal);
  const {modalProps} = modal;
  const open = modalProps.open || false;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  useEffect(() => {
    setModalIsOpen(open);
  }, [open])

  if (!modal.modalType) {
    return null;
  }

  const SpecifiedModal = ModalsMap[modal.modalType]
  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Modal"
      ariaHideApp={false}
      overlayClassName="b4-modal b4-fade b4-show"
      bodyOpenClassName="b4-modal-open modal-open"
      className={`b4-modal-dialog b4-modal-dialog-centered ${modal.modalSize}`}
    >
      <SpecifiedModal
        closeModal={closeModal}
        {...modalProps}
      />
    </ReactModal>
  );
}

export default ModalContainer;