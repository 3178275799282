import React, { useState, useCallback } from 'react';

import SearchFields from './search-fields';
import ResultsSummaries from '../../searches-results/deceased';
import NoResults from '../no-results';
import api from './api';
import { initialValues, isAutoSearch } from './server-values';
import Loading from '../loading';

const DeceasedSearch = () => {

    const [searchedValues, setSearchedValues] = useState(initialValues);
    const [results, setResults] = useState([]);
    const [searchDone, setSearchDone] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const onFormReset = useCallback(form => (_) => {

        form.reset();

        setSearchedValues(initialValues);
        setIsLoading(false);
        setSearchDone(false);

    }, [setSearchDone, setSearchedValues, setIsLoading]);


    const onFormSubmit = useCallback(async searchValues => {

        setIsLoading(true);

        var serverValues = {
            ...searchValues,
            dateOfBirth: Date.formatForServer(searchValues.dateOfBirth),
        }

        const validate = await api.validate(serverValues);
        const hasValidateErrors = validate.isInvalid;
        if (hasValidateErrors) {

            setResults([]);
            setSearchDone(false);
            setIsLoading(false);

            return validate.fieldErrors;
        }
        else {
            try {
                const search = await api.search(serverValues);
                setResults(search || []);
                setSearchedValues(searchValues);
            }
            catch (err) {
                setResults([]);
            }
            setIsLoading(false);
            setSearchDone(true);
        }
    }, [setResults, setIsLoading, setSearchDone]);

    const hasResults = results.length > 0;
    const hasLinkedSearch = searchedValues.secondLinkedSearch === '' && !searchedValues.isQuickSearch
    
    const content = isLoading 
        ? <Loading message="Searching data ..." /> 
        : !searchDone 
            ? null 
            : hasResults 
                ? <ResultsSummaries searchedValues={searchedValues} searchType="Deceased" hasLinkedSearch={hasLinkedSearch} results={results} /> 
                : <NoResults />;

    return (
        <section className="span12 search-container searches">
            <header className="block-header block-grey rt">
                <span className="icon-surround"><i className="deceased-small"></i></span>
                <h2>Deceased</h2>
                <span className="search-info-link remove-bold" title="Searches may be made on specific combinations of the fields. &#013; Permitted searches are: &#013; - Surname + Date of Birth &#013; - Forename, Surname + Town/City/Postcode (this can be a partial postcode) &#013; -	Forename/Surname fields require a minimum of 2 characters and can be used with the wildcard character * (asterisk). &#013; Ratings are provided for deceased data. Those marked 7 and above, will have been accompanied by a death certificate. Ratings below that require further checks.">(?)</span>
            </header>
            <SearchFields onSubmit={onFormSubmit} onReset={onFormReset} initialValues={initialValues} isAutoSearch={isAutoSearch} searchDone={searchDone}/>

            <div id="spacer" style={{ height: "0px", clear: "both" }}></div>

            {content}
        </section>
    );
}

export default DeceasedSearch;