import React, { Component } from "react";

export const ReactTableContainer = ({children}) => {
    return (
        <div className="ReactTable">
            {children}
        </div>
    );
};

const getExpanded = data => {
    let expanded = {};
    for(let i=0; i<data.length; i++){
        expanded[i] = true;
    }
    return expanded;
}

export const ExpandedTableHOC = TableComponent =>
    class ExpandedTable extends Component {
        constructor(props) {
            super(props);
            this.state = {
                expanded: getExpanded(props.data)
            };
        }

        componentDidUpdate(prevProps) {
            if (this.props.data !== prevProps.data) {
                this.setState({
                    expanded: getExpanded(this.props.data)
                });
            }
        }



        getWrappedInstance() {
            if (!this.wrappedInstance)
                console.warn("AdvancedExpandTable - No wrapped instance");
            if (this.wrappedInstance.getWrappedInstance)
                return this.wrappedInstance.getWrappedInstance();
            else return this.wrappedInstance;
        }

        static ThComponent({className, ...rest}) {
            let newClassName = className === "" ? "d-none" : className;
            return <TableComponent.defaultProps.ThComponent className={newClassName} {...rest} />;
        }

        static TrComponent({...rest}) {
            return <TableComponent.defaultProps.TrComponent {...rest} />;
        }

        render() {
            const { className, ...rest } = this.props;

            return (
                <TableComponent
                    {...rest}
                    className={`${className} expanded-table`}
                    ThComponent={ExpandedTable.ThComponent}
                    TrComponent={ExpandedTable.TrComponent}
                    expanded={this.state.expanded}
                />
            );
        }
    };
