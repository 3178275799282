import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {startLoadingSummaries} from '../../redux-actions/sbr-actions';
import Table from './table';
import {selector} from '../../redux-reducers/sbr-reducer';
import {selector as globalSelector} from '../../redux-reducers/globals';

const SBRLegacy = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoadingSummaries());
    }, [dispatch]);

    const isSU = useSelector(globalSelector.isSU);
    const isLoading = useSelector(selector.isLoading);
    const summaries = useSelector(selector.getSummaries);

    let comp;
    if(isLoading) {
        comp = <p>Loading data...</p>
    } else {
        if(summaries.length > 0){
            comp = <Table data={summaries} isSU={isSU}/>
        } else {
            comp = <p>No data available.</p>
        }
    }

    return comp;
}

export default SBRLegacy;