import React from 'react';

import {cellAcc, fuzzyFilter} from '../table';
import ReactTable from 'react-table-v6';
import DetailsCard from './details-card';
import humanize from '../../services/humanize';
import {ProgressBar} from '../bootstrap';
import {connect} from 'react-redux';
import {selector} from '../../redux-reducers/companies-house-reducer';

const Relevancy = (props) => {
    const score = props.value.score;
    const {highestScore} = props;

    return <ProgressBar value={score} max={highestScore} height={10} className="bg-warning"/>
}

const Kind = ({value}) => {   
    return (<div>{humanize(value.document.kind)}</div>);
}

const scoreSortFn = (a, b) => {
    return a.score > b.score ? 1 : -1;
};

const Name = ({value}) => {
    return <div>{value.document.name}</div>
}

const columns = ({score}) => [
    cellAcc('Name', d=>d, props => <Name {...props}/>, 'name', 'text-left'),
    cellAcc('Kind', d=>d, props => <Kind {...props}/>, 'kind', 'text-left'),
    cellAcc('Relevancy', d => d, props => <Relevancy {...props} highestScore={score}/>, 'relevancy', 'text-left', {
        sortMethod: scoreSortFn,
        maxWidth: 200
    })

];

const RecordSubcomponent = ({original}) => {
    return (
        <DetailsCard data={original}/>
    );
}


const PscsCard = ({companyNumber, highestScore, pscsFn, showAll, additionalPscsFn}) => {
    let data = showAll ? additionalPscsFn(companyNumber) : pscsFn(companyNumber);
    if(data.length !== 0){
        return (
            <ReactTable
                data={data}
                columns={columns({score: highestScore})}
                showPagination={false}
                pageSize={data.length}
                className="w-100 records-table"
                filterable={false}
                defaultFilterMethod={fuzzyFilter}
                TheadComponent={() => null}
                SubComponent={RecordSubcomponent}
            />
        );
    }
    return null;
}


const mapStateToProps = state => ({
    highestScore: selector.highestScore(state),
    pscsFn: selector.bycompany.pscs(state),
    additionalPscsFn: selector.bycompany.additionalPscs(state)
});

export default connect(mapStateToProps)(PscsCard);