import React from 'react';

const NoResults = () => {
  return (
    <div id="noResults" style={{display: 'block'}}>
        <span className="alert-danger">No results have been found for the search criteria entered.</span>
    </div>
  );
}

export default NoResults;