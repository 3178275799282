import React from 'react';

const QuickSearchError = ({errorMessage}) => {
    return (
        <div>
            {errorMessage}
        </div>
    );
};

export default QuickSearchError;