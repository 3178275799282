import React from 'react';
import { LoctaButton } from '../../../bootstrap';

export default ({searchType, searchedValues, results, showLabel = false, additionalQueryDetails}) => (

    <form autoComplete="off" target="_blank" method="post" action="/FurtherActions/ExportToPrintAllCouncils">
        <div className="row-fluid content">
            <div className="span12 center-text">
                {showLabel ? <span className="option-label">Results from all Councils</span> : null }
                <div style={{display: 'inline-block', marginLeft: '30px', width: '140px'}}>
                    <LoctaButton icon="fas fa-play-circle" type="submit" textComponent={(<>PDF / Print<br/>All</>)} />
                </div>

                <input type="hidden" name="SearchType" value={searchType} />
                <input type="hidden" name="IsLoctaPlusSearch" value={searchedValues.isLoctaPlusSearch} />
                <input type="hidden" name="IsLinkedSearch" value={searchedValues.isLinkedSearch} />
                <input type="hidden" name="IsQuickSearch" value={searchedValues.isQuickSearch} />

                {additionalQueryDetails}

                { results.map(summary => (
                    <>
                        <input type="hidden" name="SelectedCouncils" value={summary.dataProviderId} key={summary.dataProviderId} />
                        { summary.records.map(detail => <input type="hidden" name="SelectedRecords" value={detail.id} key={detail.id} />) }
                    </>
                ))}

            </div>
        </div>
    </form>
);