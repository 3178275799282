import React from 'react';
import {Row} from '../bootstrap';
import humanize from '../../services/humanize';
import Presenter from './companies-house-presenter';
import {cellAcc, fuzzyFilter} from '../table';
import ReactTable from 'react-table-v6';

const Field = (props) => {   
    return (<div>{humanize(props.value.field)}</div>);
}

const Value = (props) => {
    return <p>{props.value.fieldValue}</p>
}

const columns = [
    cellAcc('Field', d => d, props => <Field {...props}/>, 'field', 'text-left', {
        maxWidth: 220
    }),
    cellAcc('Value', d => d, props => <Value {...props}/>, 'value', 'text-left'),
];

const DetailsCard = ({data}) => {
    const viewModel = Presenter.toViewModel(data.document);

    return (
        <Row className="w-100 pb-2 pr-2">
            <ReactTable
                data={viewModel}
                columns={columns}
                showPagination={false}
                defaultPageSize={viewModel.length}
                className="w-100 details-table shadow-sm"
                filterable={false}
                defaultFilterMethod={fuzzyFilter}
                TheadComponent={() => null}
            />
        </Row>
    );
};

export default DetailsCard;