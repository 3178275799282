import React from 'react';
import { Row, Col } from '../bootstrap';
import ResultsGrid from '../searches-results/telephone-directory/results-grid';

const TelephoneDirectoryExport = ({ data }) => {
    if (!data) return null;

    return (
        <Row className="mt-3 mb-3 pl-3 pt-3">
            <Col size={12} >
                <ResultsGrid data={data} />
            </Col>
        </Row>
    )
}

export default TelephoneDirectoryExport;
