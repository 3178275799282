import DomConfigRepository from '../repositories/domConfigRepository';

export const GET_FEATURE_SWITCHES_START = 'GET_FEATURE_SWITCHES_START';
export const GET_FEATURE_SWITCHES_SUCCESS = 'GET_FEATURE_SWITCHES_SUCCESS';
export const GET_FEATURE_SWITCHES_FAIL = 'GET_FEATURE_SWITCHES_FAIL';

export const urls = {
    getAll: `/api/feature-switches/getAll`,
}

const action = (type, data = null) => ({
    type, data
});

export function getFeatureSwitches() {
    return async dispatch => {
        try {
            dispatch(action(GET_FEATURE_SWITCHES_START));
            const reactConfig = DomConfigRepository.getJsonConfigById('reactConfig');
            const {featureSwitches} = reactConfig;
            dispatch(action(GET_FEATURE_SWITCHES_SUCCESS, featureSwitches));
        }
        catch(err){
            dispatch(action(GET_FEATURE_SWITCHES_FAIL));
        }
    };
}