import moment from "moment";

export const isCaseInsensitiveMatch = (value1, value2 ) => {

    const v1 = (value1 || '').toUpperCase();
    const v2 = (value2 || '').toUpperCase();

    return v1 === v2;

}

export const isSameDate = (aDate, stringDate) => {
    const bDate = Date.parseFromUkStringFormat(stringDate);
    const momentA = moment(aDate);
    const momentB = moment(bDate);
    return momentA.isSame(momentB, "day");
}

