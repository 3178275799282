import ActionTypes from '../constants/ActionTypes';

export const showModal = ({ modalProps, modalType, modalSize }) => dispatch => {
  dispatch({
    type: ActionTypes.SHOW_MODAL,
    modalProps,
    modalType,
    modalSize
  });
}

export const hideModal = () => dispatch => {
  dispatch({
    type: ActionTypes.HIDE_MODAL
  });
}
