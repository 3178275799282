import React from 'react';
import store from '../redux-stores/website-store';
import Base from './base';
import SignalR from '../react-components/signalR';
import Toasts from '../react-components/toasts';

const Toastr = () => {
    return (
        <>
            <Toasts/>
            <SignalR/>
        </>
    )
};

export default Base('react-app-toastr', store, Toastr);
