import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import {toastr} from 'react-redux-toastr';


console.log("toasts enabled");

// const toastrOptions = {
//     timeOut: 3000, // by setting to 0 it will prevent the auto close
//     icon: (<myCustomIconOrAvatar />), // You can add any component you want but note that the width and height are 70px ;)
//     onShowComplete: () => console.log('SHOW: animation is done'),
//     onHideComplete: () => console.log('HIDE: animation is done'),
//     onCloseButtonClick: () => console.log('Close button was clicked'),
//     onToastrClick: () => console.log('Toastr was clicked'),
//     showCloseButton: true, // false by default
//     closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
//     component: ( // this option will give you a func 'remove' as props
//       <MyCustomComponent myProp="myValue">
//         <span>Hello, World!</span>
//       </MyCustomComponent>
//     )
// }

const debugOptions = {
    timeOut: 10000, 
    showCloseButton: true, 
    closeOnToastrClick: false
  }

export const onToastFn = ({title, message, type}) => {
    switch(type){
        case "Debug":
            toastr.info(`DEBUG - ${title}`, message, debugOptions);
            break;
        default:
            break;
    }
}

const Toasts = () => {
    return (
        <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick />
    );
}

export default Toasts;