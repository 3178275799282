import React from 'react';
import { LoctaButton } from '../../bootstrap';

export default ({onClose}) => (
    <section className="confirmation">
        <div className="center-text">
            Your Journal Entry has been added.
        </div>
        
        <div className="button-row">
            <LoctaButton icon="fas fa-play-circle" onClick={onClose} textComponent="close" />
        </div>
    </section>
)