import React, { useState, useEffect } from 'react';

import Confirmation from './confirmation';
import AddToProfileForm from './form';

import { getProfilesAsync, addToProfileAsync } from './api';

  
export default ({onClose, searchType, result}) => {

    const [visible, setVisible] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [initialising, setInitialising] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);


    const initialValues = {
        searchType,
        recordDetails: Object.keys(result).map((k) => ({ key: k, value: `${result[k]}`}))
    }

    
    useEffect(() => {
        async function initialise() {
            
            let profiles = await getProfilesAsync();
            setProfiles(profiles);

            setInitialising(false);
        }

        if (visible === false) {
            setTimeout(() =>  setVisible(true), 200);
            initialise();
        }

    }, [visible, setVisible, setProfiles]);


    const onSubmit = async (formValues) => {
        await addToProfileAsync(formValues);
        setShowConfirmation(true);
    }


    const style = showConfirmation ? {} : { width: '560px'};
    const page = initialising
        ? <h1>Loading!</h1>
        : showConfirmation
            ? <Confirmation onClose={onClose} />
            : <AddToProfileForm 
                onSubmit={onSubmit} 
                onClose={onClose} 
                initialValues={initialValues}
                profiles={profiles} />;

    return (
        <>
            <div className={ `react-modal-anchor ${(visible ? ' show' : '')}`}>
                <div className={ `react-modal add-to-profile ${visible ? 'show' : ''}` } style={style}>
                    <button onClick={onClose} className="close-button"><i className="fas fa-times"></i></button>
                    <h1>
                        <i className="fas fa-user-plus"></i>
                        Add to Profile
                    </h1>

                    { page }
                </div>
            </div>
            <div className={ `react-modal-backdrop ${visible ? 'show' : ''}` }></div>
        </>
    );
}
