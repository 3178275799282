import React from 'react';
import { Field, Form } from 'react-final-form';

import { LoctaButton, SecondaryButton } from '../../bootstrap';
import { renderInput, renderSelect, renderTextArea } from './inputs';

import validate from './validate';

const debtTypeOptions = {
    'UNDEFINED': 'UNDEFINED',
    'BENS': 'BENS',
    'CTAX': 'CTAX',
    'Fraud prevention': 'Fraud prevention',
    'DWP (IFD DMS)': 'DWP (IFD DMS)',
    'NINO Match': 'NINO Match',
    'Equifax': 'Equifax',
    'NNDR': 'NNDR',
    'Other': 'Other',
}

const MyForm = ({handleSubmit, onClose}) => (
    
    <form autoComplete="off" onSubmit={handleSubmit}>

        <div className="field-row">
            <Field name="name" type="text" render={renderInput} label="Name" tabIndex={1} />
            <Field name="ref" type="text" render={renderInput} label="Reference" tabIndex={2} />
        </div>
        <div className="field-row">
            <Field name="debtType" render={renderSelect} label="Debt Type" tabIndex={3} options={debtTypeOptions} />
            <Field name="potentialRecovery" type="text" render={renderInput} label="Potential Recovery Amount" placeholder="eg. 100.00" tabIndex={4} />
        </div>
        <Field name="notes" render={renderTextArea} label="Notes" tabIndex={5} rows={5} />

        <hr className="light-gray" />

        <div className="button-row p-15">
            <LoctaButton icon="fas fa-times-circle" ButtonFn={SecondaryButton} onClick={onClose} textComponent="cancel" className="mb-3" />
            <LoctaButton icon="fas fa-book-medical" type="submit" textComponent={(<>add to<br/>journal</>)} className="mb-3" />
        </div>

        <div className="alert alert-warning">
            <p>The Journal allows the logging of successful LoCTA searches along with a Debt Type and a Potential Recovery Amount.</p>
        </div>
    </form>

);

const AddtoJournalForm = ({ onSubmit, onClose, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={MyForm}
            onClose={onClose}
        />
    );
}

export default AddtoJournalForm;
