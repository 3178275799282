import React from 'react';
import { useSelector } from "react-redux";
import { selector } from "../../../redux-reducers/locta-plus-v2-reducer";
import { BootstrapSpinner, Row, Col, BootstrapContainer } from '../../bootstrap';
import ResultCard from './result-card';
import SearchNames from '../../searches-results/search-types';

const SearchResults = () => {
    const searchLoading = useSelector(selector.searchLoading);
    const searchDone = useSelector(selector.searchDone);
    const searchValid = useSelector(selector.searchValid);
    const searchFeedback = useSelector(selector.searchFeedback);

    const benefitsCount = useSelector(selector.benefitsCount);
    const ctaxAddressCount = useSelector(selector.ctaxAddressCount);
    const ctaxNameCount = useSelector(selector.ctaxNameCount);
    const deceasedCount = useSelector(selector.deceasedCount);
    const votersRollCount = useSelector(selector.votersRollCount);
    const directoryCount = useSelector(selector.directoryCount);

    if (searchLoading) {
        return (
            <BootstrapContainer style={{height:'100%'}}>
                <Row className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <BootstrapSpinner className="pt-2 pb-2" loadingMessage='Searching data...'/>
                </Row>
            </BootstrapContainer>
        );
    }

    if (searchDone && !searchLoading && !searchValid) {
        return (
            <BootstrapContainer style={{height:'100%'}}>
                <Row className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <p>Please review your query and try again.</p>
                    <p>{searchFeedback}</p>
                </Row>
            </BootstrapContainer>
        );
    }


    return (
        <BootstrapContainer style={{height:'100%'}}>
            <Row className='d-flex justify-content-between'>
                <Col size={5}>
                    <ResultCard title="Council Tax Name Results" resultsCount={ctaxNameCount} searchType={SearchNames.CouncilTaxName}/>
                </Col>
                <Col size={5}>
                    <ResultCard title="Council Tax Address Results" resultsCount={ctaxAddressCount} searchType={SearchNames.CouncilTaxAddress}/>
                </Col>
            </Row>
            <Row className='d-flex justify-content-between'>
                <Col size={5}>
                    <ResultCard title="Benefits Results" resultsCount={benefitsCount} searchType={SearchNames.Benefits}/>
                </Col>
                <Col size={5}>
                    <ResultCard title="Deceased Results" resultsCount={deceasedCount} searchType={SearchNames.Deceased}/>
                </Col>
            </Row>
            <Row className='d-flex justify-content-between'>
                <Col size={5}>
                    <ResultCard title="Voters Roll Results" resultsCount={votersRollCount} searchType={SearchNames.VotersRoll}/>
                </Col>
                <Col size={5}>
                    <ResultCard title="Telephone Directory Results" resultsCount={directoryCount} searchType={SearchNames.Directory}/>
                </Col>
            </Row>
        </BootstrapContainer>
    )
};

export default SearchResults;