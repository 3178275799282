import React from 'react';
import { Row, Col } from '../../bootstrap';
import { Field } from '../field';

export default ({ data }) => {
    const { 
        id,
        forename,
        surname,
        dateOfBirth,
        address,
        additionalOccupants = "N/A"
    } = data;
    return (
        <>
            <Row className="pb-3">
                <Col size={4}>
                    <Field label="Forename" value={forename} />
                </Col>
                <Col size={4}>
                    <Field label="Surname" value={surname} />
                </Col>
                <Col size={4}>
                    <Field label="Date of Birth" value={dateOfBirth} />
                </Col>

            </Row>
            <Row className="pb-3">
                <Field label="Address" value={address} />
            </Row>
            <Row className="pb-3">
            <Field key={id} label="Additional Occupants" value={additionalOccupants}/>
            </Row>
        </>
    )
}
