import axios from 'axios';

export const QUERY_ALL_START = 'QUERY_ALL_START';
export const QUERY_ALL_END = 'QUERY_ALL_END';
export const QUERY_COMPANIES_START = 'QUERY_COMPANIES_START';
export const QUERY_COMPANIES_END = 'QUERY_COMPANIES_END';

class QueryDTO {
    constructor(query, size = 10, showCompany = true, showPerson = true, queryType = 'MultiMatch') {
        this.query = query;
        this.size = size;
        this.showCompany = showCompany;
        this.showPerson = showPerson;
        this.queryType = queryType;
    }
}

class QueryCompanyiesDTO {
    constructor(companyNumbers) {
        this.companyNumbers = companyNumbers;
    }
}

const action = (type, data = null) => ({
    type, data
});

const urls = {
    queryAll: `/api/companieshouse/query`,
    queryCompanies: `/api/companieshouse/query-companies`
}

export function startQueryAll(query, size) {
    return (dispatch) => {
        dispatch(action(QUERY_ALL_START, query));
        
        const url = urls.queryAll;
        const data = new QueryDTO(query, size);
        axios.post(url, data)
        .then(response => {
            let result = {...response.data};
            dispatch(action(QUERY_ALL_END, result));
            dispatch(startQueryCompanies(result));
        })
        .catch(err => {
            console.warn(err);
            dispatch(action(QUERY_ALL_END, {}));
        });
    };
}

function startQueryCompanies(results) {
    return (dispatch) => {
        dispatch(action(QUERY_COMPANIES_START));

        let companyNumbers = results.companiesData.data.map(r => r.document.companyNumber);
        let companyNumbersForPSC = results.companiesForPSCData.data.map(r => r.document.companyNumber);
        let search = [...companyNumbers, ...companyNumbersForPSC];

        const url = urls.queryCompanies;
        const data = new QueryCompanyiesDTO(search);
        axios.post(url, data)
        .then(response => {
            let result = {...response.data};
            dispatch(action(QUERY_COMPANIES_END, result));
        })
        .catch(err => {
            console.warn(err);
            dispatch(action(QUERY_COMPANIES_END, {}));
        });
    }
}