import axios from 'axios';
import { initialize, SubmissionError, clearSubmitErrors } from 'redux-form';

export const DECEASED_SEARCH_START = 'DECEASED_SEARCH_START';
export const DECEASED_SEARCH_SUCCESS = 'DECEASED_SEARCH_SUCCESS';
export const DECEASED_SEARCH_FAIL = 'DECEASED_SEARCH_FAIL';
export const DECEASED_SEARCH_RESET = 'DECEASED_SEARCH_RESET';

export const urls = {
    search: `/api/search/deceased`,
    validate: `/api/search/deceased/validate`
}

export const formName = "deceased-search";

class DeceasedSearchViewModel {
    constructor(model) {
        this.Forename = model.forename;
        this.Surname = model.surname;
        this.Location = model.location;
        this.DateOfBirth = Date.formatForServer(model.dateOfBirth);

        this.IsLinkedSearch = model.isLinkedSearch;
        this.IsLoctaPlusSearch = model.isLoctaPlusSearch;
        this.IsQuickSearch = model.isQuickSearch;
        this.__RequestVerificationToken = model.__requestVerificationToken;
    }
}

const action = (type, data = null) => ({
    type, data
});

export function performSearch(model) {
    return async (dispatch) => {

        dispatch(action(DECEASED_SEARCH_START, model));

        const formValues = new DeceasedSearchViewModel(model);

        const validateResponse = await axios
            .post(urls.validate, formValues);
        if (Object.keys(validateResponse.data).length)
            throw new SubmissionError(validateResponse.data);
        try {
            //goToStep(2);
            const searchResponse = await axios.post(urls.search, formValues);
            dispatch(action(DECEASED_SEARCH_SUCCESS, searchResponse.data || []));
        }
        catch (err) {
            dispatch(action(DECEASED_SEARCH_FAIL));
        }
    }
}

export function validateSearch(model) {
    return async (dispatch) => {
        const formValues = new DeceasedSearchViewModel(model);

        dispatch(clearSubmitErrors(formName));

        const response = await axios.post(urls.validate, formValues);
        if (Object.keys(response.data).length)
            throw response.data;
    }
}

export function resetSearch(values) {
    return (dispatch) => {

        dispatch(action(DECEASED_SEARCH_RESET));
        dispatch(initialize(formName, {
            ...values,
            forename: '',
            surname: '',
            location: '',
            dateOfBirth: null
        }));
        //goToStep(1);
    }
}
