import React from 'react';

export const Badge = ({className = '', onClick = () => {}, children}) => (
    <span className={`b4-badge ${className}`} onClick={onClick}>{children}</span>
);

export const SuccessBadge = (props) => (
    <Badge {...props} className={`b4-badge-success ${props.className ? props.className : ''}`}/>
);

export const DangerBadge = (props) => (
    <Badge {...props} className={`b4-badge-danger ${props.className ? props.className : ''}`}/>
);

export const SecondaryBadge = (props) => (
    <Badge {...props} className={`b4-badge-secondary ${props.className ? props.className : ''}`}/>
);