import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../redux-actions/quick-search-alerts-actions';

import Card from '../../../shared/Card';
import { BootstrapContainer, LoctaButton, DangerButton, Row, Col } from '../../../bootstrap';


const AlertSummary = ({query, alertSummary, removeAlert}) => {

    return (
        <Card title="you have an alert for this search" className="quick-search-alert orange w-100">
            <BootstrapContainer>
                <Row className="alert-description">
                    <Col size={12}>
                        <p>We are currently automating this search for you using the following details and will notify you if we find other results.</p>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col size={4}>Query:</Col>
                    <Col size={8}>{query}</Col>
                </Row>
                <Row className="form-group">
                    <Col size={4}>Reference:</Col>
                    <Col size={8}>{alertSummary.Reference}</Col>
                </Row>
                <Row className="form-group">
                    <Col size={4}>Date Created:</Col>
                    <Col size={8}>{alertSummary.DateCreated}</Col>
                </Row>
                <Row className="form-group">
                    <Col size={4}>Expiry Date</Col>
                    <Col size={8}>{alertSummary.ExpiryDate}</Col>
                </Row>
                <Row>
                    <Col size={7}></Col>
                    <Col size={5}>
                        <LoctaButton
                            small={true} 
                            icon="fas fa-trash-alt"
                            ButtonFn={DangerButton} 
                            textComponent={<>Remove</>} 
                            iconSize="16px" 
                            fontSize="12px"
                            onClick={() => removeAlert(alertSummary.AccessId)}
                        />
                    </Col>
                    
                </Row>
            </BootstrapContainer>
        </Card>
    )
};


const mapDispatchToProps = (dispatch) => ({
    removeAlert: (alertId) => {
        return dispatch(actions.removeAlert(alertId));
    }
})

export default connect(() => ({}), mapDispatchToProps)(AlertSummary);