import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import DevTools from '../react-components/DevTools';
import { DateLocalizer, NumberLocalizer } from 'react-widgets/IntlLocalizer';
import {Localization} from 'react-widgets';

const getAttributesAsDict = (element) => {
    let props = {};
    if(element.hasAttributes()){
        let dict = {};
        for(let i = 0; i<element.attributes.length; i++){
            const attribute = element.attributes[i];
            dict[attribute.nodeName] = attribute.value;
        }
        props = {...props, ...dict};
    }

    return props;
}

const renderDev = (store, view) => {
    return (
        <Provider store={ store }>
            <Localization
                date={new DateLocalizer({ culture: 'en-GB', firstOfWeek: 1 })}
                number={new NumberLocalizer({ culture: 'en-GB' })}
                >
                    {view}
                    <DevTools />
            </Localization>
        </Provider>
    );
}

const renderDevNode = (store, view, node) => {
    if (node) {
        ReactDOM.render(renderDev(store,view), node);
    }
}

const renderProd = (store, view) => {
    return (
        <Provider store={ store }>
            <Localization
                date={new DateLocalizer({ culture: 'en-GB', firstOfWeek: 1 })}
                number={new NumberLocalizer({ culture: 'en-GB' })}
                >
                    {view}
            </Localization>
        </Provider>
    );
}

const renderProdNode = (store, view, node) => {
    if (node) {
        ReactDOM.render(renderProd(store,view), node);
    }
}

const reactifyFn = (rootId, storeFn, component, renderFn) => {
    const nodeById = document.getElementById(rootId);
    let nodes = document.getElementsByName(rootId);
    if(nodeById !== null){
        nodes = [nodeById, ...nodes];
    }

    for(let i = 0; i<nodes.length; i++){
        const node = nodes[i];
        const extraProps = getAttributesAsDict(node);
        const view = component(extraProps);
        const store = storeFn();
        renderFn(store, view, node);
    }
}

export default (rootId, storeFn, component) => {
    return {
        dev: () => {
            reactifyFn(rootId, storeFn, component, renderDevNode);
        },
        prod: () => {
            reactifyFn(rootId, storeFn, component, renderProdNode);
        }
    }
}