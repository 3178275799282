import React from "react";
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';
import { initialValues } from './server-values';

import * as actions from '../../../redux-actions/locta-plus-v2-actions';
import { formName } from '../../../redux-actions/locta-plus-v2-actions';

import { LoctaButton, SecondaryButton, PrimaryButton } from '../../bootstrap';

const buttonStyles = { fontSize: "14px" };

const SearchButtons = () => {
    const dispatch = useDispatch();

    const onReset = () => {
        dispatch(actions.resetSearch(initialValues));
    };

    const onContinue = () => {
        dispatch(submit(formName));
    }

    return (
        <div className="d-flex justify-content-end" style={buttonStyles}>
            <div style={{ width: "100px", height: "28px" }}>
                <LoctaButton small={true} className="h-100" icon="fas fa-sync" ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset} />
            </div>
            <div style={{ width: "100px", height: "28px" }} className="ml-4">
                <LoctaButton small={true} className="h-100" icon="fas fa-play-circle" ButtonFn={PrimaryButton} textComponent={<>Continue</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onContinue} />
            </div>
        </div>
    );
}

export default SearchButtons;