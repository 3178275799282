import React from 'react';

export const Field = ({ label, value, style = {} }) => {
    let formattedValue = (typeof(value) === 'undefined' || value === null || value.length === 0) ? 'N/A' : value;
    if(typeof formattedValue === "boolean"){
        formattedValue = formattedValue === true ? "Yes" : "No";
    }

    const valueStyle = {
        fontSize: "16px",
        ...style
    };

    return (
        <span>{label} <br /> <b style={valueStyle}>{formattedValue}</b></span>
    )
};