import React from 'react';
import { useSelector } from 'react-redux';
import store from '../redux-stores/website-store';
import AdminDashboard from '../react-components/routing/admin-dashboard';
import UserDashboard from '../react-components/routing/user-dashboard';
import CompaniesHouse from '../react-components/companies-house';
import LoctaPlus from '../react-components/locta-plus/main';
import LoctaPlusV2 from '../react-components/searches/locta-plus-search-sin-steps';
import TestingGrounds from '../react-components/testing-grounds';
import BenefitsSearch from '../react-components/searches/benefits-search';
import CouncilTaxAddressSearch from '../react-components/searches/council-tax-address-search';
import CouncilTaxNameSearch from '../react-components/searches/council-tax-name-search';
import QuickSearch from '../react-components/quick-search';
import BusinessRatesSearch from '../react-components/searches/business-rates-search';
import TelephoneDirectorySearch from '../react-components/searches/telephone-directory-search';
import VotersRollAddressSearch from '../react-components/searches/voters-roll-address-search';
import VotersRollSearch from '../react-components/searches/voters-roll-search';
import DeceasedSearch from '../react-components/searches/deceased-search';
import Base from './base';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {routes} from '../config/routes';
import {featureSwitch} from '../redux-reducers/feature-switches-reducer';

const Routes = () => {
    const useLoctaPlusV2 = useSelector(featureSwitch('UseLoctaPlusV2'));
    const loctaPlusComponent = useLoctaPlusV2 ? LoctaPlusV2 : LoctaPlus;
    return (
        <Router>
            <Route path={routes.userDashboard} component={UserDashboard}/>
            <Route path={routes.adminDashboard} component={AdminDashboard} />
            <Route path={routes.companiesHouseQuery} component={CompaniesHouse} />
            <Route path={routes.loctaPlus} component={loctaPlusComponent} />
            <Route path={routes.testingGrounds} component={TestingGrounds}/>
            <Route path={routes.benefitsSearch} component={BenefitsSearch} />
            <Route path={routes.councilTaxAddressSearch} component={CouncilTaxAddressSearch} />
            <Route path={routes.councilTaxNameSearch} component={CouncilTaxNameSearch} />
            <Route path={routes.quickSearch} component={QuickSearch} />
            <Route path={routes.businessRatesSearch} component={BusinessRatesSearch}/>
            <Route path={routes.telephoneDirectorySearch} component={TelephoneDirectorySearch}/>
            <Route path={routes.votersRollByAddressSearch} component={VotersRollAddressSearch}/>
            <Route path={routes.votersRollSearch} component={VotersRollSearch}/>
            <Route path={routes.deceased} component={DeceasedSearch}/>
        </Router>
    );
};

const Website = () => {
    return (
        <>
            <Routes/>
        </>
    )
}

export default Base('react-app-website', store, Website);
