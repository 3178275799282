import SuperUserApp from './index.superuser';
import WebsiteApp from './index.website';
import ResultsView from './index.results-view';
import ResultsViewExport from './index.results-view-export';
import ToastrApp from './index.toastr';
import BannerApp from './index.global-banner';

export const dev = () => {
    SuperUserApp.dev();
    WebsiteApp.dev();
    ResultsView.dev();
    ResultsViewExport.dev();
    ToastrApp.dev();
    BannerApp.dev();
}

export const prod = () => {
    SuperUserApp.prod();
    WebsiteApp.prod();
    ResultsView.prod();
    ResultsViewExport.prod();
    ToastrApp.prod();
    BannerApp.prod();
}