import React from 'react';
import { Row, Col } from '../../bootstrap';
import { Field } from '../field';

const Occupant = ({ data: { reference, fullName, startDateFormatted, endDateFormatted } }) => (
    <Row className="pb-3">
        <Col size={3}>
            <Field label="Additional Occupant" value={fullName} />
        </Col>
        <Col size={3}>
            <Field label="Reference" value={reference} />
        </Col>
        <Col size={3}>
            <Field label="Date In" value={startDateFormatted} />
        </Col>
        <Col size={3}>
            <Field label="Date Out" value={endDateFormatted} />
        </Col>
    </Row>
);

export default ({ data, id }) => {
    if (typeof (data) === 'undefined' || data.length === 0)
        return null;

    const occupants = data.map((x, i) => <Occupant key={`${id}-${x.reference}-${i}`} data={x} />);
    return occupants;
}
