import React, { useEffect } from "react";
import { Form } from 'react-final-form';

import { LoctaButton, PrimaryButton, SecondaryButton, BootstrapContainer } from '../../bootstrap';
import { TextInput, DateInput, CheckboxInput } from '../../react-final-forms';


const MyForm = ({ handleSubmit, submitting, form , isAutoSearch, onReset, searchDone}) => {
    const resetDisabled = isAutoSearch;
    const continueDisabled = isAutoSearch;
    const submitDisabled = submitting || continueDisabled;

    useEffect(() => {
        if (isAutoSearch && !searchDone) {
            form.submit();
        }
    }, [searchDone, isAutoSearch, form]);

    return (
        <>
            <div className={"locta-form block-grey rb " + (isAutoSearch ? "hidden" : "")}>
                <div className="inner">
                    <form className="form-search rf" autoComplete="off" onSubmit={handleSubmit}>
                        <ul className="inline-fields">
                            <li>
                                <TextInput name="surname" label="Surname" placeholder="Surname" tabIndex={1} inLine={false}/>
                            </li>
                            <li>
                                <TextInput name="nino" label="NINO" placeholder="NINO" tabIndex={2}  inLine={false}/>
                            </li>
                            <li>
                                <DateInput name="dateOfBirth" label="Date of Birth" tabIndex={3}  inLine={false}/>
                            </li>
                            <li>
                                <CheckboxInput name="openCasesOnly" label="Live Cases Only" tabIndex={4}  inLine={false}/>
                            </li>

                            <li className="align-right b4" style={{ width: "100px", height: "28px" }}>
                                <LoctaButton type="submit" small={true} className="h-100 mt-20" disabled={submitDisabled} icon={submitting ? "fas fa-spinner" : "fas fa-play-circle"} ButtonFn={PrimaryButton} textComponent={<>Search</>} iconSize="16px" fontSize="11px" fontWeight="normal" />
                            </li>
                            <li className="align-right b4" style={{ width: "100px", height: "28px" }}>
                                <LoctaButton small={true} className="h-100 mt-20" icon="fas fa-sync" disabled={submitting || resetDisabled} ButtonFn={SecondaryButton} textComponent={<>Reset</>} iconSize="16px" fontSize="11px" fontWeight="normal" onClick={onReset(form)} />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            {isAutoSearch && submitting ? <div className="auto-search-loading">Loading...</div> : null}
        </>
    );
}

const SearchFields = ({ onSubmit, onReset, initialValues, isAutoSearch, searchDone }) => {
    return (
        <BootstrapContainer style={{ height: "100%" }}>
            <Form
                onSubmit={onSubmit}
                onReset={onReset}
                initialValues={initialValues}
                isAutoSearch={isAutoSearch}
                searchDone={searchDone}
            >
                {MyForm}
            </Form>
        </BootstrapContainer>
    );
}

export default SearchFields;