import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import {stringAcc, cellAcc, dateAcc8, fuzzyFilter} from '../table';
import {ReactTableContainer} from '../bootstrap';
import {connect} from 'react-redux';
import {startLoadingSummaries} from '../../redux-actions/dissolved-companies-actions';
import {selector} from '../../redux-reducers/dissolved-companies-reducer';
import ResultsView from './results-view';

const Download = (props) => {
    const {id} = props.value;
    return (
        <div>
            <a href={`/api/dissolved-companies/download/${id}`} target="_blank" rel="noopener noreferrer">Download Report</a>
        </div>
    );
};

const columnsDefault = [
    stringAcc('Id', 'id', 'id', 'text-left'),
    dateAcc8('Report Date', d => d.dateCompletedFormatted, 'reportDate', 'text-left'),
    stringAcc('New Results', 'newResultsFound', 'newResults', 'text-left'),
    cellAcc("Download", d => d, props => <Download {...props}/>, 'download', 'text-left')
];

const SubComponent = (props) => {
    const {viewIndex, row} = props;
    const even = viewIndex % 2 === 0;
    const className = `rt-tr ${even ? "-odd" : ""}`;
    return (
        <div className={className}>
            <ResultsView data={row}/>
        </div>
    );
}

class Table extends Component {
    render() {
        const {summaries} = this.props;
    
        return (

            <ReactTableContainer>
                <ReactTable
                    data={summaries}
                    columns={columnsDefault}
                    showPagination={true}
                    defaultPageSize={10}
                    className="-striped"
                    filterable={false}
                    defaultFilterMethod={fuzzyFilter}
                    defaultSortDesc={true}
                    defaultSorted={[{
                        id   : 'id',
                        desc : true,
                    }]}
                    SubComponent = {SubComponent}
                />
            </ReactTableContainer>
        );
    }
}


const mapStateToProps = (state) => ({
    summaries: selector.getSummaries(state),
    isLoading: selector.isLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
    startLoadingSummaries: () => dispatch(startLoadingSummaries())
})

export default connect(mapStateToProps, mapDispatchToProps)(Table);